import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
} from '@ionic/react';
import LoadingInline from 'components/shared/LoadingInline';
import { useApi } from 'hooks/useApi';
import { Location } from 'interfaces/Location';
import { Organization } from 'interfaces/Organization';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const CardLocations: React.FC<{
  editing?: boolean;
  onChange: (locationIds: number[]) => void;
  userLocationIds?: number[];
}> = ({ editing, onChange, userLocationIds }) => {
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [locationsFiltered, setLocationsFiltered] = useState<Location[]>([]);
  const [locationIds, setLocationIds] = useState<number[]>([]);

  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    getLocations();
  }, [organization]);

  useEffect(() => {
    if (userLocationIds) {
      setLocationIds(userLocationIds);
    }
  }, [userLocationIds]);

  useEffect(() => {
    filterLocations();
  }, [locations]);

  useEffect(() => {
    filterLocations();
  }, [search]);

  useEffect(() => {
    onChange(locationIds);
  }, [locationIds]);

  const filterLocations = () => {
    setLocationsFiltered(
      locations.filter(
        (location: Location) =>
          location.name &&
          location.name.toLowerCase().includes(search.toLowerCase())
      )
    );
  };

  const getLocations = () => {
    if (!organization) {
      return;
    }
    setLoading(true);
    api
      .get(`organizations/${organization.organization_id}/locations`, {
        order_by: 'name',
        order_by_desc: false,
      })
      .then((response) => {
        if (response.status === 200) {
          setLocations(response.data || []);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleChange = (locationId: number, value: boolean) => {
    const tempArray = [...locationIds];
    if (value) {
      tempArray.push(locationId);
    } else {
      const index = tempArray.indexOf(locationId);
      if (index > -1) {
        tempArray.splice(index, 1);
      }
    }
    setLocationIds(tempArray);
  };

  return (
    <IonCard>
      <IonCardHeader style={{ paddingBottom: 0 }}>
        <IonCardTitle>Assigned Locations</IonCardTitle>
      </IonCardHeader>
      <IonSearchbar
        value={search}
        onIonChange={(e: any) => setSearch(e.detail.value as string)}
      />
      <IonCardContent className="ion-no-padding">
        {loading ? (
          <LoadingInline />
        ) : (
          <IonList>
            {locationsFiltered.map((location: Location, index: any) => (
              <IonItem
                key={location.location_id}
                lines={
                  index === locationsFiltered.length - 1 ? 'none' : 'inset'
                }
              >
                <IonCheckbox
                  slot="start"
                  onIonChange={(e: any) =>
                    handleChange(location.location_id, e.detail.checked)
                  }
                  disabled={!editing}
                  checked={locationIds.indexOf(location.location_id) > -1}
                />
                <IonLabel>{location.name}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default CardLocations;
