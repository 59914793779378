import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';
import DataTable, {
  DataTableHeader,
  DataTableRow,
} from 'components/shared/DataTable';
import ItemDescriptionCol from 'components/shared/ItemDescriptionCol';
import { Account } from 'interfaces/Account';
import { CatalogItem } from 'interfaces/CatalogItem';
import { PurchaseOrderItem } from 'interfaces/PurchaseOrderItem';
import { ShoppingCart } from 'interfaces/ShoppingCart';
import { ShoppingCartItem } from 'interfaces/ShoppingCartItem';
import { closeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toUsd } from 'utils';
import InputCartQuantity from 'components/buyer/InputCartQuantity';

const TableCartItems: React.FC<{
  onClickDelete: any;
  shoppingCart: ShoppingCart;
  account: Account;
  refresh: boolean;
  setRefresh: (value: boolean) => void;
}> = ({ onClickDelete, shoppingCart, account, refresh, setRefresh }) => {
  const allItems: ShoppingCartItem[] = useSelector(
    (state: any) => state.app.cartItems
  );
  const [shoppingCartItems, setShoppingCartItems] = useState<
    ShoppingCartItem[]
  >([]);
  const [selectedItem, setSelectedItem] = useState<
    CatalogItem | ShoppingCartItem | PurchaseOrderItem | null
  >(null);

  const headers: Array<DataTableHeader> = [
    { text: 'Item', key: 'item' },
    { text: 'Quantity', key: 'quantity', width: '175px' },
    { text: 'Pack Price', key: 'packPrice', align: 'right' },
    { text: 'Total', key: 'totalPrice', align: 'right' },
    { text: '', key: 'actions', align: 'right' },
  ];

  useEffect(() => {
    if (shoppingCart && shoppingCart.shopping_cart_id) {
      getFilteredItems();
    }
  }, [shoppingCart, allItems]);

  useEffect(() => {
    getCartTotal();
  }, [shoppingCartItems]);

  const getFilteredItems = () => {
    const cartItems: ShoppingCartItem[] = allItems.filter((item) => {
      return item.shopping_cart_id === shoppingCart.shopping_cart_id;
    });
    setShoppingCartItems(cartItems);
  };

  const getCartTotal = (): number => {
    if (!shoppingCartItems || shoppingCartItems.length === 0) {
      return 0;
    }
    let total = 0;
    shoppingCartItems.forEach((item) => {
      if (item.item_unit_price !== undefined) {
        total += parseFloat(item.item_unit_price) * item.quantity;
      } else {
        total += 0;
      }
    });
    return total;
  };

  return (
    <>
      <DataTable
        headers={headers}
        loading={!shoppingCartItems || shoppingCartItems.length === 0}
        search={false}
        rows={shoppingCartItems.map((item: ShoppingCartItem): DataTableRow => {
          const row: DataTableRow = {
            item: item,
            key: `${item.supplier_item_number}-${item.account_number}`,
            columns: [
              {
                header: 'item',
                content: <ItemDescriptionCol item={item} account={account} />,
              },
              {
                header: 'quantity',
                content: (
                  <InputCartQuantity
                    item={item}
                    refresh={refresh}
                    setRefresh={setRefresh}
                  />
                ),
              },
              {
                header: 'packPrice',
                content: (
                  <p className="font-weight-500 text-color-black">
                    {item.item_unit_price ? (
                      toUsd(parseFloat(item.item_unit_price || '0'))
                    ) : (
                      <span>&mdash;</span>
                    )}
                  </p>
                ),
              },
              {
                header: 'totalPrice',
                content: (
                  <p>
                    {item.item_unit_price ? (
                      toUsd(
                        parseFloat(item.item_unit_price || '0') * item.quantity
                      )
                    ) : (
                      <span>&mdash;</span>
                    )}
                  </p>
                ),
              },
              {
                header: 'actions',
                content: (
                  <IonButton
                    size="small"
                    fill="clear"
                    color="danger"
                    onClick={() => onClickDelete(item)}
                  >
                    <IonIcon icon={closeOutline} slot="icon-only"></IonIcon>
                  </IonButton>
                ),
              },
            ],
          };
          return row;
        })}
      ></DataTable>
      <IonRow className="ion-padding-horizontal">
        <IonCol>
          <p className="ion-text-right">
            Estimated Order Total:{' '}
            <strong className="text-color-black font-weight-600">
              {toUsd(getCartTotal())}
            </strong>
          </p>
        </IonCol>
      </IonRow>
    </>
  );
};

export default TableCartItems;
