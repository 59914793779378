import { IonCard, IonCol, IonRow, useIonViewWillEnter } from '@ionic/react';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import CardStat from 'components/shared/CardStat';
import { Page } from 'components/shared/Page';
import { checkmarkCircleOutline, timeOutline } from 'ionicons/icons';

import {
  faFileInvoice,
  faPaperPlane,
  faTruck,
} from '@fortawesome/pro-light-svg-icons';
import TableOrders from 'components/buyer/TableOrders';
import { useApi } from 'hooks/useApi';
import { Location } from 'interfaces/Location';
import 'pages/buyer/PageOrders.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PurchaseOrderStatus } from 'types/PurchaseOrderStatus';

export const PageOrders: React.FC = () => {
  const api = useApi();
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const [loading, setLoading] = useState(false);
  const [statusCounts, setStatusCounts] = useState<{
    pending: number;
    submitted: number;
    acknowledged: number;
    shipped: number;
    invoiced: number;
  }>();
  const [filterStatus, setFilterStatus] = useState<PurchaseOrderStatus | null>(
    null
  );

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Orders' },
  ];

  useIonViewWillEnter(() => {
    getPurchaseOrderStatusCounts();
  }, [selectedLocation]);

  useEffect(() => {
    getPurchaseOrderStatusCounts();
  }, [selectedLocation]);

  const getPurchaseOrderStatusCounts = () => {
    if (!selectedLocation) {
      return;
    }
    setLoading(true);
    api
      .get(
        `locations/${selectedLocation.location_id}/purchase_order_status_counts`
      )
      .then((response) => {
        const data = response.data;
        setStatusCounts(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleStatusClick = (status: PurchaseOrderStatus) => {
    if (filterStatus === status) {
      setFilterStatus(null);
    } else {
      setFilterStatus(status);
    }
  };

  return (
    <Page title="Order History" className="orders">
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        title="Order History"
      ></Breadcrumbs>
      <div className="cardStatsContainer">
        <CardStat
          title="Pending"
          stat={statusCounts?.pending || 0}
          statTitle="Orders Pending"
          icon={timeOutline}
          color="medium"
          loading={loading}
          selected={filterStatus === 'Pending'}
          onClick={() => handleStatusClick('Pending')}
          tooltip={`<span class="font-style-italic font-size-small">The order is being generated</span>`}
        />
        <CardStat
          title="Submitted"
          stat={statusCounts?.submitted || 0}
          statTitle="Orders Submitted"
          icon={faPaperPlane}
          color="warning"
          loading={loading}
          selected={filterStatus === 'Submitted'}
          onClick={() => handleStatusClick('Submitted')}
          tooltip={`<span class="font-style-italic font-size-small">The order has been submitted to the supplier</span>`}
        />
        <CardStat
          title="Acknowledged"
          stat={statusCounts?.acknowledged || 0}
          statTitle="Orders Acknowledged"
          icon={checkmarkCircleOutline}
          color="secondary"
          loading={loading}
          selected={filterStatus === 'Acknowledged'}
          onClick={() => handleStatusClick('Acknowledged')}
          tooltip={`<span class="font-style-italic font-size-small">The supplier got the order, and it's being processed</span>`}
        />
        <CardStat
          title="Shipped"
          stat={statusCounts?.shipped || 0}
          statTitle="Orders Shipped"
          icon={faTruck}
          iconClass="fa-flip-horizontal"
          color="primary"
          loading={loading}
          selected={filterStatus === 'Shipped'}
          onClick={() => handleStatusClick('Shipped')}
          tooltip={`<span class="font-style-italic font-size-small">Your order is on the way</span>`}
        />
        <CardStat
          title="Invoiced"
          stat={statusCounts?.invoiced || 0}
          statTitle="Orders Invoiced"
          icon={faFileInvoice}
          iconClass="fa-flip-horizontal"
          color="success"
          loading={loading}
          selected={filterStatus === 'Invoiced'}
          onClick={() => handleStatusClick('Invoiced')}
          tooltip={`<span class="font-style-italic font-size-small">The supplier sent us an invoice for your order</span>`}
        />
      </div>

      <IonRow>
        <IonCol>
          <IonCard class="ion-no-margin">
            <TableOrders
              title="Orders"
              filterStatus={filterStatus}
              setFilterStatus={setFilterStatus}
            />
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
