import {
  IonBadge,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonSearchbar,
  IonToolbar,
  useIonAlert,
  useIonRouter,
} from '@ionic/react';
import { Account } from 'interfaces/Account';
import { Location } from 'interfaces/Location';
import { ShoppingCartItem } from 'interfaces/ShoppingCartItem';
import { cartOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { setMedicationSearch, setSupplier } from 'redux/appReducer';
import ButtonBadge from 'components/shared/ButtonBadge';
import UserInfo from 'components/shared/UserInfo';
import UserInfoPopover from 'components/shared/UserInfoPopover';
import ModalAddSupplier from 'components/buyer/ModalSupplierAdd';
import { useAlertAddSupplier } from 'hooks/useAlertAddSupplier';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { isAdmin, isBuyer } from 'utils';

const HeaderBuyer: React.FC = () => {
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.app.user
  );
  const accounts: Account[] | null = useSelector(
    (state: any) => state.app.accounts
  );
  const cartItems: ShoppingCartItem[] = useSelector(
    (state: any) => state.app.cartItems
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const medicationSearch: string | null = useSelector(
    (state: any) => state.app.medicationSearch
  );
  const alertAddSupplier = useAlertAddSupplier();

  const [search, setSearch] = useState<string>(medicationSearch || '');
  const [supplierModalOpen, setSupplierModalOpen] = useState(false);
  const dispatch = useDispatch();
  const router = useIonRouter();
  const location = useLocation();

  useEffect(() => {
    if (medicationSearch !== null) {
      performSearch();
    }
  }, [medicationSearch]);

  const performSearch = () => {
    if (medicationSearch) {
      router.push(`/items?search=${medicationSearch}`);
    } else {
      router.push('/items');
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!accounts || accounts.length === 0) {
        // show alert
        alertAddSupplier.showAlert((e: any) => {
          if (e.detail.role === 'confirm') {
            if (authUser) setSupplierModalOpen(true);
          }
        });
      } else {
        // perform search
        dispatch(setMedicationSearch(search));
      }
    }
  };

  const onClear = (e: any) => {
    dispatch(setMedicationSearch(null));
    if (location.pathname === '/items') {
      router.push('/items');
    }
  };

  return (
    <IonHeader id="appHeader">
      <IonToolbar id="desktopToolbar" color="secondary">
        <IonButtons slot="start">
          <IonMenuButton></IonMenuButton>
        </IonButtons>
        <IonSearchbar
          className="ion-margin-start"
          autocomplete="off"
          placeholder="Search for medication..."
          debounce={0}
          color="light"
          onKeyDown={handleKeyDown}
          value={search}
          onIonChange={(e: any) => setSearch(e.detail.value as string)}
          onIonClear={onClear}
          disabled={!selectedLocation}
        ></IonSearchbar>
        <UserInfo />
        <UserInfoPopover />
        {authUser && (isBuyer(authUser) || isAdmin(authUser)) ? (
          <IonButtons slot="end" className="margin-right-sm">
            <ButtonBadge>
              <IonButton
                routerLink="/orders/cart"
                routerDirection="root"
                disabled={!selectedLocation}
              >
                <IonIcon slot="icon-only" icon={cartOutline}></IonIcon>
              </IonButton>
              {selectedLocation &&
                selectedLocation.location_id &&
                cartItems.length > 0 && (
                  <IonBadge color="danger">{cartItems.length}</IonBadge>
                )}
            </ButtonBadge>
          </IonButtons>
        ) : null}
      </IonToolbar>
      <IonToolbar id="mobileToolbar" color="secondary">
        <IonSearchbar
          autocomplete="off"
          placeholder="Search for medication..."
          debounce={0}
          color="light"
          onKeyDown={handleKeyDown}
          value={search}
          onIonChange={(e: any) => setSearch(e.detail.value as string)}
          onIonClear={onClear}
          disabled={!selectedLocation}
        ></IonSearchbar>
      </IonToolbar>
      <ModalAddSupplier
        isOpen={supplierModalOpen}
        onClose={(data?: any) => setSupplierModalOpen(false)}
        onDismiss={(data?: any) => setSupplierModalOpen(false)}
      />
    </IonHeader>
  );
};
export default HeaderBuyer;
