import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import Subtitle from 'components/shared/Subtitle';
import { Toast } from 'components/shared/ToastManager';
import { useApi } from 'hooks/useApi';
import { AppUserMapLocation } from 'interfaces/AppUserMapLocation';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { Location } from 'interfaces/Location';
import {
  checkmarkCircleOutline,
  closeCircleOutline,
  closeOutline,
  saveOutline,
} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLocation, setToast } from 'redux/appReducer';
import { isAdmin } from 'utils';

const ModalChangeLocation: React.FC<{
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
}> = ({ onDismiss }) => {
  const api = useApi();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.app.user
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const dispatch = useDispatch();

  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [userMapLocations, setUserMapLocations] = useState<
    AppUserMapLocation[]
  >([]);
  const [userLocationsFiltered, setUserLocationsFiltered] = useState<
    AppUserMapLocation[]
  >([]);
  const [selectedLocationId, setSelectedLocationId] = useState<number>();

  useEffect(() => {
    getUserLocations();
  }, []);

  useEffect(() => {
    filterLocations();
  }, [search, userMapLocations, selectedLocation]);

  const filterLocations = () => {
    setUserLocationsFiltered(
      userMapLocations.filter(
        (userMapLocation: AppUserMapLocation) =>
          // Filter out the currently selected location
          userMapLocation.location_id !== selectedLocation?.location_id &&
          // Filter by search query
          userMapLocation.location_name
            .toLowerCase()
            .includes(search.toLowerCase())
      )
    );
  };

  const getUserLocations = () => {
    if (!authUser) {
      return;
    }
    setLoading(true);
    api
      .get(`users/${authUser.app_user_id}/locations`)
      .then(({ data }) => {
        setUserMapLocations(data || []);
        setUserLocationsFiltered(data || []);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onClickCancel = () => {
    if (selectedLocation) {
      setSelectedLocationId(selectedLocation.location_id);
    }
    onDismiss();
  };

  const onClickSave = () => {
    // TODO: validate
    getLocation();
  };

  const getLocation = () => {
    setLoading(true);
    api
      .get(`/locations/${selectedLocationId}`)
      .then(({ data }) => {
        if (data.location_id !== undefined) {
          dispatch(setLocation(data));
          dispatch(
            setToast({
              header: 'Success!',
              message: `Switched to ${data.name}`,
              icon: checkmarkCircleOutline,
            })
          );
          onDismiss();
        } else {
          showToast('There was a problem changing locations', 'danger');
          dispatch(
            setToast({
              message: `There was a problem changing locations`,
              icon: closeCircleOutline,
              color: 'danger',
            })
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const showToast = (
    message: string,
    color: 'success' | 'danger',
    header?: string | undefined
  ) => {
    const toast: Toast = {
      header,
      message,
      color: color,
    };
    dispatch(setToast(toast));
  };
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Change Location</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismiss()}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={search}
            onIonChange={(e: any) => setSearch(e.detail.value as string)}
            placeholder="Search for a Location"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {userLocationsFiltered.length > 0 ? (
          <IonList>
            <IonRadioGroup
              value={selectedLocationId}
              onIonChange={(e: any) => setSelectedLocationId(e.detail.value)}
            >
              {userLocationsFiltered.map((userMapLocation) => (
                <IonItem key={userMapLocation.location_id} disabled={loading}>
                  <IonLabel>{userMapLocation.location_name}</IonLabel>
                  <IonRadio
                    slot="end"
                    value={userMapLocation.location_id}
                    defaultChecked={true}
                  />
                </IonItem>
              ))}
            </IonRadioGroup>
          </IonList>
        ) : (
          <div className="ion-padding ion-text-center">
            <p className="font-style-italic">
              It looks like you don't have access to any Locations.
            </p>
            {authUser && isAdmin(authUser) ? (
              <p>You can assign locations on the User Settings page.</p>
            ) : (
              <p>Please contact an administrator to be given access</p>
            )}
          </div>
        )}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          {selectedLocation ? (
            <IonLabel>
              {selectedLocation.name}{' '}
              <Subtitle className="font-style-italic">
                currently selected
              </Subtitle>
            </IonLabel>
          ) : null}
          <IonButtons slot="end">
            {loading && (
              <IonSpinner
                color="primary"
                name="crescent"
                className="ion-margin-end"
              />
            )}
            <IonButton
              fill="clear"
              color="dark"
              size="small"
              onClick={onClickCancel}
            >
              Cancel
            </IonButton>
            <IonButton
              size="small"
              onClick={onClickSave}
              disabled={loading || !selectedLocationId}
            >
              Save
              <IonIcon slot="end" icon={saveOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default ModalChangeLocation;
