import { IonIcon, IonRouterLink } from '@ionic/react';
import { useApi } from 'hooks/useApi';
import DataTable, {
  DataTableHeader,
  DataTableRow,
} from 'components/shared/DataTable';
import { trophy } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { localizeNumber, romanize, toUsd } from 'utils';
import axios from 'axios';

interface TopItem {
  id: string | number;
  name: string;
  description: string;
  ndc: string | null;
  gpi: string;
  vendorItemNumber: string | number | null;
  notes: string | null;
  stock: number | null;
  packages: number;
  strength: string | null;
  packPrice: number;
  featured: boolean;
  featuredPrice: number | null;
  featuredMinQuantity: number | null;
  packSize: string;
  unitDose: boolean;
  deaSchedule: number | null;
  supplierId: number | string;
  supplierName: string;
  dating: string | null;
  priceAverage: number;
  salesTotalQty: number;
  rank: number;
}

const TableTopItems: React.FC<{ timeFrame: string }> = ({ timeFrame }) => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);

  const headers: DataTableHeader[] = [
    { text: 'Item', key: 'item' },
    { text: 'Average Price', key: 'priceAverage', align: 'right' },
    { text: 'Quantity Sold', key: 'salesTotalQty', align: 'right' },
    { text: 'Total Sales', key: 'salesTotal', align: 'right' },
  ];

  useEffect(() => {
    getTopItems();
  }, []);

  const mockTopItems: any[] = [
    {
      id: 4,
      name: 'Bivalirudin Trifluoroacetate',
      description: 'Bivalirudin Trifluoroacetate For Iv Soln 250 MG',
      ndc: '00781-3158-95',
      vendorItemNumber: '98746654',
      gpi: '56485044002900-1GM',
      notes:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vulputate tortor non dolor scelerisque, eget blandit tellus laoreet. Quisque nec laoreet diam. Aliquam erat volutpat. Donec varius eros sed lacus sodales eleifend.',
      strength: '250 MG',
      unitDose: true,
      packSize: '50 Tablets',
      deaSchedule: null,
      supplierId: 1,
      supplierName: 'Sandoz',
      priceAverage: 1023.12,
      salesTotalQty: 225,
      rank: 1,
    },
    {
      id: 5,
      name: 'Cisatracurium Besylate (PF)',
      description:
        'Cisatracurium Besylate (PF) Iv Soln 200 Mg/20Ml (10 Mg/Ml) 20Ml',
      ndc: '00781-3153-95',
      vendorItemNumber: '7775561',
      gpi: '46485044002900-1GM',
      notes:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vulputate tortor non dolor scelerisque, eget blandit tellus laoreet. Quisque nec laoreet diam. Aliquam erat volutpat. Donec varius eros sed lacus sodales eleifend.',
      strength: '200 Mg/20Ml',
      unitDose: false,
      packSize: '5 Vials',
      deaSchedule: 1,
      supplierId: 2,
      supplierName: 'Sandoz',
      priceAverage: 1500.88,
      salesTotalQty: 100,
      rank: 2,
    },
    {
      id: 3,
      name: 'Meropenem',
      description: 'Meropenem Iv For Soln 1 Gm',
      ndc: '00781-3098-95',
      vendorItemNumber: '123-ZULU-Foxtrot',
      gpi: '76485044002900-1GM',
      notes:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vulputate tortor non dolor scelerisque, eget blandit tellus laoreet. Quisque nec laoreet diam. Aliquam erat volutpat. Donec varius eros sed lacus sodales eleifend.',
      strength: '1 GM',
      unitDose: false,
      packSize: '100 Capsules',
      deaSchedule: null,
      supplierId: 1,
      supplierName: 'Sandoz',
      priceAverage: 800.22,
      salesTotalQty: 220,
      rank: 3,
    },
    {
      id: 2,
      name: 'Zarxio',
      description:
        'Filgrastim-Sndz Soln Prefilled Syringe 480 Mcg/0.8Ml 0.8 Ml',
      ndc: '671314-0866-01',
      vendorItemNumber: '8765309',
      gpi: '86485044002900-1GM',
      notes:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vulputate tortor non dolor scelerisque, eget blandit tellus laoreet. Quisque nec laoreet diam. Aliquam erat volutpat. Donec varius eros sed lacus sodales eleifend.',
      strength: '480 Mcg/0.8Ml 0.8 Ml',
      unitDose: false,
      packSize: '5 Vials',
      deaSchedule: 2,
      supplierId: 1,
      supplierName: 'Sandoz',
      priceAverage: 750.37,
      salesTotalQty: 200,
      rank: 4,
    },
    {
      id: 1,
      name: 'Ziextenzo',
      description: 'Pegfilgrastim-Bmez Soln Prefilled Syringe 6 Mg/0.6Ml 0.6Ml',
      ndc: '61314-0866-01',
      vendorItemNumber: 'ZI-12345684',
      gpi: '96485044002900-1GM',
      notes:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vulputate tortor non dolor scelerisque, eget blandit tellus laoreet. Quisque nec laoreet diam. Aliquam erat volutpat. Donec varius eros sed lacus sodales eleifend.',
      strength: '6 Mg/0.6Ml 0.6Ml',
      unitDose: true,
      packSize: '10 Vials',
      deaSchedule: 5,
      supplierId: 1,
      supplierName: 'Sandoz',
      priceAverage: 0.5,
      salesTotalQty: 4000,
      rank: 5,
    },
  ];

  // Get top performing items for this supplier
  const getTopItems = () => {
    setLoading(true);
    setTimeout(() => {
      setItems(mockTopItems);
      setLoading(false);
    }, 150);

    // axios
    //   .get('topItems', { baseURL: process.env.REACT_APP_MOCK_URL })
    //   .then(({ data }) => {
    //     setLoading(false);
    //     setItems(data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setLoading(false);
    //   });
  };

  const getTrophyClass = (rank: number): string => {
    if (rank === 1) {
      return 'gold';
    } else if (rank === 2) {
      return 'silver';
    } else if (rank === 3) {
      return 'bronze';
    }
    return '';
  };

  return (
    <DataTable
      title="Top Items"
      headers={headers}
      loading={loading}
      rows={items.map((item): DataTableRow => {
        const row: DataTableRow = {
          item: item,
          key: item.id,
          columns: [
            {
              header: 'item',
              content: (
                <div className="itemCol">
                  {/* TODO: This should link to an item details page geared towards a Supplier */}
                  <IonRouterLink className="font-size-large" color="secondary">
                    {item.name}
                  </IonRouterLink>
                  {item.rank < 4 && (
                    <>
                      <IonIcon
                        size="small"
                        icon={trophy}
                        className={`trophy ${getTrophyClass(item.rank)}`}
                        data-tip
                        data-tooltip-id={`trophyTip-${item.rank}`}
                      />
                      <Tooltip
                        id={`trophyTip-${item.rank}`}
                        place="bottom"
                        float={false}
                      >
                        <p className="ion-text-center">
                          <strong>Rank {item.rank}</strong> for selected time
                          frame
                        </p>
                        <p className="ion-text-center">({timeFrame})</p>
                      </Tooltip>
                    </>
                  )}
                  <p>{item.description}</p>
                  <p>
                    {item.item_ndc && `${item.item_ndc} | `}
                    Pack size:{' '}
                    <strong style={{ color: 'black' }}>{item.packSize}</strong>
                    {item.deaSchedule && ` | C-${romanize(item.deaSchedule)}`}
                    {item.unitDose && ' | UD'}
                  </p>
                </div>
              ),
            },
            {
              header: 'priceAverage',
              content: <p>{toUsd(item.priceAverage)}</p>,
            },
            {
              header: 'salesTotalQty',
              content: <p>{localizeNumber(item.salesTotalQty)}</p>,
            },
            {
              header: 'salesTotal',
              content: (
                <p className="text-color-black font-size-default font-weight-500">
                  {toUsd(item.salesTotalQty * item.priceAverage, 0)}
                </p>
              ),
            },
          ],
        };
        return row;
      })}
    />
  );
};

export default TableTopItems;
