import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonModal,
  IonNote,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import 'components/buyer/ModalManageOrgDomain.css';
import { freeEmailDomains } from 'data/freeEmailDomains';
import { useApi } from 'hooks/useApi';
import { Organization } from 'interfaces/Organization';
import { OrganizationDomain } from 'interfaces/OrganizationDomain';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from 'redux/appReducer';

export const ModalManageOrgDomain: React.FC<{
  isOpen: boolean;
  onDismiss: (data?: any) => void;
}> = ({ isOpen, onDismiss }) => {
  const api = useApi();
  const dispatch = useDispatch();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );

  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isTouched, setIsTouched] = useState(false);
  const [value, setValue] = useState<string>('');
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const rules = [
    {
      validate: () => !value.includes('@'),
      message: `Only include everything after the "@" symbol.`,
    },
    {
      pattern:
        /(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      message: 'Please enter a valid email domain',
    },
    {
      validate: () => !freeEmailDomains.includes(value),
      message: `The domain "${value}" matches a known free provider, and cannot be added to your organization.`,
    },
    {
      pattern: /^.{0,50}$/,
      message: 'Email domain is too long',
    },
  ];

  useEffect(() => {
    validate();
  }, [value]);

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const resetForm = () => {
    setValue('');
    setIsValid(false);
    setIsTouched(false);
    setErrorMessages([]);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (isValid) {
        submit();
      }
    }
  };

  // Validates based on regex pattern, or custom validation function
  const validate = () => {
    let valid = true;
    const newErrors: string[] = [];

    rules.forEach((rule) => {
      if (rule.pattern) {
        if (!rule.pattern.test(value)) {
          valid = false;
          newErrors.push(rule.message);
        }
      } else if (rule.validate) {
        if (!rule.validate()) {
          valid = false;
          newErrors.push(rule.message);
        }
      }
    });
    setIsValid(valid);
    setErrorMessages(newErrors);
  };

  const submit = () => {
    createOrganizationDomain();
  };

  const createOrganizationDomain = () => {
    if (!organization) {
      return;
    }
    setLoading(true);
    const formData = [
      {
        name: value,
        organization_id: organization.organization_id,
      },
    ];
    api
      .post('organization_domain', formData)
      .then((response) => {
        if (response.status === 201) {
          const data: OrganizationDomain[] = response.data;
          dispatch(
            setToast({
              header: 'Success',
              message: `The "${data[0].name}" domain has been added to your organization`,
            })
          );
          onDismiss();
        } else if (response.response.status === 500) {
          dispatch(
            setToast({
              header: 'Error',
              message: `We were unable to add this domain name. It may already be in use.`,
              color: 'danger',
              duration: 3000,
            })
          );
          console.log(response);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <IonModal
      isOpen={isOpen}
      className="orgDomainModal"
      onIonModalWillDismiss={onDismiss}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add Email Domain</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonItem
          lines="none"
          className={`${isValid && 'ion-valid'} ${
            isValid === false && 'ion-invalid'
          } ${isTouched && 'ion-touched'}`}
        >
          <IonInput
            className="rounded"
            placeholder={`your-organization.com`}
            onKeyDown={handleKeyDown}
            value={value}
            onIonChange={(e: any) => {
              setValue(e.detail.value.toLowerCase() as string);
            }}
            onIonBlur={() => {
              setIsTouched(true);
            }}
            disabled={loading}
          />
          <IonNote slot="error">
            <ul>
              {errorMessages.map((error) => (
                <li key={error}>
                  <span>{error}</span>
                </li>
              ))}
            </ul>
          </IonNote>
        </IonItem>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton color="medium" onClick={onDismiss}>
              Cancel
            </IonButton>
            <IonButton disabled={!isValid} onClick={submit}>
              Add
              {loading && (
                <IonSpinner
                  slot="end"
                  name="crescent"
                  style={{ marginLeft: '5px' }}
                ></IonSpinner>
              )}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
