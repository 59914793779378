import { useIsAuthenticated } from '@azure/msal-react';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { PageLoading } from 'pages/shared/PageLoading';
import { PageNotFound } from 'pages/shared/PageNotFound';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { isAdmin, isAnalyst, isBuyer, isSuperAdmin } from 'utils';

const RouteRoleSuper = ({ component: Component, ...rest }: any) => {
  const isAuthenticated = useIsAuthenticated();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.app.user
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && authUser && isSuperAdmin(authUser)) {
          return <Component {...props} />;
        } else if (
          isAuthenticated &&
          authUser &&
          (isAnalyst(authUser) || isBuyer(authUser) || isAdmin(authUser))
        ) {
          return <Redirect to="/" />;
        } else if (authUser && !isSuperAdmin(authUser)) {
          return <PageNotFound />;
        }
        return <PageLoading />;
      }}
    />
  );
};

export default RouteRoleSuper;
