import { useIsAuthenticated } from '@azure/msal-react';
import { Redirect, Route } from 'react-router-dom';

const RouteGuest = ({ component: Component, ...rest }: any) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
};

export default RouteGuest;
