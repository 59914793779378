import { IonButton, IonIcon, IonSpinner } from '@ionic/react';
import { pencilOutline, saveOutline } from 'ionicons/icons';

const ButtonEdit: React.FC<{
  editing: boolean;
  loading?: boolean;
  disabled?: boolean;
  size?: 'default' | 'small' | 'large';
  onClickEdit: () => void;
  onClickCancel: () => void;
  onClickSave: () => void;
}> = ({
  editing,
  loading = false,
  disabled = false,
  size = 'small',
  onClickEdit,
  onClickCancel,
  onClickSave,
}) => {
  return (
    <>
      <div className="d-inline-flex ion-align-items-center">
        {loading && (
          <IonSpinner
            name="crescent"
            color="primary"
            className="ion-margin-end"
          />
        )}
        {!editing && (
          <IonButton
            size={size}
            onClick={onClickEdit}
            disabled={loading || disabled}
          >
            Edit
            <IonIcon slot="end" icon={pencilOutline} />
          </IonButton>
        )}
        {editing && (
          <>
            <IonButton
              size={size}
              fill="clear"
              color="dark"
              onClick={onClickCancel}
              disabled={loading || disabled}
            >
              Cancel
            </IonButton>
            <IonButton
              size={size}
              disabled={loading || disabled}
              onClick={onClickSave}
            >
              Save
              <IonIcon slot="end" icon={saveOutline} />
            </IonButton>
          </>
        )}
      </div>
    </>
  );
};

export default ButtonEdit;
