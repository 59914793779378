import 'components/buyer/ChipAccount.css';
import { Account } from 'interfaces/Account';
import { Supplier } from 'interfaces/Supplier';
import { useSelector } from 'react-redux';
import { SupplierConfig, supplierConfig } from 'supplierConfig';

const ChipAccount: React.FC<{
  account: Account;
  style?: React.CSSProperties;
  small?: boolean;
}> = ({ account, style, small }) => {
  const allSuppliers: Supplier[] | null = useSelector(
    (state: any) => state.app.allSuppliers
  );
  const supplier = allSuppliers?.find((supplier) => {
    return supplier.supplier_id === account.supplier_id;
  });

  let customConfig: SupplierConfig | null = null;

  if (supplier) {
    customConfig =
      supplierConfig.find((config) => config.number === supplier.number) ||
      null;
  }

  return (
    <div className={`accountChip ${small ? 'small' : ''}`} style={style}>
      <p className="accountDescription">{account.description}</p>
      <p className="accountNumber">
        {customConfig && customConfig?.account?.transform
          ? customConfig.account.transform(account.number)
          : account.number}
      </p>
    </div>
  );
};

export default ChipAccount;
