import { faPartyHorn } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonCol,
  IonGrid,
  IonNote,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import Divider from 'components/shared/Divider';
import LogoSupplier from 'components/shared/LogoSupplier';
import { Supplier } from 'interfaces/Supplier';
import { SupplierTypeName } from 'types/SupplierTypeName';

export const ModalSupplierAddSlide1: React.FC<{
  loading: boolean;
  standardSuppliers: Supplier[];
  featuredSuppliers: Supplier[];
  recommendedSuppliers: Supplier[];
  filterType: SupplierTypeName;
  setFilterType: (value: SupplierTypeName) => void;
  setQuery: (value: string) => void;
  setSelectedSupplier: (value: Supplier) => void;
}> = ({
  loading,
  standardSuppliers,
  featuredSuppliers,
  recommendedSuppliers,
  filterType,
  setFilterType,
  setQuery,
  setSelectedSupplier,
}) => {
  return (
    <IonGrid className="ion-padding">
      <IonRow className="ion-text-left">
        <IonCol size="12" sizeMd="6">
          <IonRow>
            <IonCol>
              <p className="ion-no-margin">
                Select a supplier you would like to order from
              </p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonSegment
                value={filterType}
                onIonChange={(e) =>
                  setFilterType(e.detail.value as SupplierTypeName)
                }
              >
                <IonSegmentButton value="All">All</IonSegmentButton>
                <IonSegmentButton value="503b">503B</IonSegmentButton>
                <IonSegmentButton value="Manufacturer">
                  Manufacturer
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
          </IonRow>
        </IonCol>
        <IonCol
          size="12"
          sizeMd="5"
          offsetMd="1"
          className="d-flex ion-align-items-center"
        >
          <IonSearchbar
            autocomplete="off"
            placeholder="Search for a Supplier..."
            onIonChange={(e) => setQuery(e.detail.value as string)}
            className="ion-no-padding"
          />
        </IonCol>
      </IonRow>
      {!loading && recommendedSuppliers.length === 0 ? (
        <IonRow>
          <IonCol>
            <div className="d-flex flex-column ion-justify-content-center ion-align-items-center">
              <IonNote color="primary">
                <FontAwesomeIcon
                  icon={faPartyHorn}
                  style={{ fontSize: '55px' }}
                />
              </IonNote>
              <h2 className="font-size-large font-weight-400">
                All available Suppliers are already setup for this Location!
              </h2>
            </div>
          </IonCol>
        </IonRow>
      ) : null}
      {featuredSuppliers.length > 0 && <Divider text="FEATURED SUPPLIERS" />}
      <IonRow>
        {featuredSuppliers.map((supplier) => {
          return (
            <IonCol
              key={supplier.name}
              size="12"
              sizeSm="6"
              sizeMd="4"
              className="ion-text-center ion-padding"
            >
              <LogoSupplier
                src={supplier.logo}
                alt={supplier.name}
                onClick={() => setSelectedSupplier(supplier as Supplier)}
              />
            </IonCol>
          );
        })}
      </IonRow>

      <Divider />

      <IonRow className="ion-justify-content-center">
        {standardSuppliers.map((supplier) => {
          return (
            <IonCol
              key={supplier.name}
              size="12"
              sizeSm="6"
              sizeMd="4"
              className="ion-text-center ion-padding"
            >
              <LogoSupplier
                src={supplier.logo}
                alt={supplier.name}
                onClick={() => setSelectedSupplier(supplier as Supplier)}
              />
            </IonCol>
          );
        })}
      </IonRow>
    </IonGrid>
  );
};
