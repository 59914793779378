import { faCapsules } from '@fortawesome/pro-light-svg-icons';
import { useIonViewWillEnter } from '@ionic/react';
import CardStat from 'components/shared/CardStat';
import { useApi } from 'hooks/useApi';
import { Location } from 'interfaces/Location';
import { Supplier } from 'interfaces/Supplier';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const CardStatOrderedItems: React.FC<{
  label?: string;
  minDate: string | null;
  supplier?: Supplier;
}> = ({ label, minDate, supplier }) => {
  const api = useApi();
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const [loading, setLoading] = useState(false);
  const [orderedItemCount, setOrderedItemCount] = useState<number | null>(null);

  useEffect(() => {
    getSpendAmount();
  }, []);

  useIonViewWillEnter(() => {
    getSpendAmount();
  }, []);

  useEffect(() => {
    getSpendAmount();
  }, [minDate, selectedLocation]);

  const getSpendAmount = () => {
    if (!selectedLocation) {
      return;
    }
    setLoading(true);
    api
      .get(
        `locations/${selectedLocation.location_id}/stats/purchase_order_item_count`,
        {
          supplier_id: supplier ? supplier.supplier_id : undefined,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          if (data.count !== undefined && typeof data.count === 'number') {
            setOrderedItemCount(response.data.count);
          } else {
            setOrderedItemCount(null);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <CardStat
      color="primary"
      title={supplier ? `${supplier.name} Items Ordered` : 'Items Ordered'}
      stat={
        orderedItemCount !== null
          ? orderedItemCount.toLocaleString('en-US')
          : 'N/A'
      }
      statTitle={label}
      icon={faCapsules}
      loading={loading}
    />
  );
};
