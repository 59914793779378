import { IonButton, IonInput, IonItem, IonLabel, IonNote } from '@ionic/react';
import 'components/buyer/InputPoNumber.css';
import { ValidationRule } from 'components/shared/FormInput';
import LoadingInline from 'components/shared/LoadingInline';
import { useApi } from 'hooks/useApi';
import { ShoppingCart } from 'interfaces/ShoppingCart';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setToast } from 'redux/appReducer';

const InputPoNumber: React.FC<{
  shoppingCart: ShoppingCart;
}> = ({ shoppingCart }) => {
  const api = useApi();
  const [poNumber, setPoNumber] = useState<string | null>();
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<string[]>([]);

  const poNumberRules: ValidationRule[] = [
    {
      pattern: /^[a-zA-Z0-9-_\s./]+$/,
      message:
        'PO number may only contain numbers, letters, spaces, hyphens "-", and underscores "_", periods "." and forward slashes "/"',
    },
    {
      pattern: /^[^-_\s./]/,
      message:
        'PO number must not start with a space " ", hyphen "-" underscore "_", period ".", or forward slash "/"',
    },
    {
      pattern: /[^-_\s]$/,
      message:
        'PO number must not end with a space " ", hyphen "-", or underscore "_"',
    },
    {
      pattern: /^.{3,20}$/,
      message: 'PO Number must be between 3 and 20 characters long',
    },
  ];

  useEffect(() => {
    setPoNumber(shoppingCart.purchase_order_number);
  }, [shoppingCart]);

  useEffect(() => {
    if (poNumber === shoppingCart.purchase_order_number) {
      setChanged(false);
    } else {
      setChanged(true);
    }
    const debounceInput = setTimeout(() => {
      validateInput();
    }, 200);

    return () => clearTimeout(debounceInput);
  }, [poNumber]);

  const validateInput = () => {
    const errors: string[] = [];
    poNumberRules.forEach((rule) => {
      if (!rule.pattern.test(poNumber as string)) {
        errors.push(rule.message);
      }
    });
    setErrors(errors);
  };

  const onClickSave = () => {
    setLoading(true);
    api
      .put(`shopping_cart/${shoppingCart.shopping_cart_id}`, {
        purchase_order_number: poNumber,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setToast({
              message: 'Successfully updated PO',
            })
          );
          setChanged(false);
        } else if (response.response.status === 404) {
          dispatch(
            setToast({
              color: 'danger',
              message: 'That PO number is unavailable.',
              duration: 3000,
            })
          );
          setPoNumber(shoppingCart.purchase_order_number);
          setChanged(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <>
      <div className="d-flex ion-align-items-start">
        <IonItem
          className={`${
            errors.length === 0 ? 'ion-valid' : 'ion-invalid'
          } poNumber fullWidth`}
          lines="none"
          detail={false}
        >
          <IonLabel position="stacked">PO Number</IonLabel>
          <IonInput
            type="text"
            value={poNumber}
            onIonChange={(e: any) => setPoNumber(e.detail.value as string)}
            className="rounded"
            max="18"
          ></IonInput>
          <IonNote slot="error">
            {errors.map((error) => (
              <div key={error}>
                <span>{error}</span>
              </div>
            ))}
          </IonNote>
        </IonItem>
        <IonButton
          size="small"
          onClick={onClickSave}
          disabled={loading || !changed || errors.length > 0}
          style={{ marginTop: '38px' }}
        >
          {loading ? <LoadingInline color="light" /> : 'Save'}
        </IonButton>
      </div>
    </>
  );
};

export default InputPoNumber;
