import 'components/shared/Header.css';
import { Organization } from 'interfaces/Organization';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isSupplierOrganization } from 'utils';
import HeaderBuyer from 'components/shared/HeaderBuyer';
import HeaderSupplier from 'components/shared/HeaderSupplier';

const Header: React.FC = () => {
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const [isSupplier, setIsSupplier] = useState(false);
  useEffect(() => {
    if (organization) {
      setIsSupplier(isSupplierOrganization(organization));
    }
  }, [organization]);

  // Determine which header to use depending on the type of organization the user belongs to.
  return <>{isSupplier ? <HeaderSupplier /> : <HeaderBuyer />}</>;
};

export default Header;
