import 'components/shared/Subtitle.css';

const Subtitle: React.FC<{ children?: any; className?: string }> = ({
  children,
  className,
}) => {
  return <p className={`subtitle ${className ? className : ''}`}>{children}</p>;
};

export default Subtitle;
