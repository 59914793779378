import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRouterLink,
  IonRow,
  IonSearchbar,
  IonText,
  IonToggle,
  useIonAlert,
  useIonViewWillEnter,
} from '@ionic/react';
import ButtonFilter from 'components/shared/ButtonFilter';
import DataTable, {
  DataTableHeader,
  DataTableItemAction,
  DataTablePagination,
  DataTableRow,
} from 'components/shared/DataTable';
import {
  checkmarkCircleOutline,
  closeOutline,
  pencilOutline,
  refreshOutline,
} from 'ionicons/icons';

import 'components/buyer/TableUsers.css';
import { AppUser } from 'interfaces/AppUser';
import { formatDateTime, isAdmin } from 'utils';
import { useEffect, useState } from 'react';
import { useApi } from 'hooks/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { Organization } from 'interfaces/Organization';
import { setToast } from 'redux/appReducer';

const TableUsers: React.FC<{ isSupplier?: boolean }> = ({ isSupplier }) => {
  const filterButtonId = Math.floor(Math.random() * 10000000000).toString(16);
  const api = useApi();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const dispatch = useDispatch();
  const [presentAlert] = useIonAlert();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<AppUser[]>([]);

  const [filterAdmin, setFilterAdmin] = useState(false);
  const [filterDeactivated, setFilterDeactivated] = useState(false);

  const [search, setSearch] = useState<string | null>(null);
  const [pagination, setPagination] = useState<DataTablePagination | null>(
    null
  );
  const [totalCount, setTotalCount] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<string | null>('first_name');
  const [orderByDesc, setOrderByDesc] = useState<boolean>(false);
  const headers: DataTableHeader[] = [
    { text: 'Name', key: 'first_name', sortable: true },
    { text: 'Email Address', key: 'email_address', sortable: true },
    { text: 'Admin', key: 'admin', align: 'center' },
    { text: 'Created', key: 'created', sortable: true },
    { text: 'Updated', key: 'updated', sortable: true },
  ];

  useIonViewWillEnter(() => {
    if (pagination) {
      getUsers();
    }
  }, [pagination]);

  useEffect(() => {
    if (pagination) {
      getUsers();
    }
  }, [organization, filterDeactivated, filterAdmin, pagination, search]);

  const actions = (user: AppUser): DataTableItemAction[] => {
    const actions: DataTableItemAction[] = [
      {
        label: 'Manage User',
        icon: <IonIcon icon={pencilOutline} />,
        routerLink: (user: AppUser) => {
          return `/settings/users/${user.app_user_id}`;
        },
        callback: (user: AppUser) => {
          return;
        },
      },
    ];
    if (user.active) {
      actions.push({
        label: 'Deactivate',
        icon: <IonIcon icon={closeOutline} />,
        color: 'danger',
        callback: (user: AppUser) => {
          onClickDeactivate(user);
        },
      });
    } else {
      actions.push({
        label: 'Reactivate',
        icon: <IonIcon icon={refreshOutline} />,
        color: 'success',
        callback: (user: AppUser) => {
          onClickReactivate(user);
        },
      });
    }
    return actions;
  };

  const clearFilters = () => {
    setFilterDeactivated(false);
    setFilterAdmin(false);
  };

  const getUsers = () => {
    if (!organization || !pagination) {
      return;
    }
    setLoading(true);
    api
      .get(`organizations/${organization.organization_id}/users`, {
        limit: pagination?.limit,
        offset: pagination?.offset,
        order_by: orderBy ? orderBy : undefined,
        order_by_desc: orderByDesc,
        active: !filterDeactivated,
        app_role_id: filterAdmin ? 2 : undefined,
        search: search ? search : undefined,
      })
      .then((response) => {
        if (response.status === 200) {
          setUsers(response.data.result || []);
          setTotalCount(response.data.result?.length);
        }
        setLoading(false);
      });
  };

  const onClickDeactivate = (user: AppUser) => {
    presentAlert({
      header: 'Deactivate the following user?',
      message: `${user.first_name} ${user.last_name}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Deactivate',
          role: 'confirm',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          deactivateUser(user);
        }
      },
    });
  };

  const deactivateUser = (user: AppUser) => {
    setLoading(true);
    api
      .delete(`users/${user.app_user_id}`)
      .then((response) => {
        console.log(response);
        setLoading(false);
        getUsers();
        dispatch(
          setToast({
            message: 'Successfully deactivated user',
          })
        );
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onClickReactivate = (user: AppUser) => {
    presentAlert({
      header: 'Reactivate the following user?',
      message: `${user.first_name} ${user.last_name}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Reactivate',
          role: 'confirm',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          reactivateUser(user);
        }
      },
    });
  };

  const reactivateUser = (user: AppUser) => {
    setLoading(true);
    const userData = { ...user };
    userData.active = true;
    api
      .put(`users/${user.app_user_id}`, userData)
      .then((response) => {
        console.log(response);
        setLoading(false);
        getUsers();
        dispatch(
          setToast({
            message: 'Successfully reactivated user',
          })
        );
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <DataTable
      title="Users"
      headers={headers}
      actions={actions}
      loading={loading}
      onPaginationChange={(pagination: DataTablePagination) =>
        setPagination(pagination)
      }
      orderBy={orderBy}
      orderByDesc={orderByDesc}
      setOrderBy={setOrderBy}
      setOrderByDesc={setOrderByDesc}
      totalCount={totalCount}
      search={false}
      rows={users.map((user: AppUser): DataTableRow => {
        const row: DataTableRow = {
          item: user,
          key: user.app_user_id,
          columns: [
            {
              header: 'first_name',
              content: (
                <>
                  <IonRouterLink
                    className="font-size-large"
                    routerLink={
                      isSupplier
                        ? `/supplier/settings/users/${user.app_user_id}`
                        : `/settings/users/${user.app_user_id}`
                    }
                  >
                    {user.first_name} {user.last_name}
                  </IonRouterLink>
                  {!user.active ? (
                    <IonText color="danger">
                      <p className="ion-no-margin font-style-italic font-size-xs">
                        Deactivated
                      </p>
                    </IonText>
                  ) : null}
                </>
              ),
            },
            {
              header: 'email_address',
              content: <p>{user.email_address}</p>,
            },
            {
              header: 'admin',
              content: (
                <IonIcon
                  color="success"
                  className="font-size-large"
                  icon={isAdmin(user) ? checkmarkCircleOutline : ''}
                />
              ),
            },
            {
              header: 'created',
              content: (
                <p>{user.created ? formatDateTime(user.created) : null}</p>
              ),
            },
            {
              header: 'updated',
              content: (
                <>
                  {(!user.active && (
                    <IonText color="danger">
                      <p>
                        {user.updated ? formatDateTime(user.updated) : null}
                      </p>
                    </IonText>
                  )) || (
                    <p>{user.updated ? formatDateTime(user.updated) : null}</p>
                  )}
                </>
              ),
            },
          ],
        };
        return row;
      })}
    >
      <IonRow>
        <IonCol size="12" sizeSm="4">
          {(filterDeactivated || filterAdmin) && (
            <IonButton fill="clear" size="small" onClick={clearFilters}>
              Clear Filters
            </IonButton>
          )}
          <ButtonFilter id={filterButtonId} />
          <IonPopover
            id="usersFilterPopover"
            trigger={filterButtonId}
            triggerAction="click"
          >
            <IonContent>
              <IonList lines="none">
                <IonItem button detail={false}>
                  <IonLabel>Deactivated</IonLabel>
                  <IonToggle
                    slot="end"
                    checked={filterDeactivated}
                    onIonChange={(e) =>
                      setFilterDeactivated(e.detail.checked as boolean)
                    }
                  />
                </IonItem>
                <IonItem button detail={false}>
                  <IonLabel>Admin</IonLabel>
                  <IonToggle
                    slot="end"
                    checked={filterAdmin}
                    onIonChange={(e) =>
                      setFilterAdmin(e.detail.checked as boolean)
                    }
                  />
                </IonItem>
              </IonList>
            </IonContent>
          </IonPopover>
        </IonCol>
        <IonCol size="12" sizeSm="8">
          <IonSearchbar
            value={search}
            onIonChange={(e: any) => setSearch(e.detail.value as string)}
            debounce={350}
            className="ion-text-left"
          />
        </IonCol>
      </IonRow>
    </DataTable>
  );
};

export default TableUsers;
