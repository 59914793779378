import { Account } from 'interfaces/Account';
import { AppUser } from 'interfaces/AppUser';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { Organization } from 'interfaces/Organization';
import { Supplier } from 'interfaces/Supplier';
import { useSelector } from 'react-redux';
import { supplierConfig } from 'supplierConfig';

export const round = (number: number, accuracy: number): number => {
  return parseInt(
    (Math.round(number * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(accuracy)
  );
};

/**
 * Accept a number, and return that number converted to USD currency
 * @param number
 * @param decimalDigits
 * @returns
 */
export const toUsd = (number: number, decimalDigits = 2): string => {
  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: decimalDigits,
  });
};

/**
 * Accept a number, and return that number converted to USD currency
 * @param number
 * @returns
 */
export const localizeNumber = (number: number): string => {
  return number.toLocaleString('en-US');
};

/**
 * Accept a number up to 10 and returns the roman numeral
 * @param number
 * @returns
 */
export const romanize = (number: number): string | typeof NaN => {
  if (number > 10) {
    return NaN;
  }
  const numerals = [
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
    'X',
  ];
  return numerals[number - 1];
};

/**
 * Accept a datestring like "2022-10-11" and convert it into a more readable date string.
 *
 * @param dateString
 * @param options https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
 * @returns string
 */
export const formatDate = (
  dateString: string,
  options?: Intl.DateTimeFormatOptions
): string => {
  const date = new Date(dateString);
  let formatOptions: Intl.DateTimeFormatOptions = options || {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  return date.toLocaleDateString('en-US', formatOptions);
};
/**
 * Accept a date-time string like "2023-01-16T16:38:49.546" and convert it into a more readable dateTime string.
 *
 * @param dateTimeString
 * @param options https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
 * @returns string
 */
export const formatDateTime = (
  dateTimeString: string,
  options?: Intl.DateTimeFormatOptions
): string => {
  const mediumTime = new Intl.DateTimeFormat(
    'en',
    options || {
      timeStyle: 'short',
      dateStyle: 'short',
    }
  );
  return mediumTime.format(new Date(dateTimeString));
};

/**
 * Subtract the given number of days, and returns the corresponding date.
 * formatted like "2023-05-16"
 *
 * @param days
 * @returns string
 */
export const subDays = (days: number): string => {
  let date = new Date();
  date.setDate(date.getDate() - days);
  return date.toISOString().split('T')[0];
};

/**
 * Subtracts the given number of months from today's date, and returns the corresponding date.
 * formatted like "2022-05-16"
 *
 * @param days
 * @returns string
 */
export const subMonths = (months: number): string => {
  let date = new Date();
  date.setMonth(date.getMonth() - months);
  return date.toISOString().split('T')[0];
};

/**
 * Format an 11-digit NDC
 *
 * @param input string like "12345678987"
 * @returns string like "12345-6789-87"
 */
export const formatNdc = (input: string) => {
  if (input.length > 11) {
    console.log(
      'Unable to format ndc string. Reason: String longer than 11 characters',
      input
    );
    return input;
  } else if (input === 'NONE') {
    return '';
  }
  let normalized = input;
  while (normalized.length < 11) {
    normalized = '0' + normalized;
  }
  let ndc = [normalized.slice(0, 5), '-', normalized.slice(5)].join('');
  ndc = [ndc.slice(0, 10), '-', ndc.slice(10)].join('');
  return ndc;
};

/**
 * @param input string | number like "4365557550"
 * @returns string like "(436) 555-7550"
 */
export const formatPhone = (input: string | number): string => {
  /*
    if 10 digits like 4365557550, return "(436) 555-7550"
    if 11 digits like 14365557550, return "+1 (436) 555-7550", where "+1" is the country code
    if 7 digits (unlikely) like 5557550, return "555-7550"
  */
  let stringified = `${input}`;
  let formatted = '';

  if (stringified.length === 11) {
    formatted = stringified.replace(
      /(\d{1})(\d{3})(\d{3})(\d{4})/,
      '+$1 ($2) $3-$4'
    );
  } else if (stringified.length === 10) {
    formatted = stringified.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  } else if (stringified.length === 7) {
    formatted = stringified.replace(/(\d{3})(\d{4})/, '$1-$2');
  } else {
    console.log('Unable to format phone number:', stringified);
    return stringified;
  }
  return formatted;
};

/**
 *
 * @param number1
 * @param number2
 * @returns
 */
export const calculatePercentDiff = (
  number1: number,
  number2: number
): number => {
  const diff = number1 - number2;
  const percent = round((diff / number1) * 100, 0);
  return percent;
};

/**
 * Accepts 2 JavaScript dates, and returns the difference in days between the two.
 *
 * @param date1
 * @param date2
 * @returns number
 *
 */
export const diffInDays = (date1: any, date2: any): number => {
  const diffSeconds = Math.abs(date1 - date2);
  const diffDays = Math.floor(diffSeconds / (1000 * 60 * 60 * 24));
  return diffDays;
};

/**
 * Accepts 2 JavaScript dates, and returns the difference in seconds between the two.
 *
 * @param date1
 * @param date2
 * @returns number
 *
 */
export const diffInSeconds = (date1: any, date2: any): number => {
  const diffSeconds = Math.abs(date1 - date2);
  return diffSeconds;
};

export const getUserInitials = (
  firstName: string,
  lastName: string
): string => {
  return `${firstName[0]}${lastName[0]}`;
};

/**
 * Determine if the given user has the Analyst role.
 * @param user AppUser
 * @returns boolean
 */
export const isAnalyst = (user: AppUser | AppUserSelf): boolean => {
  return user?.app_role_name === 'analyst';
};
/**
 * Determine if the given user has the Buyer role.
 * @param user AppUser
 * @returns boolean
 */
export const isBuyer = (user: AppUser | AppUserSelf): boolean => {
  return user?.app_role_name === 'buyer';
};

/**
 * Determine if the given user has the Administrator role
 * @param user AppUser
 * @returns boolean
 */
export const isAdmin = (user: AppUser | AppUserSelf): boolean => {
  return user?.app_role_name === 'admin';
};

/**
 * Determine if the given user has the Super Admin (Application Administrator) role
 * @param user AppUser
 * @returns boolean
 */
export const isSuperAdmin = (user: AppUser | AppUserSelf): boolean => {
  return user?.app_role_name === 'superadmin';
};

/**
 * Determine if the given user belongs to a buyer organization
 * @param user AppUser
 * @returns boolean
 */
export const isBuyerOrganization = (
  organization: Organization | null
): boolean => {
  return organization?.organization_type_name === 'buyer';
};

/**
 * Determine if the given user belongs to a supplier organization
 * @param user AppUser
 * @returns boolean
 */
export const isSupplierOrganization = (
  organization: Organization | null
): boolean => {
  return organization?.organization_type_name === 'supplier';
};
