import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { AccountType } from 'interfaces/AccountType';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingInline from 'components/shared/LoadingInline';
import { useApi } from 'hooks/useApi';
import { setAccountTypes } from 'redux/appReducer';
import 'components/buyer/SelectAccountType.css';

export const SelectAccountType: React.FC<{
  value: number | null;
  setValue: (value: number) => void;
  disabled?: boolean;
}> = ({ value, setValue, disabled }) => {
  const api = useApi();
  const dispatch = useDispatch();
  const accountTypes: AccountType[] | null = useSelector(
    (state: any) => state.app.accountTypes
  );

  useEffect(() => {
    getAccountTypes();
  }, []);

  const getAccountTypes = () => {
    api.get('account_types').then((response) => {
      if (response.status === 200) {
        const data: AccountType[] = response.data;
        dispatch(setAccountTypes(data));
      }
    });
  };

  return (
    <IonItem color="transparent" className="selectAccountType">
      <IonLabel position="stacked" className="margin-left-xs">
        Account Type
      </IonLabel>
      <IonSelect
        value={value}
        interface="popover"
        onIonChange={(e) => setValue(e.detail.value)}
        placeholder="Select Account Type"
        disabled={disabled}
      >
        {accountTypes ? (
          <>
            {accountTypes.map((accountType) => (
              <IonSelectOption
                key={accountType.account_type_id}
                value={accountType.account_type_id}
              >
                {accountType.name}
              </IonSelectOption>
            ))}
          </>
        ) : (
          <LoadingInline />
        )}
      </IonSelect>
    </IonItem>
  );
};
