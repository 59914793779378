import { IonIcon, useIonViewWillEnter } from '@ionic/react';
import { useApi } from 'hooks/useApi';
import DataTable, {
  DataTableHeader,
  DataTableItemAction,
  DataTableRow,
} from 'components/shared/DataTable';
import { pencilOutline } from 'ionicons/icons';
import { useState } from 'react';
import axios from 'axios';

interface Account {
  number: number;
  name: string;
  type: '340B' | 'GPO' | 'WAC';
  addressLine1: string;
  addressLine2: string | null;
  city: string;
  state: string;
  zip: string;
}

const TableSupplierAccounts: React.FC = () => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>([]);

  const headers: DataTableHeader[] = [
    { text: 'Account Number', key: 'number' },
    { text: 'Account Name', key: 'name' },
    { text: 'Account Type', key: 'type' },
    { text: 'Address', key: 'address' },
  ];

  useIonViewWillEnter(() => {
    getAccounts();
  }, []);

  const mockAccounts: Account[] = [
    {
      number: 73825495,
      name: 'Wyman - Goodwin',
      type: '340B',
      addressLine1: '231 Mathew Streets',
      addressLine2: null,
      city: 'Henderson',
      state: 'DE',
      zip: '91634',
    },
    {
      number: 29970494,
      name: 'Fritsch Inc',
      type: 'WAC',
      addressLine1: '59342 Emard Park',
      addressLine2: null,
      city: 'Arvada',
      state: 'MI',
      zip: '26916-3258',
    },
    {
      number: 77104292,
      name: 'Ullrich and Sons',
      type: 'WAC',
      addressLine1: '060 Isaiah Views',
      addressLine2: null,
      city: 'Monroe',
      state: 'WA',
      zip: '08593',
    },
    {
      number: 10781324,
      name: 'Cassin Group',
      type: '340B',
      addressLine1: '476 Gussie Forge',
      addressLine2: null,
      city: 'Rock Hill',
      state: 'MN',
      zip: '80800',
    },
    {
      number: 94213652,
      name: 'Connelly - Murphy',
      type: 'GPO',
      addressLine1: '65829 Eugenia Center',
      addressLine2: null,
      city: 'Hillsboro',
      state: 'VA',
      zip: '56396-9171',
    },
    {
      number: 90100869,
      name: 'Klocko Inc',
      type: '340B',
      addressLine1: '3730 Jesus Wells',
      addressLine2: null,
      city: 'Garland',
      state: 'ID',
      zip: '90178',
    },
    {
      number: 20822338,
      name: 'Ledner - McCullough',
      type: 'GPO',
      addressLine1: '54038 Rempel Ferry',
      addressLine2: null,
      city: 'Germantown',
      state: 'MN',
      zip: '27362-9309',
    },
    {
      number: 75419028,
      name: 'Marquardt, Cormier and Yost',
      type: '340B',
      addressLine1: '211 Dawn Vista',
      addressLine2: null,
      city: 'Carson',
      state: 'OH',
      zip: '17534',
    },
    {
      number: 24796098,
      name: 'Hoeger - Murray',
      type: '340B',
      addressLine1: '12188 Hilma Via',
      addressLine2: null,
      city: 'Portsmouth',
      state: 'MS',
      zip: '28401',
    },
    {
      number: 16264816,
      name: 'Will, Brekke and Haley',
      type: 'WAC',
      addressLine1: '52181 Sunny Creek',
      addressLine2: null,
      city: 'Fort Pierce',
      state: 'ID',
      zip: '34887',
    },
  ];

  const getAccounts = () => {
    setLoading(true);
    setTimeout(() => {
      setAccounts(mockAccounts);
      setLoading(false);
    }, 150);

    // axios
    //   .get('supplierAccounts', { baseURL: process.env.REACT_APP_MOCK_URL })
    //   .then(({ data }) => {
    //     setAccounts(data);
    //     setLoading(false);
    //   });
  };

  const actions = (account: any): DataTableItemAction[] => {
    return [
      {
        label: 'Manage Account',
        icon: <IonIcon icon={pencilOutline} />,
        callback: (account: any) => {
          console.log('manage account');
        },
      },
    ];
  };

  return (
    <DataTable
      title="Accounts"
      headers={headers}
      actions={actions}
      rows={accounts.map((account: any): DataTableRow => {
        const row: DataTableRow = {
          item: account,
          key: account.number,
          columns: [
            {
              header: 'number',
              content: <p>{account.number}</p>,
            },
            {
              header: 'name',
              content: <p>{account.name}</p>,
            },
            {
              header: 'type',
              content: <p>{account.type}</p>,
            },
            {
              header: 'address',
              content: (
                <>
                  <p>
                    {account.addressLine1}
                    {account.addressLine2 && (
                      <span>,&nbsp;{account.addressLine2}</span>
                    )}
                  </p>
                  <p>
                    {account.city} {account.state}, {account.zip}
                  </p>
                </>
              ),
            },
          ],
        };
        return row;
      })}
      loading={loading}
    ></DataTable>
  );
};

export default TableSupplierAccounts;
