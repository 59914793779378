import { useMsal } from '@azure/msal-react';
import { faHeadset, faHospital } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRow,
  useIonAlert,
  useIonModal,
} from '@ionic/react';
import { changePasswordRequest } from 'authConfig';
import ModalChangeLocation from 'components/buyer/ModalChangeLocation';
import Divider from 'components/shared/Divider';
import ModalTerms from 'components/shared/ModalTerms';
import Subtitle from 'components/shared/Subtitle';
import UserAvatar from 'components/shared/UserAvatar';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { Location } from 'interfaces/Location';
import { Organization } from 'interfaces/Organization';
import {
  documentLockOutline,
  documentTextOutline,
  logOutOutline,
  openOutline,
  refreshOutline,
} from 'ionicons/icons';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/appReducer';
import { isBuyerOrganization } from 'utils';
import ModalContactUs from 'components/shared/ModalContactUs';
import { MsalAccessToken } from 'interfaces/MsalAccessToken';

interface MenuItem {
  icon: any;
  label: any;
  onClick?: (data?: any) => void | any;
  href?: string;
  target?: string;
  divider: boolean;
  isVisible: boolean;
}

const UserInfoPopover: React.FC = () => {
  const { instance } = useMsal();
  const msalAccessToken: MsalAccessToken | null = useSelector(
    (state: any) => state.app.accessToken
  );
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.app.user
  );
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const dispatch = useDispatch();
  const [termsOpen, setTermsOpen] = useState(false);
  const [contactUsOpen, setContactUsOpen] = useState(false);
  const [presentAlert] = useIonAlert();

  const [presentLocations, dismissLocations] = useIonModal(
    ModalChangeLocation,
    {
      onDismiss: (data: string, role: string) => dismissLocations(data, role),
    }
  );

  const showLocations = () => {
    presentLocations({
      onWillDismiss: (e: any) => {},
    });
  };

  const onClickLogout = () => {
    presentAlert({
      header: 'Logout',
      message: 'Are you sure you want to Logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Logout',
          role: 'confirm',
        },
      ],
      onDidDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          dispatch(logout());
          instance.logoutRedirect();
        }
      },
    });
  };

  const changePassword = async () => {
    try {
      await instance.loginRedirect(changePasswordRequest);
    } catch (error) {
      console.error('Failed to trigger password change policy:', error);
    }
  };

  const menuItems: Array<MenuItem> = [
    {
      icon: faHospital,
      label: (
        <>
          <div>Change Location</div>
          <Subtitle>{selectedLocation?.name}</Subtitle>
        </>
      ),
      onClick: showLocations,
      divider: true,
      isVisible: isBuyerOrganization(organization),
    },
    {
      icon: documentTextOutline,
      label: 'Terms of Use',
      onClick: () => setTermsOpen(true),
      divider: false,
      isVisible: isBuyerOrganization(organization),
    },
    {
      icon: documentLockOutline,
      label: (
        <>
          Privacy Policy <IonIcon icon={openOutline} />
        </>
      ),
      href: '/assets/doc/SM_IT_Privacy_Declaration_v1.00.pdf',
      target: '_blank',
      divider: false,
      isVisible: isBuyerOrganization(organization),
    },
    {
      icon: faHeadset,
      label: 'Contact Us',
      onClick: () => setContactUsOpen(true),
      divider: true,
      isVisible: true,
    },
    {
      icon: refreshOutline,
      label: 'Change Password',
      onClick: () => changePassword(),
      divider: false,
      isVisible: msalAccessToken?.auth_src === 'local',
    },
    {
      icon: logOutOutline,
      label: 'Logout',
      onClick: onClickLogout,
      divider: false,
      isVisible: !!authUser,
    },
  ];

  return (
    <>
      <IonPopover
        id="userInfoPopover"
        trigger="userInfo"
        triggerAction="click"
        showBackdrop={true}
        dismissOnSelect={true}
      >
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                {authUser?.app_user_id && (
                  <IonList lines="none">
                    <IonItem
                      routerDirection="forward"
                      routerLink="/profile"
                      detail={false}
                    >
                      <div slot="start">
                        <UserAvatar user={authUser} />
                      </div>
                      <IonLabel>
                        <h2>
                          {authUser.first_name} {authUser.last_name}
                          <Subtitle className="text-capitalize">
                            {authUser.app_role_name}
                          </Subtitle>
                        </h2>
                        <p>{authUser.email_address}</p>
                      </IonLabel>
                    </IonItem>
                  </IonList>
                )}

                <Divider />

                <IonList lines="none">
                  {menuItems.map((menuItem: MenuItem, index: number) => {
                    if (menuItem.isVisible) {
                      return (
                        <Fragment key={index}>
                          <IonItem
                            button
                            detail={false}
                            onClick={menuItem.onClick || undefined}
                            href={menuItem.href || undefined}
                            target={menuItem.target || undefined}
                          >
                            {(typeof menuItem.icon === 'string' && (
                              <IonIcon slot="start" icon={menuItem.icon} />
                            )) || (
                              <span slot="start">
                                <FontAwesomeIcon icon={menuItem.icon} />
                              </span>
                            )}
                            <IonLabel>{menuItem.label}</IonLabel>
                          </IonItem>
                          {menuItem.divider ? (
                            <Divider className="ion-no-margin" />
                          ) : null}
                        </Fragment>
                      );
                    }
                  })}
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPopover>
      <ModalTerms
        isOpen={termsOpen}
        onDismiss={() => {
          setTermsOpen(false);
        }}
      />
      <ModalContactUs
        isOpen={contactUsOpen}
        onDismiss={() => {
          setContactUsOpen(false);
        }}
      />
    </>
  );
};

export default UserInfoPopover;
