export interface ItemCustomAttribute {
  key: string;
  label: string;
  transform: (value: any) => any;
}

export interface SupplierConfig {
  number: string;
  item_custom_attributes?: ItemCustomAttribute[];
  account: {
    transform?: (value: string) => string;
    accountKey: string;
    accountLabel: string;
    accountHelperText: string;
    accountRules: Array<{
      pattern: RegExp;
      message: string;
    }>;
    validationKey: string;
    validationLabel: string;
    validationHelperText: string;
    validationRules: Array<{
      pattern: RegExp;
      message: string;
    }>;
  };
}

export const supplierConfig: SupplierConfig[] = [
  {
    number: 'DEMO', // Demo Manufacturer
    item_custom_attributes: [
      {
        key: 'dea_number',
        label: 'Dea Number',
        transform: (value: any): any => {
          return value;
        },
      },
      {
        key: 'contract_start_date',
        label: 'Contract Start Date',
        transform: (value: any): any => {
          return value;
        },
      },
      {
        key: 'contract_end_date',
        label: 'Contract End Date',
        transform: (value: any): any => {
          return value;
        },
      },
      {
        key: 'minimum_order_quantity',
        label: 'Minimum Order Quantity',
        transform: (value: any): any => {
          return parseInt(value);
        },
      },
    ],
    account: {
      accountKey: 'account_id',
      accountLabel: 'Account Number',
      accountHelperText: 'This is provided by Demo Manufacturer',
      accountRules: [
        {
          pattern: /.*/,
          message: '',
        },
      ],
      validationKey: 'zip',
      validationLabel: 'Zip Code',
      validationHelperText:
        'Enter the zip code associated with your Account number',
      validationRules: [
        {
          pattern: /.*/,
          message: '',
        },
      ],
    },
  },
  {
    number: '005387188T', // Sandoz QA
    item_custom_attributes: [
      {
        key: 'dea_number',
        label: 'Dea Number',
        transform: (value: any): any => {
          return value;
        },
      },
      {
        key: 'contract_start_date',
        label: 'Contract Start Date',
        transform: (value: any): any => {
          return value;
        },
      },
      {
        key: 'contract_end_date',
        label: 'Contract End Date',
        transform: (value: any): any => {
          return value;
        },
      },
      {
        key: 'minimum_order_quantity',
        label: 'Minimum Order Quantity',
        transform: (value: any): any => {
          return parseInt(value);
        },
      },
    ],
    account: {
      accountKey: 'ship_to',
      accountLabel: 'Ship To Number',
      accountHelperText: 'Enter the Ship To Number, provided by Sandoz',
      accountRules: [
        {
          pattern: /.*/,
          message: '',
        },
      ],
      validationKey: 'zip',
      validationLabel: 'Zip Code',
      validationHelperText:
        'Enter the zip code associated with your Ship To Number',
      validationRules: [
        {
          pattern: /.*/,
          message: '',
        },
      ],
      transform: (value: string): string => {
        const contractNumber = value.split('-')[2];
        if (contractNumber !== undefined) {
          return contractNumber;
        }
        // if transform results in undefined, just return the initial value
        return value;
      },
    },
  },
  {
    number: '005387188', // Sandoz PRODUCTION
    item_custom_attributes: [
      {
        key: 'dea_number',
        label: 'Dea Number',
        transform: (value: any): any => {
          return value;
        },
      },
      {
        key: 'contract_start_date',
        label: 'Contract Start Date',
        transform: (value: any): any => {
          return value;
        },
      },
      {
        key: 'contract_end_date',
        label: 'Contract End Date',
        transform: (value: any): any => {
          return value;
        },
      },
      {
        key: 'minimum_order_quantity',
        label: 'Minimum Order Quantity',
        transform: (value: any): any => {
          return parseInt(value);
        },
      },
    ],
    account: {
      accountKey: 'ship_to',
      accountLabel: 'Ship To Number',
      accountHelperText: 'Enter the Ship To Number, provided by Sandoz',
      accountRules: [
        {
          pattern: /.*/,
          message: '',
        },
      ],
      validationKey: 'zip',
      validationLabel: 'Zip Code',
      validationHelperText:
        'Enter the zip code associated with your Ship To Number',
      validationRules: [
        {
          pattern: /.*/,
          message: '',
        },
      ],
      transform: (value: string): string => {
        const contractNumber = value.split('-')[2];
        if (contractNumber !== undefined) {
          return contractNumber;
        }
        // if transform results in undefined, just return the initial value
        return value;
      },
    },
  },
];
