import { IonBadge } from '@ionic/react';

const BadgeOrderStatus: React.FC<{ status: string; className?: string }> = ({
  status,
  className,
}) => {
  let color = '';
  switch (status) {
    case 'Pending':
      color = 'light';
      break;
    case 'Submitted':
      color = 'warning';
      break;
    case 'Acknowledged':
      color = 'secondary';
      break;
    case 'Shipped':
      color = 'primary';
      break;
    case 'Invoiced':
      color = 'success';
      break;
    default:
      color = 'light';
      break;
  }
  const badgeStyle = () => {
    let style: any = {
      fontWeight: 400,
    };
    if (color === 'light') {
      style = {
        ...style,
        borderColor: 'var(--ion-color-light-shade',
        borderWidth: '1px',
        borderStyle: 'solid',
      };
    }
    return style;
  };
  return (
    <IonBadge color={color} style={badgeStyle()} className={className || ''}>
      {status}
    </IonBadge>
  );
};

export default BadgeOrderStatus;
