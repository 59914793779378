import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonNote,
  IonSpinner,
} from '@ionic/react';
import { useApi } from 'hooks/useApi';
import { Location } from 'interfaces/Location';
import { ShoppingCartItem } from 'interfaces/ShoppingCartItem';
import {
  checkmarkCircleOutline,
  refreshCircleOutline,
  saveOutline,
} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCarts, setToast } from 'redux/appReducer';
import 'components/buyer/InputCartQuantity.css';

const InputCartQuantity: React.FC<{
  item: ShoppingCartItem;
  refresh: boolean;
  setRefresh: (value: boolean) => void;
}> = ({ item, refresh, setRefresh }) => {
  const api = useApi();
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const [quantity, setQuantity] = useState<number>(0);
  const [changed, setChanged] = useState(false);
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setQuantity(item.quantity || 0);
  }, [item]);

  useEffect(() => {
    if (focused) {
      if (quantity === item.quantity) {
        setChanged(false);
      } else {
        setChanged(true);
      }
      if (quantity && quantity > 0) {
        setError(null);
        updateItem();
      } else {
        setError('Enter a valid Quantity');
      }
    }
  }, [quantity]);

  const handleFocus = (e: any) => {
    e.target.select();
    setFocused(true);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && changed) {
      e.preventDefault();
      updateItem();
    }
  };

  const updateItem = () => {
    setLoading(true);
    const putData = {
      quantity: quantity,
      supplier_item_number: item.supplier_item_number,
    };
    api
      .put(`shopping_cart_item/${item.shopping_cart_item_id}`, putData)
      .then((response) => {
        if (response.status === 200) {
          setRefresh(!refresh);
          setChanged(false);
          setLoading(false);
          setFocused(false);
          dispatch(
            setToast({
              header: 'Quantity updated',
              message: item.item_description || '',
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div className="inputCartQuantity d-flex ion-align-items-center">
      <IonItem
        lines="none"
        className={`${!error && 'ion-valid'} ${error && 'ion-invalid'} ${
          focused && 'ion-touched'
        }`}
      >
        <IonInput
          type="number"
          value={quantity}
          disabled={loading}
          min={0}
          onFocus={(e: any) => handleFocus(e)}
          onIonChange={(e: any) => {
            setQuantity(parseInt(e.detail.value));
          }}
          debounce={350}
        />
        {loading ? <IonSpinner name="crescent" color="primary" /> : null}

        <IonNote slot="error">{error}</IonNote>
      </IonItem>
    </div>
  );
};
export default InputCartQuantity;
