import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
  IonApp,
  IonCol,
  IonRow,
  IonSplitPane,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Menu from 'components/shared/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import 'styles/variables.css';

/* KendoReact Theme */
import '@progress/kendo-theme-default/dist/all.css';

/* Inline Manual stylesheet*/
import 'styles/inline-manual.css';

/* Custom stylesheet */
import 'styles/App.css';

import ModalAddSupplier from 'components/buyer/ModalSupplierAdd';
import Header from 'components/shared/Header';
import LogoTrulla from 'components/shared/LogoTrulla';
import ToastManager from 'components/shared/ToastManager';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { Location } from 'interfaces/Location';
import { Organization } from 'interfaces/Organization';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import {
  logout,
  setAccounts,
  setAllSuppliers,
  setCartItems,
  setCarts,
  setLocation,
  setOrganization,
  setSupplier,
  setUser,
  setUserLoading,
} from 'redux/appReducer';
import Routes from 'routes/Routes';

import { AxiosError } from 'axios';
import { useAlertAddSupplier } from 'hooks/useAlertAddSupplier';
import { useApi } from 'hooks/useApi';
import { Account } from 'interfaces/Account';
import { ShoppingCart } from 'interfaces/ShoppingCart';
import { ShoppingCartItem } from 'interfaces/ShoppingCartItem';
import { isAnalyst, isSupplierOrganization } from 'utils';
import { useIdleTimer } from 'react-idle-timer';

setupIonicReact({
  mode: 'ios',
  swipeBackEnabled: false,
  innerHTMLTemplatesEnabled: false,
});

const App: React.FC = () => {
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const alertAddSupplier = useAlertAddSupplier();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.app.user
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const supplierAccounts: Account[] | null = useSelector(
    (state: any) => state.app.accounts
  );
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const [addSupplierModalOpen, setAddSupplierModalOpen] = useState(false);

  const dispatch = useDispatch();
  const api = useApi();

  // Load event handler
  useEffect(() => {
    const handleLoad = () => {
        let lastActive = localStorage.getItem("lastActive");
        if(lastActive && localStorage.getItem("td-token"))
        {
          let currentDate = Date.now();

          const timeDifference = currentDate - +lastActive;

          // Convert the time difference to minutes
          const minutesDifference = Math.floor(timeDifference / (1000 * 60));

          if(minutesDifference > getSessionTimeOutValue())
          {
            console.log("Session expired");
            dispatch(logout());
            instance.logoutRedirect();
          }

          if(lastActive)
          {
            localStorage.removeItem("lastActive");
          }
        }
      
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  // Unload event handler
  useEffect(() => {
    const handleUnload = () => {
      
      console.log('application is closing');
      let currentDate = Date.now();
      if(localStorage.getItem("td-token"))
      {
        localStorage.setItem("lastActive", currentDate.toString())
      }
      // You can add your unload event logic here
    };

    // Add the unload event listener when the component mounts
    window.addEventListener('beforeunload', handleUnload);

    // Remove the unload event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  const { isIdle } = useIdleTimer({
    timeout: 60000 * getSessionTimeOutValue(), // 60 seconds
    onIdle: () => {
      if(isAuthenticated)
      {
        console.log("Session expired");
        dispatch(logout());
        instance.logoutRedirect();
      }
    },
    crossTab : true,
    syncTimers: 1000
  });

  function getSessionTimeOutValue()
  {
    let timeoutValue = process.env.REACT_APP_SESSION_TIMEOUT; // In minutes
    let defaultTimeoutValue = 60; // In minutes

    if(timeoutValue) {
      return +timeoutValue;
    }

    return defaultTimeoutValue;
  }

  useEffect(() => {
    if (isAuthenticated) {
      if (accounts.length > 0) {
        instance.setActiveAccount(accounts[0]);
      }
      dispatch(setUserLoading(true));
      api.get('users/self').then((response) => {
        // this response can be either a success or error response.
        if (response instanceof AxiosError) {
          const errorMessage = response?.response?.data?.error;
          // At this point, the user is authenticated through Azure B2C, but not in the application database.
          if (errorMessage && errorMessage === 'no rows in result set') {
            dispatch(setUserLoading(false));
          }
        } else {
          dispatch(setUserLoading(false));
          dispatch(setUser(response.data));
        }
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (authUser) {
      // update inline manual user info
      window.InlineManual('update', {
        email: authUser.email_address,
        name: `${authUser.first_name} ${authUser.last_name}`,
      });

      api.get(`organizations/${authUser.organization_id}`).then(({ data }) => {
        dispatch(setOrganization(data));
      });
      api.get('suppliers').then((response) => {
        dispatch(setAllSuppliers(response.data));
      });
      const locationId = localStorage.getItem('td-location-id');
      if (locationId) {
        api.get(`locations/${locationId}`).then((response) => {
          if (response.status === 200) {
            dispatch(setLocation(response.data));
          } else {
            dispatch(setLocation(null));
          }
        });
      }
    }
  }, [authUser]);

  useEffect(() => {
    if (organization?.organization_type_name === 'supplier') {
      getSupplierByOrganizationId();
    }
  }, [organization]);

  useEffect(() => {
    getAccounts();
    getShoppingCarts();
  }, [selectedLocation]);

  useEffect(() => {
    if (supplierAccounts && supplierAccounts.length === 0) {
      alertAddSupplier.showAlert((e: any) => {
        if (e.detail.role === 'confirm') {
          if (authUser && !isAnalyst(authUser)) {
            setAddSupplierModalOpen(true);
          }
        }
      });
    }
  }, [supplierAccounts]);

  const getAccounts = () => {
    if (!selectedLocation) {
      return;
    }
    api
      .get(`locations/${selectedLocation.location_id}/accounts`)
      .then((response) => {
        if (response.status === 200) {
          const data: Account[] = response.data;
          dispatch(setAccounts(data));
        }
      });
  };

  const getShoppingCarts = () => {
    if (!selectedLocation || (authUser && isAnalyst(authUser))) {
      return;
    }
    api
      .get(`locations/${selectedLocation.location_id}/shopping_carts`)
      .then((response) => {
        if (response.status === 200) {
          const data: ShoppingCart[] = response.data;
          dispatch(setCarts(data));
          getShoppingCartItems();
        }
      });
  };

  const getShoppingCartItems = () => {
    if (!selectedLocation) {
      return;
    }
    api
      .get(`locations/${selectedLocation.location_id}/shopping_cart_items`)
      .then((response) => {
        if (response.status === 200) {
          const data: ShoppingCartItem[] = response.data;
          dispatch(setCartItems(data));
        }
      });
  };

  const getSupplierByOrganizationId = () => {
    if (!organization) {
      return;
    }
    api
      .get(`organizations/${organization.organization_id}/suppliers`)
      .then(({ data }) => {
        dispatch(setSupplier(data));
      });
  };

  return (
    <IonApp className={isSupplierOrganization(organization) ? 'supplier' : ''}>
      <IonReactRouter>
        {isAuthenticated && authUser ? (
          <IonSplitPane contentId="mainContent" when="xl">
            <Menu />
            <div id="mainContent">
              <Header />
              <Routes />
            </div>
          </IonSplitPane>
        ) : (
          <>
            <IonRow style={{ marginTop: '26px' }}>
              <IonCol className="ion-text-center">
                <LogoTrulla />
              </IonCol>
            </IonRow>
            <Routes />
          </>
        )}
      </IonReactRouter>
      {/* Global toast messages */}
      <ToastManager />

      <Tooltip id="global-tooltip">{/* filled dynamically */}</Tooltip>
      {authUser && !isAnalyst(authUser) ? (
        <ModalAddSupplier
          isOpen={addSupplierModalOpen}
          onClose={(data?: any) => setAddSupplierModalOpen(false)}
          onDismiss={(data?: any) => {
            setAddSupplierModalOpen(false);
          }}
        />
      ) : null}
    </IonApp>
  );
};

export default App;
