import { IonRouterLink } from '@ionic/react';
import { Account } from 'interfaces/Account';
import { CatalogItem } from 'interfaces/CatalogItem';
import { PurchaseOrderItem } from 'interfaces/PurchaseOrderItem';
import { ShoppingCartItem } from 'interfaces/ShoppingCartItem';
import { Supplier } from 'interfaces/Supplier';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatNdc } from 'utils';
import ModalItemDetails from 'components/shared/ModalItemDetails';

const ItemDescriptionCol: React.FC<{
  item: CatalogItem | ShoppingCartItem | PurchaseOrderItem;
  account?: Account;
}> = ({ item, account }) => {
  const allSuppliers: Supplier[] | null = useSelector(
    (state: any) => state.app.allSuppliers
  );
  const [itemProperties, setItemProperties] = useState<any[]>([]);

  const supplierName = allSuppliers?.find(
    (supplier) => supplier.supplier_id === account?.supplier_id
  )?.name;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (item) {
      const properties: any[] = [];
      if (item.item_ndc && item.item_ndc !== 'NONE') {
        properties.push(formatNdc(item.item_ndc));
      }
      // if (item.item_dosage_form) {
      //   properties.push(`Form: ${item.item_dosage_form}`);
      // }
      if (account?.supplier_id) {
        properties.push(
          <IonRouterLink
            routerLink={`/suppliers/${account?.supplier_id}`}
            routerDirection="forward"
            style={{ pointerEvents: 'all' }}
          >
            {supplierName}
          </IonRouterLink>
        );
      }
      setItemProperties(properties);
    } else {
      setItemProperties([]);
    }
  }, [item, account]);

  return (
    <>
      <div className="itemCol">
        <IonRouterLink
          onClick={() => {
            setIsOpen(true);
          }}
          className="font-size-default font-weight-500"
          color="secondary"
          style={{ cursor: 'pointer' }}
        >
          {item.item_description}
        </IonRouterLink>
        <p>
          {itemProperties.map((itemProperty: any, index) => {
            return (
              <Fragment key={itemProperty}>
                {itemProperty}
                {index < itemProperties.length - 1 ? ' | ' : null}
              </Fragment>
            );
          })}
        </p>
      </div>

      <ModalItemDetails
        isOpen={isOpen}
        account={account}
        item={item}
        onDismiss={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
};

export default ItemDescriptionCol;
