import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  useIonAlert,
} from '@ionic/react';

import { useMsal } from '@azure/msal-react';
import {
  faBooks,
  faBoxesStacked,
  faFileInvoiceDollar,
  faLifeRing,
  faScrewdriverWrench,
  faTags,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { faHouse } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LogoTrulla from 'components/shared/LogoTrulla';
import 'components/shared/Menu.css';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { Location } from 'interfaces/Location';
import { Organization } from 'interfaces/Organization';
import { cartOutline, logOutOutline, settingsOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { logout } from 'redux/appReducer';
import {
  isAdmin,
  isBuyerOrganization,
  isSuperAdmin,
  isSupplierOrganization,
} from 'utils';
import Divider from 'components/shared/Divider';

interface AppPage {
  title: string;
  url?: string | null;
  icon: any;
  open?: boolean | null;
  routerDirection?: 'root' | 'forward' | 'back' | 'none';
  isVisible: boolean;
  children?: Array<{
    title: string;
    url: string;
    routerDirection: 'root' | 'forward' | 'back' | 'none';
    isVisible: boolean;
  }>;
}

const Menu: React.FC = () => {
  const { instance } = useMsal();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.app.user
  );
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const [appPages, setAppPages] = useState<AppPage[]>([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const [presentAlert] = useIonAlert();

  useEffect(() => {
    if (organization) {
      if (isBuyerOrganization(organization)) {
        setAppPages(buyerPages);
      } else if (isSupplierOrganization(organization)) {
        setAppPages(supplierPages);
      }
    }
  }, [authUser, organization, selectedLocation]);

  const hasRole = (
    roleName: 'analyst' | 'buyer' | 'admin' | 'superadmin'
  ): boolean => {
    if (authUser && authUser.app_role_name === roleName) {
      return true;
    }
    return false;
  };

  const buyerPages: Array<AppPage> = [
    {
      title: 'Home',
      url: '/',
      icon: faHouse,
      routerDirection: 'root',
      isVisible: true,
    },
    {
      title: 'Suppliers',
      url: '/suppliers',
      icon: faBoxesStacked,
      routerDirection: 'root',
      isVisible: !!selectedLocation,
    },
    {
      title: 'Order',
      icon: cartOutline,
      isVisible: !!selectedLocation,
      children: [
        {
          title: 'Shopping Cart',
          url: '/orders/cart',
          routerDirection: 'root',
          isVisible: hasRole('buyer') || hasRole('admin'),
        },
        {
          title: 'Order History',
          url: '/orders',
          routerDirection: 'root',
          isVisible: true,
        },
      ],
    },
    // {
    //   title: 'Analytics',
    //   url: '/analytics',
    //   icon: pieChartOutline,
    //   routerDirection: 'root',
    //   isVisible: !!selectedLocation,
    // },
    {
      title: 'Settings',
      icon: settingsOutline,
      isVisible: hasRole('admin') || hasRole('superadmin'),
      children: [
        {
          title: 'General',
          url: '/settings',
          routerDirection: 'root',
          isVisible: true,
        },
        {
          title: 'Locations',
          url: '/settings/locations',
          routerDirection: 'root',
          isVisible: true,
        },
        {
          title: 'Users',
          url: '/settings/users',
          routerDirection: 'root',
          isVisible: true,
        },
        {
          title: 'Suppliers',
          url: '/settings/suppliers',
          routerDirection: 'root',
          isVisible: true,
        },
      ],
    },
    {
      title: 'Admin',
      icon: faScrewdriverWrench,
      isVisible: hasRole('superadmin'),
      children: [
        {
          title: 'Registration Requests',
          url: '/admin/requests',
          routerDirection: 'root',
          isVisible: true,
        },
      ],
    },
  ];

  const supplierPages: Array<AppPage> = [
    {
      title: 'Home',
      url: '/',
      icon: faHouse,
      routerDirection: 'root',
      isVisible: true,
    },
    {
      title: 'Sales',
      url: '/supplier/sales',
      icon: faFileInvoiceDollar,
      routerDirection: 'root',
      isVisible: true,
    },
    {
      title: 'Accounts',
      icon: faUsers,
      isVisible: !!authUser && (isAdmin(authUser) || isSuperAdmin(authUser)),
      children: [
        {
          title: 'Overview',
          url: '/supplier/accounts',
          routerDirection: 'root',
          isVisible: true,
        },
        // {
        //   title: 'Requests',
        //   url: '/supplier/accounts/requests',
        //   routerDirection: 'root',
        //   isVisible: true,
        // },
      ],
    },
    {
      title: 'Catalogs',
      url: '/supplier/catalogs',
      icon: faBooks,
      routerDirection: 'root',
      isVisible: true,
    },
    // {
    //   title: 'Returns',
    //   url: '/supplier/returns',
    //   icon: faArrowTurnDownLeft,
    //   routerDirection: 'root',
    //   isVisible: true,
    // },
    {
      title: 'Deals',
      url: '/supplier/deals',
      icon: faTags,
      routerDirection: 'root',
      isVisible: true,
    },
    {
      title: 'Settings',
      icon: settingsOutline,
      isVisible: hasRole('admin'),
      children: [
        {
          title: 'General',
          url: '/supplier/settings',
          routerDirection: 'root',
          isVisible: hasRole('admin'),
        },
        {
          title: 'Users',
          url: '/supplier/settings/users',
          routerDirection: 'root',
          isVisible: hasRole('admin'),
        },
      ],
    },
  ];

  const menuItemActive = ({ url, rootPath }: any): boolean => {
    // TODO: improve this function to highlight the base path (like "/settings/locations") when a child route is active (like "/settings/locations/{location_id}")
    return url === location.pathname;
  };

  const onClickLogout = () => {
    presentAlert({
      header: 'Logout',
      message: 'Are you sure you want to Logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Logout',
          role: 'confirm',
        },
      ],
      onDidDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          dispatch(logout());
          instance.logoutRedirect();
        }
      },
    });
  };

  return (
    <IonMenu contentId="mainContent" type="overlay" swipeGesture={false}>
      <IonContent>
        <IonList>
          <IonListHeader class="logoHeader">
            <LogoTrulla style={{ height: '40px' }} />
          </IonListHeader>
          {appPages.map((page) => {
            if (page.isVisible) {
              return (
                <div key={page.title}>
                  {page.url && (
                    <IonMenuToggle autoHide={false}>
                      <IonItem
                        className={menuItemActive(page) ? 'selected' : ''}
                        routerLink={page.url ? page.url : undefined}
                        routerDirection={page.routerDirection}
                        lines="none"
                        detail={false}
                      >
                        {(typeof page.icon === 'string' && (
                          <IonIcon slot="start" icon={page.icon} />
                        )) || (
                          <span slot="start">
                            <FontAwesomeIcon icon={page.icon} />
                          </span>
                        )}
                        <IonLabel>{page.title}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  )}
                  {page.children && page.children.length > 0 && (
                    <IonAccordionGroup>
                      <IonAccordion>
                        <IonItem slot="header">
                          {(typeof page.icon === 'string' && (
                            <IonIcon slot="start" icon={page.icon} />
                          )) || (
                            <span slot="start">
                              <FontAwesomeIcon icon={page.icon} />
                            </span>
                          )}
                          <IonLabel>{page.title}</IonLabel>
                        </IonItem>
                        <div slot="content">
                          {page.children.map((child) => {
                            if (child.isVisible) {
                              return (
                                <IonMenuToggle
                                  key={child.title}
                                  autoHide={false}
                                >
                                  <IonItem
                                    className={
                                      menuItemActive(child) ? 'selected' : ''
                                    }
                                    routerLink={child.url}
                                    routerDirection={child.routerDirection}
                                    lines="none"
                                    detail={false}
                                  >
                                    <IonLabel>{child.title}</IonLabel>
                                  </IonItem>
                                </IonMenuToggle>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </IonAccordion>
                    </IonAccordionGroup>
                  )}
                </div>
              );
            }
          })}
        </IonList>
      </IonContent>
      <IonFooter>
        <IonList lines="none">
          <IonMenuToggle autoHide={false}>
            <IonItem id="inline-manual-help" button detail={false}>
              <span slot="start">
                <FontAwesomeIcon icon={faLifeRing} />
              </span>
              <IonLabel>Help</IonLabel>
            </IonItem>
            <Divider className="ion-no-margin" />
            <IonItem button detail={false} onClick={onClickLogout}>
              <IonIcon slot="start" icon={logOutOutline}></IonIcon>
              Logout
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonFooter>
    </IonMenu>
  );
};

export default Menu;
