import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSkeletonText,
  IonText,
} from '@ionic/react';
import { useApi } from 'hooks/useApi';
import 'components/buyer/CardUser.css';
import UserAvatar from 'components/shared/UserAvatar';
import { AppRole } from 'interfaces/AppRole';
import { AppUser } from 'interfaces/AppUser';
import { useEffect, useState } from 'react';
import { isSuperAdmin } from 'utils';
import PopoverUserMeta from 'components/buyer/PopoverUserMeta';

const CardUser: React.FC<{
  user?: AppUser;
  editing?: boolean;
  onChange: (userData: any) => void;
}> = ({ user, editing, onChange }) => {
  const api = useApi();
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [roleId, setRoleId] = useState<number>();
  const [appRoles, setAppRoles] = useState<AppRole[]>([]);

  useEffect(() => {
    getAppRoles();
  }, []);

  useEffect(() => {
    populateData();
  }, [user]);

  useEffect(() => {
    const userInfo = {
      first_name: firstName,
      last_name: lastName,
      email_address: email,
      app_role_id: roleId,
    };
    onChange(userInfo);
  }, [firstName, lastName, email, roleId]);

  const getAppRoles = () => {
    setLoading(true);
    api
      .get('roles')
      .then(({ data }) => {
        setAppRoles(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const populateData = () => {
    if (user) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email_address);
      setRoleId(user.app_role_id);
    }
  };
  return (
    <IonCard className="userCard">
      <IonCardHeader>
        <IonCardTitle>User Info</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className="ion-no-padding">
        {user && (
          <IonRow>
            <IonCol className="ion-text-center">
              {(loading && (
                <IonSkeletonText animated={true} className="avatar" />
              )) || (
                <>
                  <UserAvatar user={user} />
                  {user && <PopoverUserMeta user={user} />}
                </>
              )}
              {user?.active === false && (
                <IonText color="danger">
                  <h2 className="ion-text-center font-weight-500 font-style-italic font-size-xl">
                    Deactivated
                  </h2>
                </IonText>
              )}
            </IonCol>
          </IonRow>
        )}
        <IonRow className="ion-padding-bottom">
          <IonCol size="12">
            <IonList>
              <IonItem>
                <IonLabel position="floating">First Name</IonLabel>
                <IonInput
                  type="text"
                  value={firstName}
                  readonly={!editing}
                  disabled={loading}
                  onIonChange={(e) => setFirstName(e.detail.value as string)}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Last Name</IonLabel>
                <IonInput
                  type="text"
                  value={lastName}
                  readonly={!editing}
                  disabled={loading}
                  onIonChange={(e) => setLastName(e.detail.value as string)}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Email</IonLabel>
                <IonInput
                  type="email"
                  value={email}
                  readonly={!editing || !!user}
                  disabled={loading}
                  onIonChange={(e) => setEmail(e.detail.value as string)}
                />
              </IonItem>
              {/* If the user is a super, don't display the dropdown */}
              {(user && !isSuperAdmin(user)) || !user ? (
                <IonItem>
                  <IonLabel position="stacked">Select Role</IonLabel>
                  <IonSelect
                    value={roleId}
                    onIonChange={(e) => setRoleId(e.detail.value as number)}
                    interface="popover"
                    interfaceOptions={{
                      alignment: 'end',
                      showBackdrop: true,
                      dismissOnSelect: true,
                    }}
                    disabled={!editing || loading}
                  >
                    {appRoles.map((appRole: AppRole) => {
                      return (
                        <IonSelectOption
                          key={appRole.app_role_id}
                          value={appRole.app_role_id}
                        >
                          {appRole.description}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>
              ) : null}
            </IonList>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
};

export default CardUser;
