import { IonAvatar } from '@ionic/react';
import 'components/shared/UserAvatar.css';
import { AppUser } from 'interfaces/AppUser';
import { getUserInitials } from 'utils';

const UserAvatar: React.FC<{
  user: AppUser;
}> = ({ user }) => {
  return (
    <IonAvatar className="userAvatar">
      <span>{getUserInitials(user.first_name, user.last_name)}</span>
    </IonAvatar>
  );
};

export default UserAvatar;
