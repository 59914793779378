import { IonButton, IonIcon, IonProgressBar } from '@ionic/react';
import {
  DataTableHeader,
  DataTableItemAction,
  DataTableRow,
} from 'components/shared/DataTable';
import DataTableActions from 'components/shared/DataTableActions';
import LoadingInline from 'components/shared/LoadingInline';
import { arrowDownOutline, arrowUpOutline } from 'ionicons/icons';

const DataTableDesktop: React.FC<{
  headers: DataTableHeader[];
  rows: DataTableRow[];
  onClickRow?: any;
  actions?: (item: any) => DataTableItemAction[];
  loading?: boolean;
  orderBy?: string | null;
  orderByDesc?: boolean;
  setOrderBy?: (value: string | null) => void;
  setOrderByDesc?: (value: boolean) => void;
}> = ({
  headers,
  rows,
  onClickRow,
  actions,
  loading,
  orderBy,
  orderByDesc,
  setOrderBy,
  setOrderByDesc,
}) => {
  const getColumnAlignment = (value: string): 'left' | 'center' | 'right' => {
    const header = headers.find((header) => {
      return header.key === value;
    });
    if (header) {
      return header.align || 'left';
    }
    return 'left';
  };

  const getHeaderStyle = (header: DataTableHeader) => {
    return {
      width: header.width ? header.width : '',
    };
  };

  const getClassName = (header: DataTableHeader): string => {
    let className = '';
    if (header.align) {
      className += `${header.align} `;
    } else {
      className += 'left ';
    }
    if (header.sortable) {
      className += 'sortable ';
      if (orderBy === header.key) {
        className += 'sorted ';
        if (orderByDesc === true) {
          className += 'sort-desc ';
        }
      }
    }
    return className;
  };

  const onClickHeader = (header: DataTableHeader) => {
    if (!setOrderBy || !setOrderByDesc) {
      return;
    }
    if (orderBy === header.key && orderByDesc === true) {
      setOrderBy(null);
      setOrderByDesc(false);
    } else if (orderBy === header.key) {
      setOrderByDesc(true);
    } else {
      setOrderBy(header.key);
      setOrderByDesc(false);
    }
  };

  return (
    <table className={loading ? 'loading' : ''}>
      <thead>
        <tr>
          {headers.map((header) => {
            return (
              <th
                style={getHeaderStyle(header)}
                className={getClassName(header)}
                key={header.key}
                onClick={
                  header.sortable ? () => onClickHeader(header) : undefined
                }
              >
                <div className="headerContainer">
                  <span>{header.text}</span>
                  {header.sortable ? <IonIcon icon={arrowUpOutline} /> : null}
                </div>
              </th>
            );
          })}
          {actions && (
            <th className="ion-text-center" style={{ width: '80px' }}>
              Actions
            </th>
          )}
        </tr>
        {loading ? (
          <tr>
            <th colSpan={100} style={{ padding: '0', backgroundColor: '#fff' }}>
              <IonProgressBar
                type="indeterminate"
                style={{ height: 'var(--app-spacing-xs)' }}
              />
            </th>
          </tr>
        ) : null}
      </thead>
      <tbody>
        {!loading && rows.length < 1 && (
          <tr>
            <td colSpan={100}>
              <h2 className="ion-text-center ion-margin-vertical font-style-italic font-size-large">
                No data to display...
              </h2>
            </td>
          </tr>
        )}
        {rows.length > 0
          ? rows.map((row: DataTableRow, rowIndex: number) => {
              return (
                <tr
                  key={row.key || rowIndex}
                  className={onClickRow ? 'clickable' : ''}
                  onClick={() => (onClickRow ? onClickRow(row) : null)}
                >
                  {headers.map((header: DataTableHeader) => {
                    const column = row.columns.find(
                      (column) => column.header === header.key
                    );
                    if (!column) {
                      return <></>;
                    }
                    return (
                      <td
                        key={column.header}
                        className={getColumnAlignment(column.header)}
                      >
                        {column.content}
                      </td>
                    );
                  })}

                  {actions && (
                    <td className="ion-text-center">
                      <DataTableActions actions={actions} item={row.item} />
                    </td>
                  )}
                </tr>
              );
            })
          : null}
      </tbody>
    </table>
  );
};

export default DataTableDesktop;
