import {
  IonButton,
  IonCol,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
} from '@ionic/react';
import ChipAccount from 'components/buyer/ChipAccount';
import Divider from 'components/shared/Divider';
import { Account } from 'interfaces/Account';
import { addOutline } from 'ionicons/icons';

export const FormManageAccountsList: React.FC<{
  existingAccounts: Account[];
  onClickAccount: (account: Account) => void;
  submitTrigger: number;
  onClickAddMore: () => void;
}> = ({ existingAccounts, onClickAccount, submitTrigger, onClickAddMore }) => {
  return (
    <>
      <IonRow>
        <IonCol>
          <Divider className="ion-no-margin" />
          <IonList lines="full">
            {existingAccounts.map((account) => {
              return (
                <IonItem
                  key={account.account_id}
                  detail={true}
                  button
                  onClick={() => onClickAccount(account)}
                >
                  <ChipAccount
                    account={account}
                    style={{
                      width: '200px',
                      marginTop: 'var(--app-spacing-small)',
                      marginBottom: 'var(--app-spacing-small)',
                    }}
                  />
                </IonItem>
              );
            })}
          </IonList>
        </IonCol>
      </IonRow>
      <IonRow className="ion-margin-top">
        <IonCol className="ion-padding ion-text-center">
          <IonButton fill="outline" shape="round" onClick={onClickAddMore}>
            Add More
            <IonIcon slot="end" icon={addOutline} />
          </IonButton>
        </IonCol>
      </IonRow>
    </>
  );
};
