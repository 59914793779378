import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { Organization } from 'interfaces/Organization';
import { PageLoading } from 'pages/shared/PageLoading';
import { PageNotFound } from 'pages/shared/PageNotFound';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { isSupplierOrganization } from 'utils';

const RouteOrgSupplier = ({ component: Component, ...rest }: any) => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, accounts } = useMsal();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.app.user
  );
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          isAuthenticated &&
          authUser &&
          isSupplierOrganization(organization)
        ) {
          return <Component {...props} />;
        } else if (authUser && !isSupplierOrganization(organization)) {
          return <PageNotFound />;
        } else if (
          !isAuthenticated &&
          inProgress !== InteractionStatus.Startup &&
          inProgress !== InteractionStatus.HandleRedirect &&
          accounts.length === 0
        ) {
          return <Redirect to="/login" />;
        }
        return <PageLoading />;
      }}
    />
  );
};

export default RouteOrgSupplier;
