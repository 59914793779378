import {
  IonBadge,
  IonCol,
  IonIcon,
  IonRouterLink,
  IonRow,
  IonSearchbar,
  useIonAlert,
  useIonViewWillEnter,
} from '@ionic/react';
import DataTable, {
  DataTableHeader,
  DataTableItemAction,
  DataTablePagination,
  DataTableRow,
} from 'components/shared/DataTable';
import Subtitle from 'components/shared/Subtitle';
import { useApi } from 'hooks/useApi';
import { Location } from 'interfaces/Location';
import { Organization } from 'interfaces/Organization';
import { closeOutline, pencilOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from 'redux/appReducer';

const TableLocations: React.FC = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const [presentAlert] = useIonAlert();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [search, setSearch] = useState<string | null>(null);
  const [pagination, setPagination] = useState<DataTablePagination>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<string | null>('name');
  const [orderByDesc, setOrderByDesc] = useState<boolean>(false);
  const headers: DataTableHeader[] = [
    { text: 'Location Name', key: 'name', sortable: true },
    { text: 'Facility ID', key: 'facility_id', sortable: true },
    { text: 'Address', key: 'address', sortable: true },
    { text: 'Billing Address', key: 'billing_address', sortable: true },
    { text: '340B Type', key: 'type340B', align: 'center' },
  ];

  useIonViewWillEnter(() => {
    getLocations();
  }, []);

  useEffect(() => {
    getLocations();
  }, [organization, pagination, search]);

  const getLocations = () => {
    if (!organization || !organization.organization_id) {
      return;
    }
    setLoading(true);
    api
      .get(`organizations/${organization.organization_id}/locations`, {
        limit: pagination?.limit,
        offset: pagination?.offset,
        order_by: orderBy ? orderBy : undefined,
        order_by_desc: orderByDesc,
        search: search ? search : undefined,
      })
      .then((response) => {
        if (response.status === 200) {
          setLocations(response.data || []);
          setTotalCount(response.data.length);
        }
        setLoading(false);
      });
  };

  const actions = (location: Location): DataTableItemAction[] => {
    return [
      {
        label: 'Manage Location',
        icon: <IonIcon icon={pencilOutline} />,
        routerLink: (location: Location) => {
          return `/settings/locations/${location.location_id}`;
        },
        callback: (location: Location) => {
          return null;
        },
      },
      {
        label: 'Delete Location',
        icon: <IonIcon icon={closeOutline} />,
        color: 'danger',
        callback: (location: Location) => {
          onClickDelete(location);
        },
      },
    ];
  };

  const onClickDelete = (location: Location) => {
    presentAlert({
      header: 'Delete the following Location?',
      message: `${location.name}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Delete',
          role: 'confirm',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          deleteLocation(location);
        }
      },
    });
  };

  const deleteLocation = (location: Location) => {
    setLoading(true);
    api
      .delete(`locations/${location.location_id}`)
      .then((response) => {
        if (response.status === 204) {
          dispatch(
            setToast({
              message: 'Successfully deleted',
            })
          );
          getLocations();
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      <DataTable
        title="Locations"
        headers={headers}
        actions={actions}
        loading={loading}
        search={false}
        onPaginationChange={(pagination: DataTablePagination) =>
          setPagination(pagination)
        }
        orderBy={orderBy}
        orderByDesc={orderByDesc}
        setOrderBy={setOrderBy}
        setOrderByDesc={setOrderByDesc}
        totalCount={totalCount}
        rows={locations.map((location: Location): DataTableRow => {
          const row: DataTableRow = {
            item: location,
            key: location.location_id,
            columns: [
              {
                header: 'name',
                content: (
                  <>
                    <IonRouterLink
                      className="font-size-large"
                      routerLink={`/settings/locations/${location.location_id}`}
                    >
                      {location.name}
                    </IonRouterLink>
                    <Subtitle>{location.location_type_name}</Subtitle>
                  </>
                ),
              },
              {
                header: 'facility_id',
                content: <p>{location.facility_id}</p>,
              },
              {
                header: 'address',
                content: (
                  <>
                    <p>
                      {location.address1}
                      {location.address2 && (
                        <span>,&nbsp;{location.address2}</span>
                      )}
                    </p>
                    <p>
                      {location.city} {location.state}, {location.postal_code}
                    </p>
                  </>
                ),
              },
              {
                header: 'billing_address',
                content: (
                  <>
                    {!location.billing_address1 ? (
                      <p>&mdash;</p>
                    ) : (
                      <>
                        {(location.billing_address1 ||
                          location.billing_address2) && (
                          <p>
                            {location.billing_address1}
                            {location.billing_address2 && (
                              <span>,&nbsp;{location.billing_address2}</span>
                            )}
                          </p>
                        )}
                        {(location.billing_city ||
                          location.billing_state ||
                          location.billing_postal_code) && (
                          <p>
                            {location.billing_city} {location.billing_state},{' '}
                            {location.billing_postal_code}
                          </p>
                        )}
                      </>
                    )}
                  </>
                ),
              },
              {
                header: 'type340B',
                content: (
                  <>
                    {location.location_340b_type_name && (
                      <IonBadge title={`${location.location_340b_type_name}`}>
                        {location.location_340b_type_abbreviation}
                      </IonBadge>
                    )}
                    {!location.location_340b_type_name && (
                      <IonBadge color="light">N/A</IonBadge>
                    )}
                  </>
                ),
              },
            ],
          };
          return row;
        })}
      >
        <IonRow>
          <IonCol size="12" sizeSm="8" offsetSm="4">
            <IonSearchbar
              value={search}
              onIonChange={(e: any) => setSearch(e.detail.value as string)}
              debounce={350}
              className="ion-text-left"
            />
          </IonCol>
        </IonRow>
      </DataTable>
    </>
  );
};

export default TableLocations;
