import { IonCard, IonCol, IonRow } from '@ionic/react';
import ChipAccount from 'components/buyer/ChipAccount';
import { Account } from 'interfaces/Account';
import { ShoppingCart } from 'interfaces/ShoppingCart';
import { ShoppingCartItem } from 'interfaces/ShoppingCartItem';
import InputPoNumber from 'components/buyer/InputPoNumber';
import TableCartItems from 'components/buyer/TableCartItems';

const CardShoppingCart: React.FC<{
  shoppingCart: ShoppingCart;
  account?: Account;
  refresh: boolean;
  setRefresh: (value: boolean) => void;
  onClickDeleteItem: (item: ShoppingCartItem) => void;
}> = ({ shoppingCart, account, refresh, setRefresh, onClickDeleteItem }) => {
  return (
    <IonCard className="ion-margin-horizontal">
      <IonRow className="ion-padding ion-justify-content-start ion-align-items-start">
        <IonCol size="12" sizeMd="4" sizeLg="3">
          <div style={{ maxWidth: '175px' }}>
            <p className="ion-no-margin font-size-default margin-right-sm margin-left-xs margin-top-sm">
              Account:
            </p>
            {account && (
              <ChipAccount
                account={account}
                style={{ marginBottom: '0', marginLeft: '0' }}
              />
            )}
          </div>
        </IonCol>
        <IonCol size="12" sizeMd="5" sizeLg="4" sizeXl="3">
          <div className="fullWidth" style={{ maxWidth: '350px' }}>
            <InputPoNumber shoppingCart={shoppingCart} />
          </div>
        </IonCol>
      </IonRow>
      {account && (
        <TableCartItems
          onClickDelete={onClickDeleteItem}
          shoppingCart={shoppingCart}
          account={account}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
    </IonCard>
  );
};

export default CardShoppingCart;
