import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonRow,
} from '@ionic/react';
import LogoSupplier from 'components/shared/LogoSupplier';
import { SelectAccountType } from 'components/buyer/SelectAccountType';
import { useApi } from 'hooks/useApi';
import { useUtils } from 'hooks/useUtils';
import { Account } from 'interfaces/Account';
import { AccountType } from 'interfaces/AccountType';
import { Supplier } from 'interfaces/Supplier';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardActions from 'components/shared/CardActions';
import { saveOutline } from 'ionicons/icons';
import { setToast } from 'redux/appReducer';

export const FormManageAccountsEdit: React.FC<{
  account: Account;
  supplier: Supplier;
  onAccountUpdated: (account: Account) => void;
}> = ({ account, supplier, onAccountUpdated }) => {
  const api = useApi();
  const utils = useUtils();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState<string | null>(null);
  const [accountTypeId, setAccountTypeId] = useState<number | null>(null);
  const changed =
    description !== account.description ||
    accountTypeId !== account.account_type_id;

  useEffect(() => {
    setDescription(account.description);
    setAccountTypeId(account.account_type_id);
  }, []);

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (loading || !description || !accountTypeId) {
        return false;
      }
      save();
    }
  };

  const save = () => {
    setLoading(true);
    const formData = {
      buyers_description: description,
      buyers_account_type_id: accountTypeId,
    };
    api
      .put(`/accounts/${account.account_id}`, formData)
      .then((response) => {
        if (response.status === 200) {
          const data: Account[] = response.data;
          onAccountUpdated(data[0]);
          dispatch(setToast({ message: 'Account updated' }));
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <IonGrid className="ion-no-padding">
      <IonRow>
        <IonCol
          size="12"
          className="d-flex ion-align-items-center ion-justify-content-center padding-xs"
        >
          <LogoSupplier src={supplier.logo} />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-no-padding">
          <IonItemDivider>
            Account: {utils.formatAccount(account)}
          </IonItemDivider>
        </IonCol>
      </IonRow>
      <IonRow className="ion-padding-horizontal ion-margin-top">
        <IonCol size="12">
          <IonList lines="none" className="">
            <IonItem>
              <IonLabel position="stacked" className="margin-left-xs">
                Description
              </IonLabel>
              <IonInput
                type="text"
                placeholder="Description"
                value={description}
                onIonChange={(e) => setDescription(e.detail.value as string)}
                onKeyDown={handleKeyDown}
                className="rounded"
                disabled={loading}
              />
            </IonItem>
          </IonList>
        </IonCol>
      </IonRow>
      <IonRow className="ion-padding-horizontal">
        <IonCol size="12" sizeMd="6">
          <IonList lines="none">
            <SelectAccountType
              value={accountTypeId}
              setValue={setAccountTypeId}
              disabled={loading}
            />
          </IonList>
        </IonCol>
      </IonRow>
      <CardActions>
        <IonButton size="small" disabled={!changed} onClick={save}>
          Save
          <IonIcon icon={saveOutline} slot="end" />
        </IonButton>
      </CardActions>
    </IonGrid>
  );
};
