import {
  IonCard,
  IonCardContent,
  IonCol,
  IonRow,
  IonSkeletonText,
  useIonViewWillEnter,
} from '@ionic/react';
import TableSupplierLocations from 'components/buyer/TableSupplierLocations';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import LogoSupplier from 'components/shared/LogoSupplier';
import { Page } from 'components/shared/Page';
import { useApi } from 'hooks/useApi';
import { Supplier } from 'interfaces/Supplier';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { CardStatOrderedItems } from 'components/buyer/CardStatOrderedItems';
import { CardStatSpend } from 'components/buyer/CardStatSpend';
import PopoverSupplierMeta from 'components/buyer/PopoverSupplierMeta';
import { SelectTimeFrame, TimeFrame } from 'components/shared/SelectTimeFrame';
import { useDispatch } from 'react-redux';
import { setToast } from 'redux/appReducer';

import { CardStatOrders } from 'components/buyer/CardStatOrders';
import 'pages/buyer/PageSettingSupplier.css';

export const PageSettingSupplier: React.FC = () => {
  const api = useApi();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [supplier, setSupplier] = useState<Supplier>();

  const [timeFrameLabel, setTimeFrameLabel] = useState<string>();
  const [filterMinDate, setFilterMinDate] = useState<string | null>(null);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Settings', path: '/settings', direction: 'back' },
    { label: 'Suppliers', path: '/settings/suppliers', direction: 'back' },
    { label: supplier && supplier.name ? supplier.name : id },
  ];

  useIonViewWillEnter(() => {
    getSupplier();
  }, []);

  useEffect(() => {
    getSupplier();
  }, [id]);

  const getSupplier = () => {
    setLoading(true);
    api
      .get(`suppliers/${id}`)
      .then(({ data }) => {
        setSupplier(data);
        setLoading(false);
      })
      .catch((error) => {
        dispatch(
          setToast({
            header: 'Error',
            message: 'Unable to get this Supplier',
            color: 'danger',
          })
        );
        setLoading(false);
      });
  };

  return (
    <Page title="Supplier Settings" className="pageSettingSupplier">
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>

      <IonRow>
        <IonCol size="12" sizeMd="6" sizeLg="3">
          <IonCard className="supplierCard d-flex ion-align-items-center ion-justify-content-center">
            <IonCardContent>
              <IonRow>
                <IonCol size="12" className="ion-text-center">
                  {loading && (
                    <IonSkeletonText animated={true} className="supplierLogo" />
                  )}
                  {!loading && supplier && <LogoSupplier src={supplier.logo} />}
                </IonCol>
                <IonCol className="ion-text-center">
                  <PopoverSupplierMeta supplier={supplier} />
                </IonCol>
              </IonRow>
            </IonCardContent>
          </IonCard>
        </IonCol>

        <IonCol size="12" sizeMd="6" sizeLg="9" className="ion-no-padding">
          <IonRow>
            <IonCol size="12" className="ion-no-padding">
              <IonRow>
                <IonCol size="12" sizeLg="8" className="ion-padding-horizontal">
                  <SelectTimeFrame
                    onUpdate={(timeFrame: TimeFrame) => {
                      setFilterMinDate(timeFrame.value);
                      setTimeFrameLabel(timeFrame.label);
                    }}
                  />
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="4">
              <CardStatSpend
                minDate={filterMinDate}
                label={timeFrameLabel}
                supplier={supplier}
              />
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="4">
              <CardStatOrders
                minDate={filterMinDate}
                label={timeFrameLabel}
                supplier={supplier}
                routerLink={`/orders?supplier_id=${id}`}
                routerDirection="root"
              />
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="4">
              <CardStatOrderedItems
                minDate={filterMinDate}
                label={timeFrameLabel}
                supplier={supplier}
              />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonCard>
            {supplier && (
              <TableSupplierLocations supplier={supplier} hideLogo={true} />
            )}
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
