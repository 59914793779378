import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonicSlides,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonModal,
  IonPopover,
  IonRouterLink,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { chevronBackOutline, close } from 'ionicons/icons';
import { Fragment, useEffect, useState } from 'react';
import { Swiper as SwiperInterface } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import '@ionic/react/css/ionic-swiper.css';
import FormManageAccounts from 'components/buyer/FormManageAccounts';
import 'components/buyer/ModalSupplierAdd.css';
import { ModalSupplierAddSlide1 } from 'components/buyer/ModalSupplierAddSlide1';
import LoadingInline from 'components/shared/LoadingInline';
import { useApi } from 'hooks/useApi';
import { Account } from 'interfaces/Account';
import { Contact } from 'interfaces/Contact';
import { Location } from 'interfaces/Location';
import { Supplier } from 'interfaces/Supplier';
import { useSelector } from 'react-redux';
import 'swiper/css';
import { SupplierTypeName } from 'types/SupplierTypeName';
import { formatPhone } from 'utils';

const ModalSupplierAdd: React.FC<{
  isOpen: boolean;
  onClose: any;
  supplier?: Supplier | null;
  onDismiss: (data?: any) => void;
}> = ({ isOpen, onClose, supplier, onDismiss }) => {
  const supplierPopoverButtonId = Math.floor(
    Math.random() * 10000000000
  ).toString(16);
  const api = useApi();
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const allSuppliers: Supplier[] | null = useSelector(
    (state: any) => state.app.allSuppliers
  );
  const accounts: Account[] | null = useSelector(
    (state: any) => state.app.accounts
  );

  const [filterType, setFilterType] = useState<SupplierTypeName>('All');
  const [recommendedSuppliers, setRecommendedSuppliers] = useState<Supplier[]>(
    []
  );

  const [loading, setLoading] = useState(false);
  const [newAccountIds, setNewAccountIds] = useState<number[]>([]);
  const [submitTrigger, setSubmitTrigger] = useState<number>(0);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();

  const [query, setQuery] = useState('');
  const [standardSuppliers, setStandardSuppliers] = useState<Supplier[]>([]);
  const [featuredSuppliers, setFeaturedSuppliers] = useState<Supplier[]>([]);
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(
    null
  );

  const [contacts, setContacts] = useState<Contact[]>([]);
  const [contactsLoading, setContactsLoading] = useState(false);

  const [searched, setSearched] = useState(false);

  useEffect(() => {
    if (allSuppliers && accounts) {
      setRecommendedSuppliers(
        allSuppliers.filter(
          (supplier) =>
            accounts.find(
              (account) => account.supplier_id === supplier.supplier_id
            ) === undefined
        )
      );
      slideTo(0);
    }
  }, [allSuppliers, accounts, selectedLocation]);

  useEffect(() => {
    filterSuppliers();
  }, [recommendedSuppliers, query, filterType]);

  useEffect(() => {
    if (activeIndex == 0) {
      resetModal();
    }
  }, [activeIndex]);

  useEffect(() => {
    if (selectedSupplier) {
      slideNext();
      getContacts();
    }
  }, [selectedSupplier]);

  const getContacts = () => {
    if (!selectedSupplier) return;
    setContactsLoading(true);
    api
      .get(`suppliers/${selectedSupplier.supplier_id}/contacts`)
      .then(({ data }) => {
        setContacts(data);
        setContactsLoading(false);
      });
  };

  const resetModal = () => {
    if (activeIndex === 0) {
      setFilterType('All');
      setSelectedSupplier(null);
      setNewAccountIds([]);
      setSearched(false);
      setSubmitTrigger(0);
    }
  };

  const filterSuppliers = () => {
    const standard = recommendedSuppliers.filter((supplier) => {
      if (supplier.featured) return false;
      if (query && !supplier.name.toLowerCase().includes(query.toLowerCase()))
        return false;
      if (filterType !== 'All' && supplier.supplier_type_name !== filterType)
        return false;
      return true;
    });
    setStandardSuppliers(standard);

    const featured = recommendedSuppliers.filter((supplier) => {
      if (!supplier.featured) return false;
      if (query && !supplier.name.toLowerCase().includes(query.toLowerCase()))
        return false;
      if (filterType !== 'All' && supplier.supplier_type_name !== filterType)
        return false;
      return true;
    });
    setFeaturedSuppliers(featured);
  };

  const slideTo = (index: number) => {
    if (swiperInstance) {
      swiperInstance?.slideTo(index);
    }
  };
  const slideNext = () => {
    swiperInstance?.slideNext();
  };
  const slidePrev = () => {
    swiperInstance?.slidePrev();
  };

  const onActiveIndexChange = (e?: any) => {
    const index = swiperInstance?.activeIndex;
    setActiveIndex(index || 0);
  };

  return (
    <IonModal
      id="modalSupplierAdd"
      className="accountsModal"
      isOpen={isOpen}
      onIonModalWillDismiss={() => {
        slidePrev();
        onDismiss();
      }}
      onDidPresent={() => {
        if (supplier) {
          setSelectedSupplier(supplier);
        }
      }}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {activeIndex === 1 && (
              <IonButton onClick={slidePrev} fill="clear">
                <IonIcon icon={chevronBackOutline} slot="start"></IonIcon>
                Back
              </IonButton>
            )}
          </IonButtons>
          <IonTitle>
            {selectedSupplier
              ? `Add ${selectedSupplier.name} Accounts for ${selectedLocation?.name}`
              : 'Add Supplier'}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onClose()} fill="clear">
              <IonIcon icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-no-padding">
        <Swiper
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          modules={[IonicSlides]}
          initialSlide={0}
          speed={400}
          allowTouchMove={false}
          onActiveIndexChange={() => onActiveIndexChange()}
        >
          <SwiperSlide>
            <ModalSupplierAddSlide1
              loading={loading}
              standardSuppliers={standardSuppliers}
              featuredSuppliers={featuredSuppliers}
              recommendedSuppliers={recommendedSuppliers}
              filterType={filterType}
              setFilterType={setFilterType}
              setQuery={setQuery}
              setSelectedSupplier={setSelectedSupplier}
            />
          </SwiperSlide>
          <SwiperSlide>
            {selectedSupplier && selectedLocation ? (
              <FormManageAccounts
                loading={loading}
                setLoading={setLoading}
                supplier={selectedSupplier}
                location={selectedLocation}
                newAccountIds={newAccountIds}
                setNewAccountIds={setNewAccountIds}
                searched={searched}
                setSearched={setSearched}
                submitTrigger={submitTrigger}
              />
            ) : null}
          </SwiperSlide>
        </Swiper>
      </IonContent>
      {activeIndex === 1 && (
        <>
          <p color="primary" className="font-size-small ion-margin-horizontal">
            Don't have a {selectedSupplier?.name} direct account yet?{' '}
            {/* TRIGGER MODAL INFO */}
            <IonRouterLink
              id={supplierPopoverButtonId}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              Contact {selectedSupplier?.name}
            </IonRouterLink>
            &nbsp;to request one.
          </p>
          <IonFooter>
            <IonToolbar>
              <IonButtons slot="end">
                {loading && (
                  <span className="margin-right-sm">
                    <LoadingInline />
                  </span>
                )}

                <IonButton
                  fill="solid"
                  color="primary"
                  className="ion-margin-horizontal"
                  disabled={loading || newAccountIds.length < 1}
                  onClick={() => setSubmitTrigger(submitTrigger + 1)}
                >
                  Add Account{newAccountIds.length > 1 ? 's' : ''}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonFooter>
          <IonPopover
            className="popoverSupplierMeta"
            trigger={supplierPopoverButtonId}
            triggerAction="click"
            alignment="center"
            side="bottom"
          >
            <IonContent>
              {!contactsLoading ? (
                <>
                  {contacts.map((contact: Contact) => (
                    <Fragment key={contact.contact_id}>
                      <IonItemDivider>
                        <IonLabel>
                          {selectedSupplier?.name} {contact.name}
                        </IonLabel>
                      </IonItemDivider>
                      {contact.email_address && (
                        <IonItem>
                          <IonLabel>Email</IonLabel>
                          <p slot="end" className="ion-text-right">
                            <a href={`mailto:${contact.email_address}`}>
                              {contact.email_address}
                            </a>
                          </p>
                        </IonItem>
                      )}
                      {contact.phone1 && (
                        <IonItem>
                          <IonLabel>Phone</IonLabel>
                          <p slot="end" className="ion-text-right">
                            <a href={`tel:${contact.phone1}`}>
                              {formatPhone(contact.phone1)}
                            </a>
                          </p>
                        </IonItem>
                      )}
                      {contact.phone2 && (
                        <IonItem>
                          <IonLabel>Secondary Phone</IonLabel>
                          <p slot="end" className="ion-text-right">
                            <a href={`tel:${contact.phone2}`}>
                              {formatPhone(contact.phone2)}
                            </a>
                          </p>
                        </IonItem>
                      )}
                      {contact.attributes &&
                        contact.attributes.length > 0 &&
                        contact.attributes.map((attribute) => (
                          <IonItem
                            key={attribute.contact_attribute_id}
                            data-tooltip-id="global-tooltip"
                            data-tooltip-content={attribute.description}
                            data-tooltip-place="bottom"
                          >
                            <IonLabel>{attribute.name}</IonLabel>
                            <p slot="end" className="ion-text-right">
                              {attribute.value}
                            </p>
                          </IonItem>
                        ))}
                    </Fragment>
                  ))}
                </>
              ) : (
                <LoadingInline />
              )}
            </IonContent>
          </IonPopover>
        </>
      )}
    </IonModal>
  );
};

export default ModalSupplierAdd;
