import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import Subtitle from 'components/shared/Subtitle';
import TableSupplierAccounts from 'components/supplier/TableSupplierAccounts';
import {
  addOutline,
  closeOutline,
  cloudUploadOutline,
  settingsSharp,
} from 'ionicons/icons';
import { Page } from 'components/shared/Page';
import { useState } from 'react';

export const PageSupplierAccounts: React.FC = () => {
  const [accountSync, setAccountSync] = useState(false);
  const [accountWizard, setAccountWizard] = useState(true);

  const [isOpen, setIsOpen] = useState(false);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Accounts' },
  ];

  const onDismiss = () => {
    setIsOpen(false);
  };

  return (
    <Page title="Supplier Accounts">
      <Breadcrumbs breadcrumbs={breadcrumbs}>
        {!accountSync && (
          <>
            <IonButton
              size="small"
              data-tooltip-id="global-tooltip"
              data-tooltip-content="CSV import"
            >
              Upload Accounts
              <IonIcon slot="end" icon={cloudUploadOutline} />
            </IonButton>
            <IonButton size="small">
              Add Account
              <IonIcon slot="end" icon={addOutline} />
            </IonButton>
          </>
        )}
        <IonButton
          fill="clear"
          size="small"
          color="medium"
          onClick={() => setIsOpen(true)}
          title="Account Settings"
        >
          <IonIcon slot="icon-only" icon={settingsSharp} />
        </IonButton>
      </Breadcrumbs>
      <IonRow>
        <IonCol>
          <IonCard>
            <TableSupplierAccounts />
          </IonCard>
        </IonCol>
      </IonRow>

      <IonModal
        id="modalAccountSettings"
        isOpen={isOpen}
        onDidDismiss={onDismiss}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Account Settings</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={onDismiss} fill="clear">
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonItemDivider>
            <IonLabel>Account Sync</IonLabel>
          </IonItemDivider>
          <IonList lines="full">
            <IonItem className="ion-margin-bottom">
              <IonLabel>Enable Account Sync</IonLabel>
              <IonToggle
                checked={accountSync}
                onIonChange={(e: any) => setAccountSync(e.detail.checked)}
              />

              <div slot="helper">
                <Subtitle>
                  Account numbers will be synced with the vendor.
                </Subtitle>
                <Subtitle className="text-style-italic font-weight-500">
                  Note: Turning this on disables the ability to upload and add
                  accounts within Trulla Direct
                </Subtitle>
              </div>
            </IonItem>
          </IonList>

          <IonItemDivider>
            <IonLabel>New Account Wizard</IonLabel>
          </IonItemDivider>

          <IonList lines="full">
            <IonItem>
              <IonLabel>Enable New Account Wizard</IonLabel>
              <IonToggle
                checked={accountWizard}
                onIonChange={(e: any) => setAccountWizard(e.detail.checked)}
              />
            </IonItem>

            <div
              className="ion-padding-horizontal"
              style={{ paddingTop: '5px', paddingBottom: '5px' }}
            >
              <Subtitle>
                Allow users to apply for a direct account within Trulla
              </Subtitle>
            </div>

            <IonItem>
              <IonLabel>Example Setting</IonLabel>
              <IonToggle disabled={!accountWizard} />
            </IonItem>
            <IonItem>
              <IonLabel>A Second Example Setting</IonLabel>
              <IonToggle disabled={!accountWizard} />
            </IonItem>
          </IonList>
        </IonContent>
      </IonModal>
    </Page>
  );
};
