import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonIcon,
  IonRow,
  useIonModal,
} from '@ionic/react';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import { Page } from 'components/shared/Page';

import { faHospital } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardStatOrders } from 'components/buyer/CardStatOrders';
import { CardStatSpend } from 'components/buyer/CardStatSpend';
import ModalChangeLocation from 'components/buyer/ModalChangeLocation';
import ModalAddSupplier from 'components/buyer/ModalSupplierAdd';
import TableOrders from 'components/buyer/TableOrders';
import Divider from 'components/shared/Divider';
import LogoSupplier from 'components/shared/LogoSupplier';
import { SelectTimeFrame, TimeFrame } from 'components/shared/SelectTimeFrame';
import { Account } from 'interfaces/Account';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { Location } from 'interfaces/Location';
import { Supplier } from 'interfaces/Supplier';
import { chevronForwardOutline } from 'ionicons/icons';
import 'pages/buyer/PageHome.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PurchaseOrderStatus } from 'types/PurchaseOrderStatus';
import { isAnalyst } from 'utils';

export const PageHome: React.FC = () => {
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.app.user
  );
  const allSuppliers: Supplier[] | null = useSelector(
    (state: any) => state.app.allSuppliers
  );
  const accounts: Account[] | null = useSelector(
    (state: any) => state.app.accounts
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const [refresh, setRefresh] = useState(false);
  const [supplierModalOpen, setSupplierModalOpen] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(
    null
  );
  const [timeFrameLabel, setTimeFrameLabel] = useState<string>();
  const [filterMinDate, setFilterMinDate] = useState<string | null>(null);
  const [mySuppliers, setMySuppliers] = useState<Supplier[]>([]);
  const [recommendedSuppliers, setRecommendedSuppliers] = useState<Supplier[]>(
    []
  );
  const [presentLocations, dismissLocations] = useIonModal(
    ModalChangeLocation,
    {
      onDismiss: (data: string, role: string) => dismissLocations(data, role),
    }
  );
  const breadcrumbs: Array<Breadcrumb> = [{ label: 'Home' }];

  useEffect(() => {
    if (!allSuppliers || !accounts) {
      return;
    }
    setMySuppliers(
      allSuppliers.filter((supplier) =>
        accounts.find((account) => account.supplier_id === supplier.supplier_id)
      )
    );
    setRecommendedSuppliers(
      allSuppliers?.filter(
        (supplier) =>
          accounts.find(
            (account) => account.supplier_id === supplier.supplier_id
          ) === undefined
      )
    );
  }, [accounts, allSuppliers, selectedLocation, refresh]);

  const showLocations = () => {
    presentLocations({
      onWillDismiss: (e: any) => {},
    });
  };

  const onCloseSupplierModal = (data?: any) => {
    setSupplierModalOpen(false);
    setSelectedSupplier(null);
    setRefresh(!refresh);
  };

  const onClickRecommendedSupplier = (supplier: Supplier) => {
    setSelectedSupplier(supplier);
    setSupplierModalOpen(true);
  };

  return (
    <Page title="Home" className="pageHome">
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
      {selectedLocation ? (
        <>
          <IonRow>
            <IonCol size="12" sizeLg="6" sizeXl="8" className="ion-no-padding">
              <IonRow>
                <IonCol>
                  <SelectTimeFrame
                    onUpdate={(timeFrame: TimeFrame) => {
                      setFilterMinDate(timeFrame.value);
                      setTimeFrameLabel(timeFrame.label);
                    }}
                  />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12" sizeMd="6">
                  <CardStatOrders
                    label={timeFrameLabel}
                    minDate={filterMinDate}
                  />
                </IonCol>
                <IonCol size="12" sizeMd="6">
                  <CardStatSpend
                    label={timeFrameLabel}
                    minDate={filterMinDate}
                  />
                </IonCol>
                <div className="fullWidth ion-padding-horizontal">
                  <Divider />
                </div>
                <IonCol size="12">
                  <IonCard className="ion-no-padding">
                    <TableOrders
                      filterStatus={null}
                      setFilterStatus={(value: PurchaseOrderStatus | null) => {
                        console.log(value);
                      }}
                      itemsPerPageOptions={[5]}
                      title="Recent Orders"
                      hidePagination={true}
                      hideChangeLocation={true}
                    />
                  </IonCard>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12">
                  <IonButton
                    expand="full"
                    fill="outline"
                    shape="round"
                    routerLink="/orders"
                    routerDirection="forward"
                  >
                    View Order History
                    <IonIcon icon={chevronForwardOutline} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCol>

            <IonCol
              size="12"
              sizeLg="6"
              sizeXl="4"
              className="ion-padding-start"
            >
              <IonRow>
                {mySuppliers && mySuppliers.length > 0 ? (
                  <>
                    <IonCol size="12">
                      <h2 className="sectionTitle" style={{ marginTop: '4px' }}>
                        My Suppliers
                      </h2>
                    </IonCol>
                    {mySuppliers.map((supplier) => (
                      <IonCard
                        className="supplierCard"
                        key={supplier.supplier_id}
                        button
                        routerLink={`/suppliers/${supplier.supplier_id}`}
                        routerDirection="forward"
                      >
                        <IonCardContent className="ion-text-center">
                          <LogoSupplier src={supplier.logo} />
                        </IonCardContent>
                      </IonCard>
                    ))}
                  </>
                ) : null}
              </IonRow>
              {authUser && !isAnalyst(authUser) ? (
                <IonRow>
                  <IonCol size="12">
                    <h2 className="sectionTitle" style={{ marginTop: '26px' }}>
                      Add Suppliers
                    </h2>
                  </IonCol>
                  <div className="d-flex fullWidth flex-wrap ion-justify-start">
                    {recommendedSuppliers.map((supplier) => (
                      <IonCard
                        className="supplierCard"
                        key={supplier.supplier_id}
                        button
                        onClick={() => onClickRecommendedSupplier(supplier)}
                        data-tooltip-id="global-tooltip"
                        data-tooltip-content={`Click to add ${supplier.name} accounts to this location`}
                        data-tooltip-variant="info"
                        data-tooltip-place="bottom"
                      >
                        <IonCardContent className="ion-text-center">
                          <LogoSupplier src={supplier.logo} />
                        </IonCardContent>
                      </IonCard>
                    ))}
                  </div>
                  {recommendedSuppliers && (
                    <>
                      {recommendedSuppliers.map((supplier) => (
                        <IonCol
                          key={supplier.supplier_id}
                          size="12"
                          sizeSm="6"
                          sizeLg="3"
                        ></IonCol>
                      ))}
                    </>
                  )}
                  {recommendedSuppliers && recommendedSuppliers.length === 0 ? (
                    <p className="ion-no-margin ion-margin-start font-style-italic">
                      Nothing to display
                    </p>
                  ) : null}
                </IonRow>
              ) : null}
            </IonCol>
          </IonRow>
          <ModalAddSupplier
            isOpen={supplierModalOpen}
            supplier={selectedSupplier}
            onClose={(data?: any) => onCloseSupplierModal(data)}
            onDismiss={(data?: any) => onCloseSupplierModal(data)}
          />
        </>
      ) : (
        <div className="ion-text-center">
          <p className="ion-text-center">No location selected</p>
          <IonButton onClick={showLocations}>
            Select Location
            <span slot="end">
              <FontAwesomeIcon icon={faHospital} />
            </span>
          </IonButton>
        </div>
      )}
    </Page>
  );
};
