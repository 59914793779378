import { IonCard, IonCol, IonRow, IonSearchbar } from '@ionic/react';
import ButtonAddToCart from 'components/buyer/ButtonAddToCart';
import ChipAccount from 'components/buyer/ChipAccount';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import DataTable, {
  DataTableHeader,
  DataTablePagination,
  DataTableRow,
} from 'components/shared/DataTable';
import ItemDescriptionCol from 'components/shared/ItemDescriptionCol';
import LogoSupplier from 'components/shared/LogoSupplier';
import { Page } from 'components/shared/Page';
import { useApi } from 'hooks/useApi';
import { Account } from 'interfaces/Account';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { CatalogItem } from 'interfaces/CatalogItem';
import { Location } from 'interfaces/Location';
import { PurchaseOrderItem } from 'interfaces/PurchaseOrderItem';
import { ShoppingCartItem } from 'interfaces/ShoppingCartItem';
import { Supplier } from 'interfaces/Supplier';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setMedicationSearch } from 'redux/appReducer';
import { isAnalyst, toUsd } from 'utils';

export const PageItems: React.FC = () => {
  const api = useApi();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.app.user
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const dispatch = useDispatch();

  const [selectedSupplier, setSelectedSupplier] = useState<Supplier>();

  const location = useLocation();
  const [search, setSearch] = useState<string | null>();

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<CatalogItem[]>([]);

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [accountsLoading, setAccountsLoading] = useState(false);

  const [pagination, setPagination] = useState<DataTablePagination>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const [selectedItem, setSelectedItem] = useState<
    CatalogItem | ShoppingCartItem | PurchaseOrderItem | null
  >(null);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Items' },
  ];

  const params = new URLSearchParams(location.search);

  useEffect(() => {
    getAccounts();
    if (selectedSupplier) {
      params.append('supplier_id', `${selectedSupplier.supplier_id}`);
    }
  }, [selectedLocation, selectedSupplier]);

  useEffect(() => {
    setSearch(params.get('search') || '');
    dispatch(setMedicationSearch(null));
  }, [location.search]);

  useEffect(() => {
    getItems();
  }, [search, pagination, selectedLocation]);

  const headers: Array<DataTableHeader> = [
    { text: 'Item', key: 'item' },
    { text: 'Account', key: 'account' },
    { text: 'Pack Price', key: 'packPrice', align: 'right' },
    { text: 'Quantity', key: 'actions', align: 'left', width: '325px' },
  ];

  const getAccounts = () => {
    if (!selectedLocation) return;
    setAccountsLoading(true);
    api
      .get(`locations/${selectedLocation.location_id}/accounts`)
      .then(({ data }) => {
        setAccounts(data);
        setAccountsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAccountsLoading(false);
      });
  };

  const getItems = () => {
    if (!selectedLocation) return;
    setLoading(true);
    api
      .get(`locations/${selectedLocation.location_id}/catalog`, {
        limit: pagination?.limit,
        offset: pagination?.offset,
        search: search || undefined,
      })
      .then((response) => {
        if (response.status === 200) {
          setItems(response.data.result || []);
          setTotalCount(response.data.total);
        }
        setLoading(false);
      });
  };

  return (
    <Page title="Search Results">
      <Breadcrumbs breadcrumbs={breadcrumbs} title="Search Results" />

      <IonRow>
        <IonCol size="12">
          <IonCard>
            <DataTable
              headers={headers}
              search={false}
              loading={loading}
              title="All Items"
              onPaginationChange={(pagination: DataTablePagination) =>
                setPagination(pagination)
              }
              itemsPerPage={50}
              totalCount={totalCount}
              subtitle={
                <>
                  {selectedSupplier && (
                    <LogoSupplier
                      src={selectedSupplier.logo}
                      alt={selectedSupplier.name}
                      title={selectedSupplier.name}
                      style={{ objectPosition: 'left' }}
                    />
                  )}
                </>
              }
              rows={items.map((item): DataTableRow => {
                const selectedAccount = accounts.find(
                  (account) => account.number === item.account_number
                );
                const row: DataTableRow = {
                  item: item,
                  key: `${item.supplier_item_number}-${item.account_number}`,
                  columns: [
                    {
                      header: 'item',
                      content: (
                        <ItemDescriptionCol
                          item={item}
                          account={selectedAccount}
                        />
                      ),
                    },
                    {
                      header: 'account',
                      content: (
                        <>
                          {selectedAccount ? (
                            <ChipAccount
                              account={selectedAccount}
                              style={{ margin: 0 }}
                            />
                          ) : null}
                        </>
                      ),
                    },
                    {
                      header: 'packPrice',
                      content: (
                        <p className="font-weight-500 text-color-black">
                          {item.item_unit_price
                            ? toUsd(parseFloat(item.item_unit_price || '0'))
                            : 'N/A'}
                        </p>
                      ),
                    },
                    {
                      header: 'actions',
                      content: (
                        <>
                          {selectedAccount &&
                          authUser &&
                          !isAnalyst(authUser) ? (
                            <ButtonAddToCart
                              item={item}
                              account={selectedAccount}
                            />
                          ) : null}
                        </>
                      ),
                    },
                  ],
                };
                return row;
              })}
            >
              <IonRow className="ion-text-left">
                <IonCol size="12">
                  <IonSearchbar
                    value={search}
                    onIonChange={(e: any) =>
                      setSearch(e.detail.value as string)
                    }
                    debounce={350}
                  />
                </IonCol>
              </IonRow>
            </DataTable>
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
