import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import { Page } from 'components/shared/Page';
import Subtitle from 'components/shared/Subtitle';
import TableSupplierCatalogs from 'components/supplier/TableSupplierCatalogs';
import {
  addOutline,
  closeOutline,
  cloudUploadOutline,
  settingsSharp,
} from 'ionicons/icons';
import { useState } from 'react';

export const PageSupplierCatalogs: React.FC = () => {
  const [catalogSync, setCatalogSync] = useState(true);

  const [isOpen, setIsOpen] = useState(false);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Catalogs' },
  ];

  const onDismiss = () => {
    setIsOpen(false);
  };

  return (
    <Page title="Supplier Catalogs">
      <Breadcrumbs breadcrumbs={breadcrumbs}>
        <IonButton
          disabled={catalogSync}
          size="small"
          data-tooltip-id="global-tooltip"
          data-tooltip-content="CSV import"
        >
          Upload Catalog
          <IonIcon slot="end" icon={cloudUploadOutline} />
        </IonButton>
        <IonButton disabled={catalogSync} size="small">
          Add item
          <IonIcon slot="end" icon={addOutline} />
        </IonButton>
        <IonButton
          fill="clear"
          size="small"
          color="medium"
          onClick={() => setIsOpen(true)}
        >
          <IonIcon slot="icon-only" icon={settingsSharp} />
        </IonButton>
      </Breadcrumbs>
      <IonRow>
        <IonCol>
          <IonCard>
            <TableSupplierCatalogs catalogSync={catalogSync} />
          </IonCard>
        </IonCol>
      </IonRow>
      <IonModal
        id="modalCatalogSettings"
        isOpen={isOpen}
        onDidDismiss={onDismiss}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Catalog Settings</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={onDismiss} fill="clear">
                <IonIcon icon={closeOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList lines="full">
            <IonItem className="ion-margin-bottom">
              <IonLabel>Enable Catalog Sync</IonLabel>
              <IonToggle
                checked={catalogSync}
                onIonChange={(e: any) => setCatalogSync(e.detail.checked)}
              />

              <div slot="helper">
                <Subtitle>Catalogs will be synced through the vendor.</Subtitle>
                <Subtitle className="text-style-italic font-weight-500">
                  Note: Turning this on disables the ability to upload and add
                  catalogs within Trulla Direct
                </Subtitle>
              </div>
            </IonItem>
          </IonList>
        </IonContent>
      </IonModal>
    </Page>
  );
};
