import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRouterLink,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  useIonViewWillEnter,
} from '@ionic/react';
import ButtonFilter from 'components/shared/ButtonFilter';
import DataTable, {
  DataTableHeader,
  DataTableItemAction,
  DataTableRow,
} from 'components/shared/DataTable';
import {
  checkmarkCircleOutline,
  closeCircleOutline,
  eyeOutline,
  helpCircleOutline,
} from 'ionicons/icons';

import 'components/buyer/TableUsers.css';
import { useOnboardApi } from 'hooks/useOnboardApi';
import { OnboardRequest } from 'interfaces/OnboardRequest';
import { useEffect, useState } from 'react';

export const TableOnboardRequests: React.FC<{ isSupplier?: boolean }> = ({
  isSupplier,
}) => {
  const onboardApi = useOnboardApi();

  const [loading, setLoading] = useState(false);
  const [onboardRequests, setOnboardRequests] = useState<OnboardRequest[]>([]);
  const [filterStatus, setFilterStatus] = useState<0 | 1 | 2>(0);
  const [tableTitle, setTableTitle] = useState<string>('Pending Requests');

  const headers: DataTableHeader[] = [
    { text: 'Name', key: 'name', sortable: false },
    { text: 'Email Address', key: 'email', sortable: false },
    { text: 'Organization Name', key: 'organization', sortable: false },
    { text: 'Location Name', key: 'location', sortable: false },
    { text: 'Status', key: 'status', align: 'center' },
  ];

  useIonViewWillEnter(() => {
    getOnboardRequests();
  }, []);

  useEffect(() => {
    getOnboardRequests();
    if (filterStatus === 0) {
      setTableTitle('Pending Requests');
    } else if (filterStatus === 1) {
      setTableTitle('Approved Requests');
    } else if (filterStatus === 2) {
      setTableTitle('Rejected Requests');
    }
  }, [filterStatus]);

  const actions = (onboardRequest: OnboardRequest): DataTableItemAction[] => {
    const actions: DataTableItemAction[] = [
      {
        label: 'View Request',
        icon: <IonIcon icon={eyeOutline} />,
        routerLink: (onboardRequest: OnboardRequest) => {
          return `/admin/requests/${onboardRequest.userId}`;
        },
        callback: (onboardRequest: OnboardRequest) => {
          return;
        },
      },
    ];
    return actions;
  };

  const getOnboardRequests = () => {
    setLoading(true);
    onboardApi
      .get('onboardrequests', {
        status: filterStatus !== null ? filterStatus : undefined,
      })
      .then((response) => {
        setOnboardRequests(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const StatusIcon: React.FC<{ onboardRequest: OnboardRequest }> = ({
    onboardRequest,
  }) => {
    let color;
    let icon;
    let title;
    switch (onboardRequest.status) {
      case 0:
        color = 'medium';
        icon = helpCircleOutline;
        title = 'Pending';
        break;
      case 1:
        color = 'success';
        icon = checkmarkCircleOutline;
        title = 'Approved';
        break;
      case 2:
        color = 'danger';
        icon = closeCircleOutline;
        title = 'Rejected';
        break;
    }

    return (
      <IonIcon
        color={color}
        className="font-size-large"
        icon={icon}
        title={title}
      />
    );
  };

  return (
    <DataTable
      title={tableTitle}
      headers={headers}
      actions={actions}
      loading={loading}
      rows={onboardRequests.map(
        (onboardRequest: OnboardRequest): DataTableRow => {
          const row: DataTableRow = {
            item: onboardRequest,
            key: onboardRequest.userId,
            columns: [
              {
                header: 'name',
                content: (
                  <>
                    <IonRouterLink
                      className="font-size-large"
                      routerLink={`/admin/requests/${onboardRequest.userId}`}
                    >
                      {onboardRequest.userData.UserFirstName}{' '}
                      {onboardRequest.userData.UserLastName}
                    </IonRouterLink>
                  </>
                ),
              },
              {
                header: 'email',
                content: <p>{onboardRequest.userName}</p>,
              },
              {
                header: 'organization',
                content: <p>{onboardRequest.userData.OrganizationName}</p>,
              },
              {
                header: 'location',
                content: <p>{onboardRequest.userData.LocationName}</p>,
              },
              {
                header: 'status',
                content: <StatusIcon onboardRequest={onboardRequest} />,
              },
            ],
          };
          return row;
        }
      )}
    >
      <IonRow>
        <IonCol size="12" className="ion-text-right">
          <IonSegment
            value={`${filterStatus}`}
            onIonChange={(e) =>
              setFilterStatus(parseInt(e.detail.value || '', 0) as 0 | 1 | 2)
            }
          >
            <IonSegmentButton value="0">Pending</IonSegmentButton>
            <IonSegmentButton value="1">Approved</IonSegmentButton>
            <IonSegmentButton value="2">Rejected</IonSegmentButton>
          </IonSegment>
        </IonCol>
      </IonRow>
    </DataTable>
  );
};
