import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { FormManageAccountsAdd } from 'components/buyer/FormManageAccountsAdd';
import { FormManageAccountsList } from 'components/buyer/FormManageAccountsList';
import LogoSupplier from 'components/shared/LogoSupplier';
import { Account } from 'interfaces/Account';
import { Location } from 'interfaces/Location';
import { Supplier } from 'interfaces/Supplier';
import { useEffect, useState } from 'react';
import { SupplierConfig, supplierConfig } from 'supplierConfig';

const FormManageAccounts: React.FC<{
  loading: boolean;
  setLoading: (value: boolean) => void;
  supplier: Supplier;
  location: Location;
  existingAccounts?: Account[];
  newAccountIds: number[];
  setNewAccountIds: (accountIds: number[]) => void;
  searched?: boolean;
  setSearched?: (value: boolean) => void;
  editAccount?: (account: Account) => void;
  submitTrigger: number;
  onClickAddMore?: () => void;
}> = ({
  loading,
  setLoading,
  supplier,
  location,
  existingAccounts,
  newAccountIds,
  setNewAccountIds,
  searched,
  setSearched,
  editAccount,
  submitTrigger,
  onClickAddMore,
}) => {
  const [customConfig, setCustomConfig] = useState<SupplierConfig | null>(null);

  useEffect(() => {
    if (supplier) {
      setCustomConfig(
        supplierConfig.find((config) => config.number === supplier.number) ||
          null
      );
    }
  }, [supplier]);

  return (
    <IonGrid className="ion-no-padding">
      <IonRow>
        <IonCol
          size="12"
          className="d-flex ion-align-items-center ion-justify-content-center padding-xs"
        >
          <LogoSupplier src={supplier.logo} />
        </IonCol>
      </IonRow>
      {existingAccounts && existingAccounts.length > 0 ? (
        <FormManageAccountsList
          existingAccounts={existingAccounts}
          onClickAccount={(account: Account) => {
            if (editAccount) {
              editAccount(account);
            }
          }}
          submitTrigger={submitTrigger}
          onClickAddMore={() => {
            if (onClickAddMore) {
              onClickAddMore();
            }
          }}
        />
      ) : (
        <FormManageAccountsAdd
          loading={loading}
          setLoading={setLoading}
          newAccountIds={newAccountIds}
          setNewAccountIds={setNewAccountIds}
          searched={searched}
          setSearched={setSearched}
          supplierConfig={customConfig}
          supplier={supplier}
          location={location}
          submitTrigger={submitTrigger}
        />
      )}
    </IonGrid>
  );
};

export default FormManageAccounts;
