import 'pages/shared/PageLoading.css';
import { Page } from 'components/shared/Page';
export const PageLoading: React.FC = () => {
  return (
    <Page title="Loading" className="pageLoading">
      <div className="loadingContainer">
        <p>Loading...</p>
        <svg
          className="loading"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="200px"
          height="200px"
          viewBox="0 0 200 200"
          enableBackground="new 0 0 200 200"
        >
          <g>
            <path
              fill="none"
              stroke="var(--ion-color-primary)"
              strokeWidth="3"
              strokeMiterlimit="10"
              d="M132.605,100c0,18.008-14.598,32.605-32.605,32.605
    c-18.007,0-32.605-14.598-32.605-32.605c0-18.007,14.598-32.605,32.605-32.605"
            />
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 100 100"
              to="360 100 100"
              dur="1.5s"
              repeatCount="indefinite"
            />
          </g>
          <g>
            <path
              fill="none"
              stroke="var(--ion-color-tertiary)"
              strokeWidth="3"
              strokeMiterlimit="10"
              d="M126.502,100c0,14.638-11.864,26.502-26.502,26.502
    c-14.636,0-26.501-11.864-26.501-26.502c0-14.636,11.865-26.501,26.501-26.501"
            />
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 100 100"
              to="360 100 100"
              dur="1s"
              repeatCount="indefinite"
            />
          </g>
          <g>
            <path
              fill="none"
              stroke="var(--ion-color-primary)"
              strokeWidth="3"
              strokeMiterlimit="10"
              d="M120.494,100c0,11.32-9.174,20.494-20.494,20.494
    c-11.319,0-20.495-9.174-20.495-20.494c0-11.319,9.176-20.495,20.495-20.495"
            />
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 100 100"
              to="360 100 100"
              dur="0.75s"
              repeatCount="indefinite"
            />
          </g>
        </svg>
      </div>
    </Page>
  );
};
