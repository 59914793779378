import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonText,
} from '@ionic/react';
import { AppUser } from 'interfaces/AppUser';
import { informationCircleOutline } from 'ionicons/icons';
import { formatDateTime } from 'utils';

import 'components/buyer/PopoverUserMeta.css';
const mediumDateTimeOptions: Intl.DateTimeFormatOptions = {
  dateStyle: 'medium',
  timeStyle: 'medium',
};
const PopoverUserMeta: React.FC<{ user: AppUser }> = ({ user }) => {
  return (
    <>
      <IonButton id="userMetaButton" fill="clear" size="small">
        <IonIcon
          slot="icon-only"
          size="large"
          icon={informationCircleOutline}
        />
      </IonButton>
      <IonPopover
        className="popoverUserMeta"
        trigger="userMetaButton"
        triggerAction="click"
      >
        <IonContent className="ion-padding">
          <IonList lines="none">
            <IonItem>
              <p slot="start">Created</p>
              <IonLabel className="ion-text-right">
                <p>
                  {user.created &&
                    formatDateTime(user.created, mediumDateTimeOptions)}
                </p>
              </IonLabel>
            </IonItem>
            {user.updated && user.updated !== user.created && (
              <IonItem>
                <p slot="start">Updated</p>
                <IonLabel className="ion-text-right">
                  {!user.active ? (
                    <IonText color="danger">
                      <p>
                        {formatDateTime(user.updated, mediumDateTimeOptions)}
                      </p>
                    </IonText>
                  ) : (
                    <p>{formatDateTime(user.updated, mediumDateTimeOptions)}</p>
                  )}
                </IonLabel>
              </IonItem>
            )}
          </IonList>
        </IonContent>
      </IonPopover>
    </>
  );
};

export default PopoverUserMeta;
