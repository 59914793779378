import { OverlayEventDetail } from '@ionic/core/components';
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonProgressBar,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useApi } from 'hooks/useApi';
import { AppUser } from 'interfaces/AppUser';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { Location } from 'interfaces/Location';
import { Organization } from 'interfaces/Organization';
import { closeOutline, pencilOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const ModalLocationUsers: React.FC<{
  trigger: string;
  location: Location;
  locationUserIds: number[];
  editing: boolean;
  setEditing: (value: boolean) => void;
  onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => void;
}> = ({
  trigger,
  location,
  locationUserIds,
  editing,
  setEditing,
  onWillDismiss,
}) => {
  const api = useApi();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<AppUser[]>([]);
  const [search, setSearch] = useState<string | null>(null);
  const [userIds, setUserIds] = useState<number[]>([]);

  const modal = useRef<HTMLIonModalElement>(null);

  useEffect(() => {
    getAllUsers();
  }, [search]);

  useEffect(() => {
    setUserIds(locationUserIds);
  }, [locationUserIds]);

  const cancel = () => {
    modal.current?.dismiss(userIds, 'cancel');
    setUserIds(locationUserIds);
  };
  const confirm = () => {
    modal.current?.dismiss(userIds, 'confirm');
  };

  const getAllUsers = () => {
    if (!organization) {
      return;
    }
    setLoading(true);
    api
      .get(`organizations/${organization.organization_id}/users`, {
        search: search ? search : undefined,
        order_by: 'first_name',
        order_by_desc: false,
      })
      .then((response) => {
        if (response.status === 200) {
          setUsers(response.data.result || []);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (app_user_id: number, value: boolean) => {
    const tempArray = [...userIds];
    if (value) {
      tempArray.push(app_user_id);
    } else {
      const index = tempArray.indexOf(app_user_id);
      if (index > -1) {
        tempArray.splice(index, 1);
      }
    }
    setUserIds(tempArray);
  };

  return (
    <IonModal
      ref={modal}
      trigger={trigger}
      onWillDismiss={(ev) => onWillDismiss(ev)}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Manage Users for {location.name}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={cancel}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={search}
            onIonChange={(e: any) => setSearch(e.detail.value as string)}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading ? (
          <IonProgressBar
            type="indeterminate"
            style={{ height: 'var(--app-spacing-xs)' }}
          />
        ) : null}
        <IonList>
          {users.map((user: AppUser) => (
            <IonItem key={user.app_user_id}>
              <IonCheckbox
                slot="start"
                onIonChange={(e: any) =>
                  handleChange(user.app_user_id, e.detail.checked)
                }
                checked={userIds.indexOf(user.app_user_id) > -1}
                disabled={!editing}
              />
              <IonLabel>
                <div className="fullWidth d-flex ion-align-items-center ion-justify-content-between">
                  <span>
                    {user.first_name} {user.last_name}
                  </span>
                  <span>{user.email_address}</span>
                </div>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton fill="clear" color="dark" size="small" onClick={cancel}>
              Cancel
            </IonButton>
            {!editing ? (
              <IonButton
                size="small"
                onClick={() => setEditing(true)}
                disabled={loading}
              >
                Edit
                <IonIcon icon={pencilOutline} slot="end" />
              </IonButton>
            ) : (
              <IonButton size="small" onClick={confirm} disabled={loading}>
                Done
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default ModalLocationUsers;
