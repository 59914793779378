import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSkeletonText,
  useIonViewWillEnter,
} from '@ionic/react';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import ButtonEdit from 'components/shared/ButtonEdit';
import Divider from 'components/shared/Divider';
import LogoSupplier from 'components/shared/LogoSupplier';
import { Page } from 'components/shared/Page';
import Subtitle from 'components/shared/Subtitle';
import { CardSupplierContact } from 'components/supplier/CardSupplierContact';
import { ModalSupplierContact } from 'components/supplier/ModalSupplierContact';
import { useApi } from 'hooks/useApi';
import { Contact } from 'interfaces/Contact';
import { Supplier } from 'interfaces/Supplier';
import { addOutline, cloudUploadOutline, openOutline } from 'ionicons/icons';
import 'pages/supplier/PageSupplierSettings.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const PageSupplierSettings: React.FC = () => {
  const supplier: Supplier | null = useSelector(
    (state: any) => state.app.supplier
  );
  const api = useApi();

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);

  const [name, setName] = useState<string>();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [contactTelephone, setContactTelephone] = useState<string>();
  const [contactDays, setContactDays] = useState<string>();
  const [contactHours, setContactHours] = useState<string>();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Settings' },
  ];

  useIonViewWillEnter(() => {
    getContacts();
  }, []);

  useEffect(() => {
    setName(supplier?.name);
    getContacts();
  }, [supplier]);

  const onClickUploadPhoto = () => {
    console.log('Upload Photo');
  };

  const revertChanges = () => {
    setName(supplier?.name);
  };

  const onClickCancel = () => {
    revertChanges();
    setEditing(false);
  };

  const onClickSave = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setEditing(false);
      alert('save changes');
    }, 1000);
  };

  const onClickTerms = () => {
    console.log('onClickTerms');
  };

  const getContacts = () => {
    if (!supplier) {
      return;
    }
    setLoading(true);
    api.get(`suppliers/${supplier.supplier_id}/contacts`).then((response) => {
      if (response.status === 200) {
        setContacts(response.data || []);
      }
      setLoading(false);
    });
  };

  const Footer = () => {
    return (
      <div slot="end">
        <ButtonEdit
          size="default"
          editing={editing}
          loading={loading}
          onClickCancel={onClickCancel}
          onClickEdit={() => setEditing(true)}
          onClickSave={() => onClickSave()}
        />
      </div>
    );
  };

  return (
    <Page
      title="Supplier Settings"
      footer={<Footer />}
      className="supplierSettings"
    >
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
      <IonRow>
        <IonCol size="12" sizeMd="6" sizeXl="5">
          <IonCard>
            <IonCardHeader className="padding-bottom-xs">
              <IonCardTitle>Organization</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-no-padding ion-padding-bottom">
              <div className="ion-text-left padding-left">
                {(loading && (
                  <IonSkeletonText animated={true} className="supplierLogo" />
                )) || <LogoSupplier src={supplier?.logo} />}
              </div>
              <IonList>
                <IonItem lines="inset">
                  <IonLabel position="stacked">Organization Name</IonLabel>
                  <IonInput
                    disabled={loading}
                    readonly={!editing}
                    value={name}
                    onIonChange={(e: any) => setName(e.detail.value)}
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Terms And Conditions</IonLabel>
                  <div className="d-flex ion-align-items-center">
                    <span>Terms-and-conditions_example.pdf</span>
                    <IonButton
                      size="small"
                      fill="clear"
                      href="/assets/doc/lorem_ipsum.pdf"
                      target="_blank"
                      className="ion-no-padding ion-no-margin margin-left-sm"
                      style={{
                        '--padding-end': '5px',
                      }}
                    >
                      View
                      <IonIcon icon={openOutline} slot="end" size="small" />
                    </IonButton>
                  </div>
                  {editing ? (
                    <div
                      slot="end"
                      className="fullHeight d-inline-flex ion-align-items-center ion-no-margin"
                    >
                      <IonButton
                        fill="clear"
                        data-tooltip-id="global-tooltip"
                        data-tooltip-content="Upload New"
                        data-tooltip-place="bottom"
                      >
                        <IonIcon slot="icon-only" icon={cloudUploadOutline} />
                      </IonButton>
                    </div>
                  ) : null}
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Returns Policy</IonLabel>
                  <div className="d-flex ion-align-items-center">
                    <span>Returns-policy_example.pdf</span>
                    <IonButton
                      size="small"
                      fill="clear"
                      href="/assets/doc/lorem_ipsum.pdf"
                      target="_blank"
                      className="ion-no-padding ion-no-margin margin-left-sm"
                      style={{
                        '--padding-end': '5px',
                      }}
                    >
                      View
                      <IonIcon icon={openOutline} slot="end" size="small" />
                    </IonButton>
                  </div>
                  {editing ? (
                    <div
                      slot="end"
                      className="fullHeight d-inline-flex ion-align-items-center ion-no-margin"
                    >
                      <IonButton
                        fill="clear"
                        data-tooltip-id="global-tooltip"
                        data-tooltip-content="Upload New"
                        data-tooltip-place="bottom"
                      >
                        <IonIcon slot="icon-only" icon={cloudUploadOutline} />
                      </IonButton>
                    </div>
                  ) : null}
                </IonItem>
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonCol>
        <IonCol size="12" sizeMd="6" sizeXl="7"></IonCol>
      </IonRow>
      <Divider />
      <IonRow>
        <IonCol size="12">
          <h2 className="sectionTitle margin-bottom-xs">
            Contacts{' '}
            <IonButton
              fill="clear"
              size="small"
              color="primary"
              onClick={() => setIsOpen(true)}
              className="ion-no-margin ion-no-padding"
              style={{ verticalAlign: 'middle' }}
              data-tooltip-id="global-tooltip"
              data-tooltip-content="Add Contact"
              data-tooltip-place="bottom"
            >
              <IonIcon slot="icon-only" icon={addOutline} />
            </IonButton>
          </h2>
          <Subtitle>Contact info here will be shown to buyers.</Subtitle>
        </IonCol>
      </IonRow>
      <IonRow>
        {contacts.map((contact: Contact) => (
          <IonCol size="12" sizeXl="6" key={contact.contact_id}>
            <CardSupplierContact
              contact={contact}
              onClickEdit={(contact: Contact) => {
                setSelectedContact(contact);
                setIsOpen(true);
              }}
            />
          </IonCol>
        ))}
      </IonRow>
      <ModalSupplierContact
        isOpen={isOpen}
        onDismiss={(data?: Contact[]) => {
          setIsOpen(false);
          setSelectedContact(null);
          getContacts();
        }}
        supplierContact={selectedContact}
      />
    </Page>
  );
};
