import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToggle,
} from '@ionic/react';
import SelectLocation340bType from 'components/buyer/SelectLocation340bType';
import SelectLocationType from 'components/buyer/SelectLocationType';
import SelectState from 'components/shared/SelectState';
import { Location } from 'interfaces/Location';
import { useEffect, useState } from 'react';

const FormManageLocation: React.FC<{
  location?: Location;
  loading: boolean;
  editing?: boolean;
  name: string | null;
  locationTypeId: number | null;
  location340bTypeId: number | null;
  facilityId: string | null;
  departmentId: string | null;
  license: string | null;
  licenseExpiration: string | null;
  deaNumber: string | null;
  deaExpiration: string | null;
  // poPrefix: string | null;
  addressName: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  country: string | null;
  billingAddressName: string | null;
  billingAddress1: string | null;
  billingAddress2: string | null;
  billingCity: string | null;
  billingState: string | null;
  billingPostalCode: string | null;
  billingCountry: string | null;
  differentBillingAddress : boolean;
  setName: (value: string | null) => void;
  setLocationTypeId: (value: number | null) => void;
  setLocation340bTypeId: (value: number | null) => void;
  setFacilityId: (value: string | null) => void;
  setDepartmentId: (value: string | null) => void;
  setLicense: (value: string | null) => void;
  setLicenseExpiration: (value: string | null) => void;
  setDeaNumber: (value: string | null) => void;
  setDeaExpiration: (value: string | null) => void;
  // setPoPrefix: (value: string | null) => void;
  setAddressName: (value: string | null) => void;
  setAddress1: (value: string | null) => void;
  setAddress2: (value: string | null) => void;
  setCity: (value: string | null) => void;
  setState: (value: string | null) => void;
  setPostalCode: (value: string | null) => void;
  setCountry: (value: string | null) => void;
  setBillingAddressName: (value: string | null) => void;
  setBillingAddress1: (value: string | null) => void;
  setBillingAddress2: (value: string | null) => void;
  setBillingCity: (value: string | null) => void;
  setBillingState: (value: string | null) => void;
  setBillingPostalCode: (value: string | null) => void;
  setBillingCountry: (value: string | null) => void;
  setDifferentBillingAddress: (value : boolean) => void
}> = ({
  location,
  loading,
  editing,
  name,
  locationTypeId,
  location340bTypeId,
  facilityId,
  departmentId,
  license,
  licenseExpiration,
  deaNumber,
  deaExpiration,
  // poPrefix,
  addressName,
  address1,
  address2,
  city,
  state,
  postalCode,
  country,
  billingAddressName,
  billingAddress1,
  billingAddress2,
  billingCity,
  billingState,
  billingPostalCode,
  billingCountry,
  differentBillingAddress,
  setName,
  setLocationTypeId,
  setLocation340bTypeId,
  setFacilityId,
  setDepartmentId,
  setLicense,
  setLicenseExpiration,
  setDeaNumber,
  setDeaExpiration,
  // setPoPrefix,
  setAddressName,
  setAddress1,
  setAddress2,
  setCity,
  setState,
  setPostalCode,
  setCountry,
  setBillingAddressName,
  setBillingAddress1,
  setBillingAddress2,
  setBillingCity,
  setBillingState,
  setBillingPostalCode,
  setBillingCountry,
  setDifferentBillingAddress
}) => {


  useEffect(() => {
    if (location?.billing_address1) {
      setDifferentBillingAddress(true);
    } else {
      setDifferentBillingAddress(false);
    }
  }, [location, editing]);

  return (
    <IonRow>
      <IonCol size="12" sizeLg="6">
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle className="d-flex ion-justify-content-between ion-align-items-center">
              <span>Facility Info</span>
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem>
                <IonLabel position="floating">Location Name</IonLabel>
                <IonInput
                  type="text"
                  required
                  value={name}
                  onIonChange={(e) => setName(e.detail.value as string)}
                  readonly={!editing}
                  disabled={loading}
                />
              </IonItem>

              <IonItem>
                <IonLabel position="floating">Facility Type</IonLabel>
                <SelectLocationType
                  value={locationTypeId}
                  setValue={setLocationTypeId}
                  disabled={!editing || loading}
                />
              </IonItem>

              <IonItem>
                <IonLabel position="floating">340B Type</IonLabel>
                <SelectLocation340bType
                  value={location340bTypeId}
                  setValue={setLocation340bTypeId}
                  disabled={!editing || loading}
                />
              </IonItem>

              <IonItem>
                <IonLabel position="floating">Facility ID</IonLabel>
                <IonInput
                  type="text"
                  value={facilityId}
                  onIonChange={(e) => setFacilityId(e.detail.value as string)}
                  readonly={!editing}
                  disabled={loading}
                />
              </IonItem>

              <IonItem>
                <IonLabel position="floating">Department ID</IonLabel>
                <IonInput
                  type="text"
                  value={departmentId}
                  onIonChange={(e) => setDepartmentId(e.detail.value as string)}
                  readonly={!editing}
                  disabled={loading}
                />
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonCol>

      <IonCol size="12" sizeLg="6">
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle className="d-flex ion-justify-content-between ion-align-items-center">
              <span>Licensing</span>
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonItem>
              <IonLabel position="floating">License Number</IonLabel>
              <IonInput
                type="text"
                readonly={!editing}
                value={license}
                onIonChange={(e: any) => setLicense(e.detail.value as string)}
                disabled={loading}
              />
            </IonItem>

            <IonItem>
              <IonLabel position="floating">License Expiration</IonLabel>
              <IonInput
                type="date"
                readonly={!editing}
                value={licenseExpiration}
                onIonChange={(e: any) =>
                  setLicenseExpiration(e.detail.value as string)
                }
                disabled={loading}
              />
            </IonItem>
            <IonList>
              <IonItem>
                <IonLabel position="floating">DEA Number</IonLabel>
                <IonInput
                  type="text"
                  readonly={!editing}
                  value={deaNumber}
                  onIonChange={(e: any) =>
                    setDeaNumber(e.detail.value as string)
                  }
                  disabled={loading}
                />
              </IonItem>

              <IonItem>
                <IonLabel position="floating">DEA Expiration</IonLabel>
                <IonInput
                  type="date"
                  readonly={!editing}
                  value={deaExpiration}
                  onIonChange={(e: any) =>
                    setDeaExpiration(e.detail.value as string)
                  }
                  disabled={loading}
                />
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonCol>

      <IonCol size="12" sizeLg="6">
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle className="d-flex ion-justify-content-between ion-align-items-center">
              <span>Address</span>
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem>
                <IonLabel position="floating">Address Name</IonLabel>
                <IonInput
                  type="text"
                  required
                  value={addressName}
                  onIonChange={(e) => setAddressName(e.detail.value as string)}
                  readonly={!editing}
                  disabled={loading}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Street Address Line 1</IonLabel>
                <IonInput
                  type="text"
                  required
                  value={address1}
                  onIonChange={(e) => setAddress1(e.detail.value as string)}
                  readonly={!editing}
                  disabled={loading}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">
                  Street Address Line 2 (optional)
                </IonLabel>
                <IonInput
                  type="text"
                  value={address2}
                  onIonChange={(e) => setAddress2(e.detail.value as string)}
                  readonly={!editing}
                  disabled={loading}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">City</IonLabel>
                <IonInput
                  type="text"
                  required
                  value={city}
                  onIonChange={(e) => setCity(e.detail.value as string)}
                  readonly={!editing}
                  disabled={loading}
                />
              </IonItem>
              <IonRow>
                <IonCol size="12" sizeLg="6" className="ion-no-padding">
                  <SelectState
                    value={state}
                    onIonChange={(e: any) => setState(e.detail.value as string)}
                    disabled={!editing || loading}
                  />
                </IonCol>
                <IonCol size="12" sizeLg="6" className="ion-no-padding">
                  <IonItem>
                    <IonLabel position="floating">Zip</IonLabel>
                    <IonInput
                      type="text"
                      required
                      value={postalCode}
                      onIonChange={(e) =>
                        setPostalCode(e.detail.value as string)
                      }
                      readonly={!editing}
                      disabled={loading}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>

              <IonItem lines="none">
                <IonLabel position="stacked">
                  Different Billing Address
                </IonLabel>
                <IonToggle
                  checked={differentBillingAddress}
                  disabled={!editing}
                  onIonChange={(e: any) =>
                    {
                      setDifferentBillingAddress(e.detail.checked as boolean);
                    }
                  }
                />
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonCol>

      <IonCol size="12" sizeLg="6">
        <IonCard disabled={!differentBillingAddress}>
          <IonCardHeader>
            <IonCardSubtitle className="d-flex ion-justify-content-between ion-align-items-center">
              <span>Billing Address</span>
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem>
                <IonLabel position="floating">Billing Address Name</IonLabel>
                <IonInput
                  type="text"
                  required
                  value={billingAddressName}
                  onIonChange={(e) =>
                    setBillingAddressName(e.detail.value as string)
                  }
                  readonly={!editing}
                  disabled={loading}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Street Address Line 1</IonLabel>
                <IonInput
                  type="text"
                  required
                  value={billingAddress1}
                  onIonChange={(e) =>
                    setBillingAddress1(e.detail.value as string)
                  }
                  readonly={!editing}
                  disabled={loading}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">
                  Street Address Line 2 (optional)
                </IonLabel>
                <IonInput
                  type="text"
                  value={billingAddress2}
                  onIonChange={(e) =>
                    setBillingAddress2(e.detail.value as string)
                  }
                  readonly={!editing}
                  disabled={loading}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">City</IonLabel>
                <IonInput
                  type="text"
                  required
                  value={billingCity}
                  onIonChange={(e) => setBillingCity(e.detail.value as string)}
                  readonly={!editing}
                  disabled={loading}
                />
              </IonItem>
              <IonRow>
                <IonCol size="12" sizeLg="6" className="ion-no-padding">
                  <SelectState
                    value={billingState}
                    onIonChange={(e: any) =>
                      setBillingState(e.detail.value as string)
                    }
                    disabled={!editing || loading}
                  />
                </IonCol>
                <IonCol size="12" sizeLg="6" className="ion-no-padding">
                  <IonItem>
                    <IonLabel position="floating">Zip</IonLabel>
                    <IonInput
                      type="text"
                      required
                      value={billingPostalCode}
                      onIonChange={(e) =>
                        setBillingPostalCode(e.detail.value as string)
                      }
                      readonly={!editing}
                      disabled={loading}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonCol>
      {/* <IonCol size="12" sizeLg="6">
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle className="d-flex ion-justify-content-between ion-align-items-center">
              <span>Purchasing</span>
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem>
                <IonLabel position="floating">PO Prefix</IonLabel>
                <IonInput
                  type="text"
                  readonly={!editing}
                  value={poPrefix}
                  onIonChange={(e) => setPoPrefix(e.detail.value as string)}
                  disabled={loading}
                />
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonCol> */}
    </IonRow>
  );
};

export default FormManageLocation;
