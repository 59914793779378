import { IonButton, IonIcon, useIonAlert } from '@ionic/react';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import { Page } from 'components/shared/Page';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useApi } from 'hooks/useApi';
import FormManageUser from 'components/buyer/FormManageUser';
import ButtonEdit from 'components/shared/ButtonEdit';
import { AppUser } from 'interfaces/AppUser';
import {
  checkmarkCircleOutline,
  closeCircleOutline,
  refreshOutline,
} from 'ionicons/icons';
import { useDispatch } from 'react-redux';
import { setToast } from 'redux/appReducer';

export const PageUser: React.FC = () => {
  const api = useApi();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [presentAlert] = useIonAlert();

  const [user, setUser] = useState<AppUser>();
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [roleId, setRoleId] = useState<number>();
  const [locationIds, setLocationIds] = useState<number[]>([]);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Settings', path: '/settings', direction: 'back' },
    { label: 'Users', path: '/settings/users', direction: 'back' },
    { label: user ? `${user.first_name} ${user.last_name}` : id },
  ];

  useEffect(() => {
    getUser();
  }, [id]);

  useEffect(() => {
    if (user) {
      populateData();
    }
  }, [user]);

  const getUser = () => {
    setLoading(true);
    api.get(`users/${id}`).then(({ data }) => {
      setUser(data);
      setLoading(false);
    });
  };

  const populateData = () => {
    if (user) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email_address);
      setLocationIds(user.location_ids);
    }
  };

  const revertChanges = () => {
    populateData();
    setEditing(false);
  };

  const onClickSave = () => {
    if (!user) {
      return false;
    }
    setLoading(true);
    const userData = {
      app_role_id: roleId,
      organization_id: user.organization_id,
      first_name: firstName,
      last_name: lastName,
      email_address: email,
      location_ids: locationIds,
    };
    api
      .put(`users/${user?.app_user_id}`, userData)
      .then(({ data }) => {
        const updatedUser = data[0];
        setUser(updatedUser);
        setLoading(false);
        dispatch(
          setToast({
            message: `User updated successfully`,
            icon: checkmarkCircleOutline,
          })
        );
        setEditing(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        dispatch(
          setToast({
            header: 'Error',
            message: 'There was an error updating this user',
            color: 'danger',
            icon: closeCircleOutline,
          })
        );
      });
  };

  const onUserChange = (userInfo: any) => {
    setFirstName(userInfo.first_name);
    setLastName(userInfo.last_name);
    setEmail(userInfo.email_address);
    setRoleId(userInfo.app_role_id);
  };

  const onLocationsChange = (locationIds: number[]) => {
    setLocationIds(locationIds);
  };

  const onClickDeactivate = () => {
    presentAlert({
      header: 'Deactivate the following user?',
      message: `${user?.first_name} ${user?.last_name}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Deactivate',
          role: 'confirm',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          deactivateUser();
        }
      },
    });
  };

  const deactivateUser = () => {
    setLoading(true);
    api
      .delete(`users/${user?.app_user_id}`)
      .then((response) => {
        if (response.status === 204) {
          getUser();
          setEditing(false);
          dispatch(
            setToast({
              message: `User Deactivated`,
            })
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onClickReactivate = () => {
    presentAlert({
      header: 'Reactivate the following user?',
      message: `${user?.first_name} ${user?.last_name}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Reactivate',
          role: 'confirm',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          reactivateUser();
        }
      },
    });
  };

  const reactivateUser = () => {
    setLoading(true);
    const userData = { ...user };
    userData.active = true;
    api
      .put(`users/${user?.app_user_id}`, userData)
      .then(({ data }) => {
        setLoading(false);
        setUser(data[0]);
        setEditing(false);
        dispatch(
          setToast({
            message: 'User has been reactivated successfully',
          })
        );
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const Footer = () => {
    return (
      <>
        <div slot="start">
          {user?.active ? (
            <IonButton
              color="danger"
              disabled={loading}
              onClick={onClickDeactivate}
            >
              Deactivate User
            </IonButton>
          ) : null}
        </div>
        <div slot="end">
          {!user || user?.active ? (
            <ButtonEdit
              size="default"
              disabled={!user}
              editing={editing}
              loading={loading}
              onClickEdit={() => setEditing(true)}
              onClickCancel={revertChanges}
              onClickSave={onClickSave}
            />
          ) : null}
          {user && !user.active ? (
            <IonButton
              color="primary"
              disabled={loading}
              onClick={onClickReactivate}
            >
              <IonIcon icon={refreshOutline} slot="start" />
              Reactivate User
            </IonButton>
          ) : null}
        </div>
      </>
    );
  };

  return (
    <Page title="User Settings" footer={<Footer />}>
      <Breadcrumbs breadcrumbs={breadcrumbs} title="User Detail"></Breadcrumbs>
      <FormManageUser
        user={user}
        editing={editing}
        onUserChange={onUserChange}
        onLocationsChange={onLocationsChange}
      />
    </Page>
  );
};
