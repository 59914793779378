import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonIcon,
  IonRouterLink,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonSkeletonText,
} from '@ionic/react';
import ModalAddSupplier from 'components/buyer/ModalSupplierAdd';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import LogoSupplier from 'components/shared/LogoSupplier';
import { Page } from 'components/shared/Page';
import { Supplier } from 'interfaces/Supplier';
import { addOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';

import { Account } from 'interfaces/Account';
import 'pages/buyer/PageSuppliers.css';
import { useSelector } from 'react-redux';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { isAnalyst } from 'utils';
import { SupplierTypeName } from 'types/SupplierTypeName';

export const PageSuppliers: React.FC = () => {
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.app.user
  );
  const allSuppliers: Supplier[] | null = useSelector(
    (state: any) => state.app.allSuppliers
  );
  const accounts: Account[] | null = useSelector(
    (state: any) => state.app.accounts
  );
  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Suppliers' },
  ];

  const [supplierModalOpen, setSupplierModalOpen] = useState(false);
  const [mySuppliers, setMySuppliers] = useState<Supplier[]>([]);
  const [suppliersFiltered, setSuppliersFiltered] = useState<Supplier[]>([]);

  const [filterType, setFilterType] = useState<SupplierTypeName>('All');
  const [search, setSearch] = useState<string | null>(null);

  useEffect(() => {
    if (allSuppliers && accounts) {
      setMySuppliers(
        allSuppliers.filter((supplier) =>
          accounts.find(
            (account) => account.supplier_id === supplier.supplier_id
          )
        )
      );
    }
  }, [allSuppliers, accounts]);

  useEffect(() => {
    if (mySuppliers.length === 0) {
      setSuppliersFiltered([]);
    } else {
      const filtered = mySuppliers.filter((supplier) => {
        if (
          supplier.name.toLowerCase().includes(search || '') &&
          (filterType === 'All' || filterType === supplier.supplier_type_name)
        ) {
          return true;
        }
      });
      setSuppliersFiltered(filtered);
    }
  }, [mySuppliers, filterType, search]);

  const onCloseSupplierModal = (data?: Supplier) => {
    setSupplierModalOpen(false);
  };

  const SupplierCol: React.FC<{ supplier?: Supplier }> = ({ supplier }) => {
    return (
      <IonCol size="12" sizeMd="6" sizeXl="4">
        <IonCard
          button
          routerLink={
            supplier ? `/suppliers/${supplier.supplier_id}` : undefined
          }
        >
          <IonCardHeader className="ion-text-center">
            {(supplier && <LogoSupplier src={supplier.logo} />) || (
              <IonSkeletonText
                animated={true}
                className="supplierLogo"
              ></IonSkeletonText>
            )}
            <div className="ion-text-center">
              {(supplier && (
                <IonRouterLink className="font-size-large">
                  {supplier?.name}
                </IonRouterLink>
              )) || (
                <IonSkeletonText
                  animated={true}
                  style={{
                    height: 'var(--font-size-large)',
                    width: '100px',
                    margin: '0 auto',
                  }}
                />
              )}
            </div>
          </IonCardHeader>
          <IonCardContent>
            {(supplier && (
              <p className="ion-text-center">
                {supplier.supplier_type_name === 'manufacturer'
                  ? 'Manufacturer'
                  : '503B'}
              </p>
            )) || (
              <IonSkeletonText
                animated={true}
                style={{
                  height: 'var(--font-size-default)',
                  width: '100px',
                  margin: '0 auto',
                }}
              />
            )}
          </IonCardContent>
        </IonCard>
      </IonCol>
    );
  };

  const AddSupplierButton: React.FC = () => {
    return (
      <IonButton size="small" onClick={() => setSupplierModalOpen(true)}>
        Add Supplier
        <IonIcon icon={addOutline} slot="end"></IonIcon>
      </IonButton>
    );
  };

  return (
    <Page title="Suppliers" className="suppliers">
      <Breadcrumbs breadcrumbs={breadcrumbs}>
        {authUser && !isAnalyst(authUser) ? <AddSupplierButton /> : null}
        <ModalAddSupplier
          isOpen={supplierModalOpen}
          onClose={(data?: any) => onCloseSupplierModal(data)}
          onDismiss={(data?: any) => onCloseSupplierModal(data)}
        />
      </Breadcrumbs>
      {mySuppliers?.length > 0 ? (
        <>
          <IonRow className="ion-align-items-center">
            <IonCol size="12" sizeMd="6" sizeLg="4" offsetLg="4">
              <IonSegment
                value={filterType}
                onIonChange={(e) =>
                  setFilterType(e.detail.value as SupplierTypeName)
                }
              >
                <IonSegmentButton value="All">All</IonSegmentButton>
                <IonSegmentButton value="503b">503B</IonSegmentButton>
                <IonSegmentButton value="manufacturer">
                  Manufacturer
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="4">
              <IonSearchbar
                value={search}
                onIonChange={(e: any) => setSearch(e.detail.value as string)}
                className="ion-text-left"
                placeholder="Search"
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            {suppliersFiltered.length > 0 ? (
              <>
                {suppliersFiltered.map((supplier) => {
                  return (
                    <SupplierCol
                      supplier={supplier}
                      key={supplier.supplier_id}
                    />
                  );
                })}
              </>
            ) : (
              <div className="ion-text-center">
                <p className="ion-text-center font-style-italic">
                  Nothing to display
                </p>
              </div>
            )}
          </IonRow>
        </>
      ) : (
        <div className="ion-text-center">
          <p className="ion-text-center">
            No supplier accounts found for this location.
          </p>
          <AddSupplierButton />
        </div>
      )}
    </Page>
  );
};
