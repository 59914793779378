import { useIsAuthenticated } from '@azure/msal-react';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { PageLoading } from 'pages/shared/PageLoading';
import { PageNoUser } from 'pages/shared/PageNoUser';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

const RouteAuth = ({ component: Component, ...rest }: any) => {
  const isAuthenticated = useIsAuthenticated();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.app.user
  );
  const sessionExpired: boolean | null = useSelector(
    (state: any) => state.app.sessionExpired
  );
  const userLoading: boolean = useSelector(
    (state: any) => state.app.userLoading
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && authUser) {
          return <Component {...props} />;
        } else if (
          (isAuthenticated && !userLoading && !authUser) ||
          sessionExpired === true
        ) {
          return <PageNoUser />;
        }
        return <PageLoading />;
      }}
    />
  );
};

export default RouteAuth;
