import { IonButton, IonCol, IonIcon, IonList, IonRow } from '@ionic/react';
import ButtonBackToLogin from 'components/shared/ButtonBackToLogin';
import { FormInput } from 'components/shared/FormInput';
import { chevronForwardOutline } from 'ionicons/icons';
import { Fragment, useEffect, useState } from 'react';

export const Step1Form: React.FC<{
  loading: boolean;
  firstName: string | null;
  setFirstName: (value: string) => void;
  lastName: string | null;
  setLastName: (value: string) => void;
  email: string | null;
  setEmail: (value: string) => void;
  phone: string | null;
  setPhone: (value: string) => void;
  onSubmit: () => void;
  formValid: boolean;
  setFormValid: (value: boolean) => void;
}> = ({
  loading,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  onSubmit,
  formValid,
  setFormValid,
}) => {
  /*
    TODO: WHEN ANYTHING IS CHANGED, CALCULATE WHETHER OR NOT THIS FORM IS VALID
  */
  const formInputs = [
    {
      label: 'First Name',
      name: 'firstName',
      inputType: 'text',
      type: 'text',
      required: true,
      value: firstName,
      setValue: setFirstName,
      rules: [
        {
          pattern: /^.{0,25}$/,
          message: 'First Name must not be longer than 25 characters',
        },
      ],
    },
    {
      label: 'Last Name',
      name: 'lastName',
      inputType: 'text',
      type: 'text',
      required: true,
      value: lastName,
      setValue: setLastName,
      rules: [
        {
          pattern: /^.{0,25}$/,
          message: 'Last Name must not be longer than 25 characters',
        },
      ],
    },
    {
      label: 'Email',
      name: 'email',
      inputType: 'text',
      type: 'email',
      required: true,
      value: email,
      setValue: setEmail,
      rules: [
        {
          pattern:
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
          message: 'Please enter a valid email address',
        },
      ],
    },
    {
      label: 'Phone (optional)',
      name: 'phone',
      inputType: 'text',
      type: 'tel',
      required: false,
      value: phone,
      setValue: setPhone,
      rules: [
        {
          pattern: /^(\d{10}|\d{11})$/,
          message: 'Please enter a 10-digit or 11-digit phone number',
        },
      ],
    },
  ];

  useEffect(() => {
    let valid = true;
    formInputs.forEach((input) => {
      if (input.required) {
        // if input is required, we much check the pattern.
        if (!input.value) {
          valid = false;
        } else {
          input.rules.forEach((rule) => {
            if (!rule.pattern.test(`${input.value}`)) {
              valid = false;
            }
          });
        }
      } else {
        // if input is not required, we only check the pattern if it has a value
        if (input.value) {
          input.rules.forEach((rule) => {
            if (!rule.pattern.test(`${input.value}`)) {
              valid = false;
            }
          });
        }
      }
    });
    setFormValid(valid);
  }, [firstName, lastName, email, phone]);

  return (
    <>
      <IonRow>
        <IonCol>
          <IonList>
            {formInputs.map((input, index) => (
              <Fragment key={index}>
                <FormInput
                  disabled={loading}
                  label={input.label}
                  name={input.name}
                  inputType={input.inputType as 'text' | 'state'}
                  type={input.type as 'text' | 'email' | 'password' | 'tel'}
                  required={input.required}
                  value={input.value}
                  setValue={input.setValue}
                  rules={input.rules}
                />
              </Fragment>
            ))}
          </IonList>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-between ion-margin-top">
        <ButtonBackToLogin disabled={loading} />
        <IonButton type="submit" onClick={onSubmit} disabled={!formValid}>
          Next
          <IonIcon slot="end" icon={chevronForwardOutline} />
        </IonButton>
      </IonRow>
    </>
  );
};
