import { IonBreadcrumb, IonBreadcrumbs, IonCol, IonRow } from '@ionic/react';

import 'components/shared/Breadcrumbs.css';

export interface Breadcrumb {
  label: string;
  path?: string;
  direction?: 'back' | 'forward' | 'root' | 'none';
}

const Breadcrumbs: React.FC<{
  breadcrumbs: Array<Breadcrumb>;
  title?: string;
  children?: any;
}> = ({ breadcrumbs, title, children }) => {
  return (
    <IonRow className="breadcrumbs">
      <IonCol size="12">
        <div className="breadcrumbTitleContainer">
          <h3>
            {(title && title) || breadcrumbs[breadcrumbs.length - 1].label}
          </h3>
          <div className="ion-text-right breadcrumbActions">{children}</div>
        </div>
        {breadcrumbs.length > 1 && (
          <IonBreadcrumbs>
            {breadcrumbs.map((breadcrumb) => {
              return (
                <IonBreadcrumb
                  key={breadcrumb.label}
                  routerLink={breadcrumb.path}
                  routerDirection={
                    breadcrumb.direction ? breadcrumb.direction : 'root'
                  }
                >
                  {breadcrumb.label}
                </IonBreadcrumb>
              );
            })}
          </IonBreadcrumbs>
        )}
      </IonCol>
    </IonRow>
  );
};

export default Breadcrumbs;
