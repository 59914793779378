import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonRouterLink,
  IonSegment,
  IonSegmentButton,
  useIonViewWillEnter,
} from '@ionic/react';
import ButtonFilter from 'components/shared/ButtonFilter';
import DataTable, {
  DataTableHeader,
  DataTableItemAction,
  DataTablePagination,
  DataTableRow,
} from 'components/shared/DataTable';
import LogoSupplier from 'components/shared/LogoSupplier';
import { useApi } from 'hooks/useApi';
import { Organization } from 'interfaces/Organization';
import { pencilOutline, peopleOutline } from 'ionicons/icons';
import { Supplier } from 'interfaces/Supplier';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ModalSettingSupplier from 'components/buyer/ModalSettingSupplier';

const TableSuppliers: React.FC = () => {
  const filterButtonId = Math.floor(Math.random() * 10000000000).toString(16);
  const api = useApi();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const [loading, setLoading] = useState(false);
  const [allSuppliers, setAllSuppliers] = useState<Supplier[]>([]);
  const [pagination, setPagination] = useState<DataTablePagination>();
  const [orderBy, setOrderBy] = useState<string | null>('name');
  const [orderByDesc, setOrderByDesc] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filterType, setFilterType] = useState<'b503' | 'manufacturer' | null>(
    null
  );

  const [isOpen, setIsOpen] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier>();
  const headers: DataTableHeader[] = [
    { text: 'Name', key: 'logo', width: '1%' },
    { text: '', key: 'name' },
    { text: 'Type', key: 'type' },
    { text: 'Setup/Not Setup', key: 'status', align: 'center' },
  ];

  useIonViewWillEnter(() => {
    getAllSuppliers();
  }, []);

  useEffect(() => {
    getAllSuppliers();
  }, [organization, filterType]);

  useEffect(() => {
    if (selectedSupplier) {
      setIsOpen(true);
    }
  }, [selectedSupplier]);

  const getAllSuppliers = () => {
    if (!organization) {
      return;
    }
    setLoading(true);
    api
      .get(`organizations/${organization.organization_id}/suppliers`, {
        limit: pagination?.limit,
        offset: pagination?.offset,
        type: filterType || null,
      })
      .then((response) => {
        if (response.status === 200) {
          setAllSuppliers(response.data || []);
          setTotalCount(response.data?.length);
        }
        setLoading(false);
      });
  };

  const actions = (supplier: Supplier): DataTableItemAction[] => {
    return [
      {
        label: 'Manage Supplier',
        icon: <IonIcon icon={pencilOutline} />,
        routerLink: (supplier: Supplier) => {
          return `/settings/suppliers/${supplier.supplier_id}`;
        },
        callback: (supplier: Supplier) => null,
      },
    ];
  };

  const onClickManageAccounts = (supplier: Supplier) => {
    setSelectedSupplier(supplier);
  };

  const onDidDismiss = (data?: any) => {
    setSelectedSupplier(undefined);
    setIsOpen(false);
  };

  const clearFilters = () => {
    setFilterType(null);
  };

  const ButtonLocationStatus: React.FC<{ supplier: Supplier }> = ({
    supplier,
  }) => {
    return (
      <IonButton
        size="small"
        fill="clear"
        color="primary"
        onClick={() => onClickManageAccounts(supplier)}
      >
        {`${supplier.location_count_setup}/${supplier.location_count_total} Locations`}
      </IonButton>
    );
  };

  return (
    <>
      <DataTable
        title="Suppliers"
        headers={headers}
        onPaginationChange={(pagination: DataTablePagination) =>
          setPagination(pagination)
        }
        orderBy={orderBy}
        orderByDesc={orderByDesc}
        setOrderBy={setOrderBy}
        setOrderByDesc={setOrderByDesc}
        itemsPerPage={15}
        totalCount={totalCount}
        rows={allSuppliers.map((supplier): DataTableRow => {
          const row: DataTableRow = {
            item: supplier,
            key: supplier.supplier_id,
            columns: [
              {
                header: 'logo',
                content: (
                  <LogoSupplier
                    src={supplier.logo}
                    style={{ width: '125px' }}
                    routerLink={`/settings/suppliers/${supplier.supplier_id}`}
                    className="ion-margin-end"
                  />
                ),
              },
              {
                header: 'name',
                content: (
                  <IonRouterLink
                    className="font-size-large"
                    routerLink={`/settings/suppliers/${supplier.supplier_id}`}
                  >
                    {supplier.name}
                  </IonRouterLink>
                ),
              },
              {
                header: 'type',
                content: <p>{supplier.supplier_type_name}</p>,
              },
              {
                header: 'status',
                content: <ButtonLocationStatus supplier={supplier} />,
              },
            ],
          };
          return row;
        })}
        actions={actions}
        loading={loading}
      >
        {filterType && (
          <IonButton fill="clear" size="small" onClick={clearFilters}>
            Clear Filters
          </IonButton>
        )}
        <ButtonFilter id={filterButtonId} />
        <IonPopover
          id="settingSuppliersFilterPopover"
          trigger={filterButtonId}
          triggerAction="click"
        >
          <IonContent>
            <IonList lines="none">
              <IonItem
                button
                detail={false}
                style={{ marginBottom: 'var(--app-spacing-small)' }}
              >
                <IonLabel position="stacked">Type</IonLabel>
                <IonSegment
                  value={filterType}
                  onIonChange={(e) =>
                    setFilterType(e.detail.value as 'b503' | 'manufacturer')
                  }
                >
                  <IonSegmentButton value="503b">503B</IonSegmentButton>
                  <IonSegmentButton value="manufacturer">
                    Manufacturer
                  </IonSegmentButton>
                </IonSegment>
              </IonItem>
            </IonList>
          </IonContent>
        </IonPopover>
      </DataTable>

      <ModalSettingSupplier
        isOpen={isOpen}
        supplier={selectedSupplier}
        onDismiss={(data?: any) => onDidDismiss(data)}
      />
    </>
  );
};

export default TableSuppliers;
