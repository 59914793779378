import { IonSpinner } from '@ionic/react';

const LoadingInline: React.FC<{
  large?: any | boolean;
  color?: 'light' | 'primary';
}> = ({ large, color = 'primary' }) => {
  const style = {
    width: '',
    height: '',
  };

  if (large !== undefined) {
    style.width = style.height = '50px';
  }

  return (
    <div style={{ width: '100%' }} className="ion-text-center">
      <IonSpinner name="crescent" color={color} style={style} />
    </div>
  );
};

export default LoadingInline;
