import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSpinner,
  IonText,
  useIonRouter,
} from '@ionic/react';
import { FormInput } from 'components/shared/FormInput';
import ModalTerms from 'components/shared/ModalTerms';
import { chevronBackOutline } from 'ionicons/icons';
import { Fragment, useEffect, useState } from 'react';

export const Step2Form: React.FC<{
  loading: boolean;
  slidePrev: () => void;
  organizationName: string | null;
  setOrganizationName: (value: string) => void;
  locationName: string | null;
  setLocationName: (value: string) => void;
  addressLine1: string | null;
  setAddressLine1: (value: string) => void;
  addressLine2: string | null;
  setAddressLine2: (value: string) => void;
  city: string | null;
  setCity: (value: string) => void;
  state: string | null;
  setState: (value: string) => void;
  zip: string | null;
  setZip: (value: string) => void;
  termsAccepted: boolean;
  setTermsAccepted: (value: boolean) => void;
  onSubmit: () => void;
  formValid: boolean;
  setFormValid: (value: boolean) => void;
}> = ({
  loading,
  slidePrev,
  organizationName,
  setOrganizationName,
  locationName,
  setLocationName,
  addressLine1,
  setAddressLine1,
  addressLine2,
  setAddressLine2,
  city,
  setCity,
  state,
  setState,
  zip,
  setZip,
  termsAccepted,
  setTermsAccepted,
  onSubmit,
  formValid,
  setFormValid,
}) => {
  /*
    TODO: WHEN ANYTHING IS CHANGED, CALCULATE WHETHER OR NOT THIS FORM IS VALID
  */
  const router = useIonRouter();
  const [termsOpen, setTermsOpen] = useState(false);

  const formInputs = [
    {
      label: 'Organization Name',
      name: 'organizationName',
      inputType: 'text',
      type: 'text',
      required: true,
      value: organizationName,
      setValue: setOrganizationName,
      rules: [
        {
          pattern: /^.{0,50}$/,
          message: 'Organization Name must be 50 characters or less',
        },
      ],
    },
    {
      label: 'Location (Facility) Name',
      name: 'locationName',
      inputType: 'text',
      type: 'text',
      required: true,
      value: locationName,
      setValue: setLocationName,
      rules: [
        {
          pattern: /^.{0,100}$/,
          message: 'Location Name is too long',
        },
      ],
    },
    {
      label: 'Street Address',
      name: 'address1',
      inputType: 'text',
      type: 'text',
      required: true,
      value: addressLine1,
      setValue: setAddressLine1,
      rules: [
        {
          pattern: /^.{0,100}$/,
          message: 'Street Address is too long',
        },
      ],
    },
    {
      label: 'Suite, Unit, etc.',
      name: 'address2',
      inputType: 'text',
      type: 'text',
      required: false,
      value: addressLine2,
      setValue: setAddressLine2,
      rules: [
        {
          pattern: /^.{0,100}$/,
          message: 'Suite is too long',
        },
      ],
    },
    {
      label: 'City',
      name: 'city',
      inputType: 'text',
      type: 'text',
      required: true,
      value: city,
      setValue: setCity,
      rules: [
        {
          pattern: /^.{0,100}$/,
          message: 'City name is too long',
        },
      ],
    },
    {
      label: 'State',
      name: 'state',
      inputType: 'state',
      type: 'text',
      required: true,
      value: state,
      setValue: setState,
      rules: [],
    },
    {
      label: 'Zip',
      name: 'zip',
      inputType: 'text',
      type: 'text',
      required: true,
      value: zip,
      setValue: setZip,
      rules: [
        {
          pattern: /^.{0,12}$/,
          message: '',
        },
      ],
    },
  ];

  useEffect(() => {
    let valid = true;
    formInputs.forEach((input) => {
      if (input.required) {
        // if input is required, we much check the pattern.
        if (!input.value) {
          valid = false;
        } else {
          input.rules.forEach((rule) => {
            if (!rule.pattern.test(`${input.value}`)) {
              valid = false;
              console.log('invalid', input.name, input.value);
            }
          });
        }
      } else {
        // if input is not required, we only check the pattern if it has a value
        if (input.value) {
          input.rules.forEach((rule) => {
            if (!rule.pattern.test(`${input.value}`)) {
              valid = false;
            }
          });
        }
      }
    });
    if (!termsAccepted) {
      valid = false;
    }
    setFormValid(valid);
  }, [
    organizationName,
    locationName,
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
    termsAccepted,
  ]);

  return (
    <>
      <IonRow>
        <IonCol>
          <IonList>
            {formInputs.map((input, index) => (
              <Fragment key={index}>
                <FormInput
                  disabled={loading}
                  label={input.label}
                  name={input.name}
                  inputType={input.inputType as 'text' | 'state'}
                  type={input.type as 'text' | 'email' | 'password' | 'tel'}
                  required={input.required}
                  value={input.value}
                  setValue={input.setValue}
                  rules={input.rules}
                />
              </Fragment>
            ))}
            <IonItem lines="none" disabled={loading}>
              <IonCheckbox
                checked={termsAccepted}
                onIonChange={(e) =>
                  setTermsAccepted(e.detail.checked as boolean)
                }
              />
              <IonLabel className="fullWidth ion-text-wrap">
                By creating a Trulla Direct account, I understand and agree to
                Trulla's{' '}
                <IonText color="primary">
                  <a
                    onClick={() => setTermsOpen(true)}
                    style={{ cursor: 'pointer' }}
                  >
                    Terms of Use.
                  </a>
                </IonText>
              </IonLabel>
            </IonItem>
          </IonList>
        </IonCol>
      </IonRow>

      <IonRow className="ion-justify-content-between ion-margin-top">
        <IonButton fill="clear" onClick={() => slidePrev()} disabled={loading}>
          <IonIcon slot="start" icon={chevronBackOutline} />
          Previous
        </IonButton>
        {/* TODO: disable based on validity of form */}
        <IonButton disabled={loading || !formValid} onClick={onSubmit}>
          Submit
          {loading && (
            <IonSpinner
              slot="end"
              name="crescent"
              style={{ marginLeft: '5px' }}
            />
          )}
        </IonButton>
      </IonRow>
      <ModalTerms isOpen={termsOpen} onDismiss={() => setTermsOpen(false)} />
    </>
  );
};
