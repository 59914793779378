import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Toast } from 'components/shared/ToastManager';
import { Account } from 'interfaces/Account';
import { AccountType } from 'interfaces/AccountType';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { Location } from 'interfaces/Location';
import { MsalAccessToken } from 'interfaces/MsalAccessToken';
import { Organization } from 'interfaces/Organization';
import { ShoppingCart } from 'interfaces/ShoppingCart';
import { ShoppingCartItem } from 'interfaces/ShoppingCartItem';
import { Supplier } from 'interfaces/Supplier';

interface InitialState {
  user: AppUserSelf | null;
  userLoading: boolean;
  accessToken: MsalAccessToken | null;
  onboardApiAccessToken: MsalAccessToken | null;
  sessionExpired: boolean | null;
  accounts: Account[] | null;
  accountTypes: AccountType[] | null;
  carts: ShoppingCart[];
  cartItems: ShoppingCartItem[];
  selectedLocation: Location | null;
  medicationSearch: string | null;
  organization: Organization | null;
  supplier: Supplier | null;
  allSuppliers: Supplier[] | null;
  toast: Toast | null;
}

const initialState: InitialState = {
  user: null,
  userLoading: true,
  accessToken: null,
  onboardApiAccessToken: null,
  sessionExpired: null,
  accounts: null,
  accountTypes: null,
  carts: [],
  cartItems: [],
  selectedLocation: null,
  medicationSearch: null,
  organization: null,
  supplier: null,
  allSuppliers: null,
  toast: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    // user
    setUserLoading: (state, action: PayloadAction<boolean>) => {
      state.userLoading = action.payload;
    },
    setUser: (state, action: PayloadAction<AppUserSelf | null>) => {
      state.user = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<MsalAccessToken | null>) => {
      state.accessToken = action.payload;
    },
    setOnboardApiAccessToken: (
      state,
      action: PayloadAction<MsalAccessToken | null>
    ) => {
      state.onboardApiAccessToken = action.payload;
    },
    setSessionExpired: (state, action: PayloadAction<boolean | null>) => {
      state.sessionExpired = action.payload;
    },
    logout: (state) => {
      localStorage.removeItem('td-token');
      localStorage.removeItem('lastActive');
      state.user = null;
      state.sessionExpired = null;
      state.accounts = null;
      state.organization = null;
      state.supplier = null;
    },
    // accounts
    setAccounts: (state, action: PayloadAction<Account[] | null>) => {
      state.accounts = action.payload;
    },
    setAccountTypes: (state, action: PayloadAction<AccountType[] | null>) => {
      state.accountTypes = action.payload;
    },
    // cart
    setCarts: (state, action: PayloadAction<ShoppingCart[]>) => {
      state.carts = action.payload;
    },
    setCartItems: (state, action: PayloadAction<ShoppingCartItem[]>) => {
      state.cartItems = action.payload;
    },
    // selectedLocation
    setLocation: (state, action: PayloadAction<Location | null>) => {
      state.selectedLocation = action.payload;
      if (action.payload?.location_id) {
        localStorage.setItem(
          'td-location-id',
          JSON.stringify(action.payload.location_id)
        );
      } else {
        localStorage.removeItem('td-location-id');
      }
    },
    // medicationSearch
    setMedicationSearch: (state, action: PayloadAction<string | null>) => {
      state.medicationSearch = action.payload;
    },
    // organization
    setOrganization: (state, action: PayloadAction<Organization | null>) => {
      state.organization = action.payload;
    },
    // suppliers
    setAllSuppliers: (state, action: PayloadAction<Supplier[] | null>) => {
      state.allSuppliers = action.payload;
    },
    setSupplier: (state, action: PayloadAction<Supplier | null>) => {
      state.supplier = action.payload;
    },
    // toast messages
    setToast: (state, action: PayloadAction<Toast | null>) => {
      state.toast = action.payload;
    },
  },
});

export const appReducer = appSlice.reducer;
export const {
  setUser,
  setUserLoading,
  setAccessToken,
  setOnboardApiAccessToken,
  setSessionExpired,
  logout,
  setAccounts,
  setAccountTypes,
  setCarts,
  setCartItems,
  setLocation,
  setMedicationSearch,
  setOrganization,
  setAllSuppliers,
  setSupplier,
  setToast,
} = appSlice.actions;
