import { IonCard, IonCol, IonRow } from '@ionic/react';
import TableSuppliers from 'components/buyer/TableSuppliers';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import { Page } from 'components/shared/Page';

import { Supplier } from 'interfaces/Supplier';
import 'pages/buyer/PageSettingSuppliers.css';

export const PageSettingSuppliers: React.FC = () => {
  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Settings', path: '/settings', direction: 'back' },
    { label: 'Suppliers' },
  ];

  return (
    <Page title="Supplier Settings">
      <Breadcrumbs breadcrumbs={breadcrumbs} title="Supplier Settings" />
      <IonRow>
        <IonCol>
          <IonCard>
            <TableSuppliers />
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
