import { faHospital } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonRippleEffect } from '@ionic/react';
import UserAvatar from 'components/shared/UserAvatar';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { Location } from 'interfaces/Location';
import { Organization } from 'interfaces/Organization';
import { useSelector } from 'react-redux';
import { isSupplierOrganization } from 'utils';

const UserInfo: React.FC = () => {
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.app.user
  );
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );

  return (
    <div id="userInfo" slot="end" className="ion-activatable ripple-parent">
      <div className="label">
        <p>
          {authUser?.first_name} {authUser?.last_name}
        </p>
        {isSupplierOrganization(organization) ? (
          <p>{organization?.name}</p>
        ) : (
          <>
            {selectedLocation?.name ? (
              <p>
                <FontAwesomeIcon
                  icon={faHospital}
                  className="margin-right-xs"
                />
                {selectedLocation?.name}
              </p>
            ) : null}
          </>
        )}
      </div>
      {authUser?.app_user_id && <UserAvatar user={authUser} />}
      <IonRippleEffect type="bounded"></IonRippleEffect>
    </div>
  );
};

export default UserInfo;
