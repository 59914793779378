import { IonSelect, IonSelectOption } from '@ionic/react';
import { useApi } from 'hooks/useApi';
import { LocationType } from 'interfaces/LocationType';
import { useEffect, useState } from 'react';

const SelectLocationType: React.FC<{
  value: number | null;
  setValue: (value: number) => void;
  disabled?: boolean;
}> = ({ value, setValue, disabled = false }) => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [locationTypes, setLocationTypes] = useState<LocationType[]>([]);

  useEffect(() => {
    getLocationTypes();
  }, []);

  const getLocationTypes = () => {
    setLoading(true);
    api
      .get('odata/location_types')
      .then(({ data }) => {
        setLocationTypes(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <IonSelect
      value={value}
      onIonChange={(e) => setValue(e.detail.value as number)}
      interface="popover"
      interfaceOptions={{
        alignment: 'end',
        showBackdrop: true,
        dismissOnSelect: true,
      }}
      disabled={disabled || loading}
    >
      {locationTypes && locationTypes.map((locationType: LocationType) => {
        return (
          <IonSelectOption
            key={locationType.location_type_id}
            value={locationType.location_type_id}
          >
            {locationType.name}
          </IonSelectOption>
        );
      })}
    </IonSelect>
  );
};

export default SelectLocationType;
