import { IonButtons, IonHeader, IonMenuButton, IonToolbar } from '@ionic/react';
import UserInfo from 'components/shared/UserInfo';
import UserInfoPopover from 'components/shared/UserInfoPopover';

const HeaderSupplier: React.FC = () => {
  return (
    <IonHeader id="appHeader">
      <IonToolbar id="desktopToolbar" color="secondary">
        <IonButtons slot="start">
          <IonMenuButton></IonMenuButton>
        </IonButtons>
        <UserInfo />
        <UserInfoPopover />
      </IonToolbar>
    </IonHeader>
  );
};
export default HeaderSupplier;
