import {
  IonButton,
  IonIcon,
  useIonToast,
  useIonViewWillEnter,
} from '@ionic/react';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import { peopleOutline } from 'ionicons/icons';
import { Page } from 'components/shared/Page';
import { useEffect, useState } from 'react';

import { faBoxesStacked } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import { useApi } from 'hooks/useApi';
import FormAddEditLocation from 'components/buyer/FormManageLocation';
import ModalLocationUsers from 'components/buyer/ModalLocationUsers';
import ButtonEdit from 'components/shared/ButtonEdit';
import { Location } from 'interfaces/Location';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { setToast } from 'redux/appReducer';

export const PageLocation: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const api = useApi();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [location, setLocation] = useState<Location>();
  const [userIds, setUserIds] = useState<number[]>([]);

  const [name, setName] = useState<string | null>(null);
  const [locationTypeId, setLocationTypeId] = useState<number | null>(null);
  const [location340bTypeId, setLocation340bTypeId] = useState<number | null>(
    null
  );
  const [facilityId, setFacilityId] = useState<string | null>(null);
  const [departmentId, setDepartmentId] = useState<string | null>(null);
  const [license, setLicense] = useState<string | null>(null);
  const [licenseExpiration, setLicenseExpiration] = useState<string | null>(
    null
  );
  const [deaNumber, setDeaNumber] = useState<string | null>(null);
  const [deaExpiration, setDeaExpiration] = useState<string | null>(null);
  // const [poPrefix, setPoPrefix] = useState<string | null>(null);

  const [addressName, setAddressName] = useState<string | null>(null);
  const [address1, setAddress1] = useState<string | null>(null);
  const [address2, setAddress2] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [state, setState] = useState<string | null>(null);
  const [postalCode, setPostalCode] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);

  const [differentBillingAddress, setDifferentBillingAddress] = useState(false);

  const [billingAddressName, setBillingAddressName] = useState<string | null>(
    null
  );
  const [billingAddress1, setBillingAddress1] = useState<string | null>(null);
  const [billingAddress2, setBillingAddress2] = useState<string | null>(null);
  const [billingCity, setBillingCity] = useState<string | null>(null);
  const [billingState, setBillingState] = useState<string | null>(null);
  const [billingPostalCode, setBillingPostalCode] = useState<string | null>(
    null
  );
  const [billingCountry, setBillingCountry] = useState<string | null>(null);

  const [present] = useIonToast();

  const presentToast = (
    message: string,
    color: 'primary' | 'secondary' | 'success' | 'danger' | 'warning',
    duration: number = 1500
  ) => {
    present({
      message,
      color,
      duration,
      position: 'bottom',
    });
  };

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Settings', path: '/settings', direction: 'back' },
    { label: 'Locations', path: '/settings/locations', direction: 'back' },
    { label: location && location.name ? location.name : id },
  ];

  useIonViewWillEnter(() => {
    getLocation();
  }, [id]);
  useEffect(() => {
    populateData();
  }, [location]);

  const populateData = () => {
    if (!location) {
      return;
    }
    setUserIds(location.app_user_ids);
    setName(location.name);
    setLocationTypeId(location.location_type_id);
    setLocation340bTypeId(location.location_340b_type_id);
    setFacilityId(location.facility_id);
    setDepartmentId(location.department_id);
    setLicense(location.license_number);
    setLicenseExpiration(location.license_expiration);
    setDeaNumber(location.dea_number);
    setDeaExpiration(location.dea_expiration);
    // setPoPrefix(location.po_prefix);
    setAddressName(location.address_name);
    setAddress1(location.address1);
    setAddress2(location.address2);
    setCity(location.city);
    setState(location.state);
    setPostalCode(location.postal_code);
    setCountry(location.country);
    setBillingAddressName(location.billing_address_name);
    setBillingAddress1(location.billing_address1);
    setBillingAddress2(location.billing_address2);
    setBillingCity(location.billing_city);
    setBillingState(location.billing_state);
    setBillingPostalCode(location.billing_postal_code);
    setBillingCountry(location.billing_country);
    setDifferentBillingAddress(location.different_billing_address)
  };

  const getLocation = () => {
    setLoading(true);
    api
      .get(`locations/${id}`)
      .then(({ data }) => {
        setLocation(data);
        setLoading(false);
      })
      .catch((error) => {
        presentToast(
          `Error ${error.response.status}. Unable to get this Location`,
          'danger',
          3000
        );
        setLoading(false);
      });
  };

  const onClickCancel = () => {
    populateData();
    setEditing(false);
  };

  const onClickSave = () => {
    if (!location) {
      return;
    }

    setLoading(true);
    const formData = {
      organization_id: location.organization_id,
      name: name,
      description: location.description,
      location_type_id: locationTypeId,
      location_340b_type_id: location340bTypeId,
      facility_id: facilityId,
      department_id: departmentId,
      license_number: license,
      license_expiration: licenseExpiration,
      dea_number: deaNumber,
      dea_expiration: deaExpiration,
      // po_prefix: poPrefix,
      address_id: location.address_id,
      address_name: addressName,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      postal_code: postalCode,
      country: country,
      billing_address_id: location.billing_address_id,
      billing_address_name: billingAddressName,
      billing_address1: billingAddress1,
      billing_address2: billingAddress2,
      billing_city: billingCity,
      billing_state: billingState,
      billing_postal_code: billingPostalCode,
      billing_country: billingCountry,
      app_user_ids: userIds,
      active: location.active,
      different_billing_address : differentBillingAddress
    };
    api
      .put(`locations/${location.location_id}`, formData)
      .then(({ data }) => {
        if (data[0] && data[0].location_id) {
          setLocation(data[0]);
          dispatch(
            setToast({
              message: 'Successfully updated',
            })
          );
          setEditing(false);
        } else {
          dispatch(
            setToast({
              header: 'Error',
              message: 'There was a problem updating this location',
            })
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const manageUsers = () => {
    console.log('manage users');
  };

  const onWillDismissUsersModal = (ev: CustomEvent<OverlayEventDetail>) => {
    if (ev.detail.role === 'confirm') {
      // Only set user ids if the user doesn't hit cancel.
      setUserIds(ev.detail.data);
    }
  };

  const Footer = () => {
    return (
      <div slot="end">
        <ButtonEdit
          size="default"
          editing={editing}
          loading={loading}
          onClickCancel={onClickCancel}
          onClickEdit={() => setEditing(true)}
          onClickSave={() => onClickSave()}
        />
      </div>
    );
  };

  return (
    <Page title="Location Detail" footer={<Footer />}>
      <Breadcrumbs breadcrumbs={breadcrumbs} title="Location Detail">
        <IonButton
          size="small"
          id="manage-users-btn"
          disabled={loading || !location}
        >
          Manage Users
          <IonIcon slot="end" icon={peopleOutline} />
        </IonButton>
        {location ? (
          <ModalLocationUsers
            trigger="manage-users-btn"
            location={location}
            locationUserIds={userIds}
            editing={editing}
            setEditing={setEditing}
            onWillDismiss={onWillDismissUsersModal}
          />
        ) : null}
      </Breadcrumbs>
      <FormAddEditLocation
        location={location}
        loading={loading}
        editing={editing}
        name={name}
        locationTypeId={locationTypeId}
        location340bTypeId={location340bTypeId}
        facilityId={facilityId}
        departmentId={departmentId}
        license={license}
        licenseExpiration={licenseExpiration}
        deaNumber={deaNumber}
        deaExpiration={deaExpiration}
        // poPrefix={poPrefix}
        addressName={addressName}
        address1={address1}
        address2={address2}
        city={city}
        state={state}
        postalCode={postalCode}
        country={country}
        billingAddressName={billingAddressName}
        billingAddress1={billingAddress1}
        billingAddress2={billingAddress2}
        billingCity={billingCity}
        billingState={billingState}
        billingPostalCode={billingPostalCode}
        billingCountry={billingCountry}
        differentBillingAddress= {differentBillingAddress}
        setName={setName}
        setLocationTypeId={setLocationTypeId}
        setLocation340bTypeId={setLocation340bTypeId}
        setFacilityId={setFacilityId}
        setDepartmentId={setDepartmentId}
        setLicense={setLicense}
        setLicenseExpiration={setLicenseExpiration}
        setDeaNumber={setDeaNumber}
        setDeaExpiration={setDeaExpiration}
        // setPoPrefix={setPoPrefix}
        setAddressName={setAddressName}
        setAddress1={setAddress1}
        setAddress2={setAddress2}
        setCity={setCity}
        setState={setState}
        setPostalCode={setPostalCode}
        setCountry={setCountry}
        setBillingAddressName={setBillingAddressName}
        setBillingAddress1={setBillingAddress1}
        setBillingAddress2={setBillingAddress2}
        setBillingCity={setBillingCity}
        setBillingState={setBillingState}
        setBillingPostalCode={setBillingPostalCode}
        setBillingCountry={setBillingCountry}
        setDifferentBillingAddress={setDifferentBillingAddress}
      />
    </Page>
  );
};
