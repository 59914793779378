import { IonButton, useIonRouter } from '@ionic/react';
import FormManageLocation from 'components/buyer/FormManageLocation';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import { Page } from 'components/shared/Page';
import { useApi } from 'hooks/useApi';
import { Organization } from 'interfaces/Organization';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from 'redux/appReducer';

export const PageLocationAdd: React.FC = () => {
  const api = useApi();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const router = useIonRouter();

  const [name, setName] = useState<string | null>(null);
  const [locationTypeId, setLocationTypeId] = useState<number | null>(null);
  const [location340bTypeId, setLocation340bTypeId] = useState<number | null>(
    null
  );
  const [facilityId, setFacilityId] = useState<string | null>(null);
  const [departmentId, setDepartmentId] = useState<string | null>(null);
  const [license, setLicense] = useState<string | null>(null);
  const [licenseExpiration, setLicenseExpiration] = useState<string | null>(
    null
  );
  const [deaNumber, setDeaNumber] = useState<string | null>(null);
  const [deaExpiration, setDeaExpiration] = useState<string | null>(null);
  // const [poPrefix, setPoPrefix] = useState<string | null>(null);

  const [addressName, setAddressName] = useState<string | null>(null);
  const [address1, setAddress1] = useState<string | null>(null);
  const [address2, setAddress2] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [state, setState] = useState<string | null>(null);
  const [postalCode, setPostalCode] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);

  const [differentBillingAddress, setDifferentBillingAddress] = useState(false);

  const [billingAddressName, setBillingAddressName] = useState<string | null>(
    null
  );
  const [billingAddress1, setBillingAddress1] = useState<string | null>(null);
  const [billingAddress2, setBillingAddress2] = useState<string | null>(null);
  const [billingCity, setBillingCity] = useState<string | null>(null);
  const [billingState, setBillingState] = useState<string | null>(null);
  const [billingPostalCode, setBillingPostalCode] = useState<string | null>(
    null
  );
  const [billingCountry, setBillingCountry] = useState<string | null>(null);
  const breadcrumbs: Breadcrumb[] = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Settings', path: '/settings', direction: 'back' },
    { label: 'Locations', path: '/settings/locations', direction: 'back' },
    { label: 'Add Location' },
  ];

  const onClickCreate = () => {
    if (!organization) {
      return;
    }
    setLoading(true);
    // the backend supports mass inserts, so post requests are passed in as an array.
    const formData = [
      {
        organization_id: organization.organization_id,
        name: name,
        location_type_id: locationTypeId,
        location_340b_type_id: location340bTypeId,
        facility_id: facilityId,
        department_id: departmentId,
        license_number: license,
        license_expiration: licenseExpiration,
        dea_number: deaNumber,
        dea_expiration: deaExpiration,
        //po_prefix: null,
        address_name: addressName,
        address1: address1,
        address2: address2,
        city: city,
        state: state,
        postal_code: postalCode,
        country: country,
        billing_address_name: billingAddressName,
        billing_address1: billingAddress1,
        billing_address2: billingAddress2,
        billing_city: billingCity,
        billing_state: billingState,
        billing_postal_code: billingPostalCode,
        billing_country: billingCountry,
        different_billing_address: differentBillingAddress 
      },
    ];
    api
      .post(`locations`, formData[0])
      .then(({ data }) => {
        const location = data;
        setLoading(false);
        dispatch(
          setToast({
            header: 'Location created',
            message: `Successfully created location ${location.name}`,
          })
        );
        router.push('/settings/locations', 'back');
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const Footer = () => {
    return (
      <div slot="end">
        <IonButton
          color="dark"
          fill="clear"
          routerLink="/settings/locations"
          routerDirection="back"
        >
          Cancel
        </IonButton>
        <IonButton color="primary" onClick={onClickCreate}>
          Create Location
        </IonButton>
      </div>
    );
  };

  return (
    <Page title="Add Location" footer={<Footer />}>
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
      <FormManageLocation
        loading={loading}
        editing={true}
        name={name}
        locationTypeId={locationTypeId}
        location340bTypeId={location340bTypeId}
        facilityId={facilityId}
        departmentId={departmentId}
        license={license}
        licenseExpiration={licenseExpiration}
        deaNumber={deaNumber}
        deaExpiration={deaExpiration}
        // poPrefix={poPrefix}
        addressName={addressName}
        address1={address1}
        address2={address2}
        city={city}
        state={state}
        postalCode={postalCode}
        country={country}
        billingAddressName={billingAddressName}
        billingAddress1={billingAddress1}
        billingAddress2={billingAddress2}
        billingCity={billingCity}
        billingState={billingState}
        billingPostalCode={billingPostalCode}
        billingCountry={billingCountry}
        differentBillingAddress={differentBillingAddress}
        setName={setName}
        setLocationTypeId={setLocationTypeId}
        setLocation340bTypeId={setLocation340bTypeId}
        setFacilityId={setFacilityId}
        setDepartmentId={setDepartmentId}
        setLicense={setLicense}
        setLicenseExpiration={setLicenseExpiration}
        setDeaNumber={setDeaNumber}
        setDeaExpiration={setDeaExpiration}
        // setPoPrefix={setPoPrefix}
        setDifferentBillingAddress={setDifferentBillingAddress}
        setAddressName={setAddressName}
        setAddress1={setAddress1}
        setAddress2={setAddress2}
        setCity={setCity}
        setState={setState}
        setPostalCode={setPostalCode}
        setCountry={setCountry}
        setBillingAddressName={setBillingAddressName}
        setBillingAddress1={setBillingAddress1}
        setBillingAddress2={setBillingAddress2}
        setBillingCity={setBillingCity}
        setBillingState={setBillingState}
        setBillingPostalCode={setBillingPostalCode}
        setBillingCountry={setBillingCountry}
      />
    </Page>
  );
};
