import { faBoxesStacked, faCapsules } from '@fortawesome/pro-light-svg-icons';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import CardStat from 'components/shared/CardStat';
import LogoSupplier from 'components/shared/LogoSupplier';
import { cashOutline, clipboardOutline } from 'ionicons/icons';
import { Page } from 'components/shared/Page';
import { useState } from 'react';
import { localizeNumber, toUsd } from 'utils';

import ChartSupplierSales from 'components/supplier/ChartSupplierSales';
import 'pages/supplier/PageSupplierHome.css';
import { useSelector } from 'react-redux';
import TableTopItems from 'components/supplier/TableTopItems';
import TableTopCustomers from 'components/supplier/TableTopCustomers';
import { Supplier } from 'interfaces/Supplier';
import Divider from 'components/shared/Divider';

export const PageSupplierHome: React.FC = () => {
  const supplier: Supplier | null = useSelector(
    (state: any) => state.app.supplier
  );

  const [loading, setLoading] = useState(false);
  const [filterTimeFrame, setFilterTimeFrame] = useState<
    'Last 30 Days' | 'Last 12 Months' | 'All Time'
  >('Last 30 Days');

  const breadcrumbs: Array<Breadcrumb> = [{ label: 'Home' }];

  return (
    <Page title="Supplier Home" className="supplierHome">
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
      <IonRow>
        <IonCol size="12" sizeLg="8" className="ion-no-padding ion-padding-end">
          <IonRow>
            <IonCol size="12" sizeSm="6">
              <IonCard className="supplierCard d-flex ion-align-items-center ion-justify-content-center">
                <IonCardContent className="ion-text-center">
                  <LogoSupplier src={supplier?.logo} />
                </IonCardContent>
              </IonCard>
            </IonCol>

            <IonCol size="12" sizeSm="6" className="ion-no-padding">
              <IonRow>
                <IonCol size="12">
                  <IonItem lines="full" color="transparent">
                    <IonLabel>Time Frame</IonLabel>
                    <IonSelect
                      value={filterTimeFrame}
                      interface="popover"
                      onIonChange={(e) =>
                        setFilterTimeFrame(
                          e.detail.value as
                            | 'Last 30 Days'
                            | 'Last 12 Months'
                            | 'All Time'
                        )
                      }
                    >
                      <IonSelectOption value="Last 30 Days">
                        Last 30 Days
                      </IonSelectOption>
                      <IonSelectOption value="Last 12 Months">
                        Last 12 Months
                      </IonSelectOption>
                      <IonSelectOption value="All Time">
                        All Time
                      </IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
                <IonCol size="12" sizeLg="6">
                  <CardStat
                    color="primary"
                    title="Orders"
                    stat={localizeNumber(0)}
                    statTitle={filterTimeFrame}
                    icon={clipboardOutline}
                    loading={loading}
                  />
                </IonCol>
                <IonCol size="12" sizeLg="6">
                  <CardStat
                    color="success"
                    title="Sales"
                    stat={toUsd(0, 0)}
                    statTitle={filterTimeFrame}
                    icon={cashOutline}
                    loading={loading}
                  />
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-no-padding ion-padding-horizontal">
              <Divider />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonCard>
                <TableTopItems timeFrame={filterTimeFrame} />
                {/* <div className="ion-text-center">
                  <IonButton fill="clear" color="primary">
                    View All
                  </IonButton>
                </div> */}
              </IonCard>
            </IonCol>
          </IonRow>
        </IonCol>
        <IonCol size="12" sizeLg="4">
          <IonCard>
            <TableTopCustomers timeFrame={filterTimeFrame} />
            {/* <div className="ion-text-center">
              <IonButton fill="clear" color="primary">
                View All
              </IonButton>
            </div> */}
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
