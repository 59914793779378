import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from '@ionic/react';
import CardOrganizationDomains from 'components/buyer/CardOrganizationDomains';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import ButtonEdit from 'components/shared/ButtonEdit';
import CardActions from 'components/shared/CardActions';
import { Page } from 'components/shared/Page';
import { useApi } from 'hooks/useApi';
import { Organization } from 'interfaces/Organization';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOrganization, setToast } from 'redux/appReducer';

export const PageSettings: React.FC = () => {
  const api = useApi();
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);

  const [name, setName] = useState<string | null>();

  const breadcrumbs: Breadcrumb[] = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Settings', path: '/settings', direction: 'back' },
  ];

  useEffect(() => {
    if (organization) {
      setName(organization.name);
    }
  }, [organization]);

  const revertChanges = () => {
    setName(organization?.name || null);
    setEditing(false);
  };

  const onClickSave = () => {
    // TODO: validation
    updateOrganization();
  };

  const updateOrganization = () => {
    if (!organization) {
      return;
    }
    setLoading(true);
    const formData = {
      name: name,
      description: organization.description,
    };
    api
      .put(`organizations/${organization.organization_id}`, formData)
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          if (response.data[0]) {
            dispatch(setOrganization(response.data[0]));
            setEditing(false);
            dispatch(
              setToast({
                message: 'Successfully updated Organization!',
              })
            );
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Page title="Settings">
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        title="General Settings"
      ></Breadcrumbs>

      <IonRow>
        <IonCol size="12" sizeMd="6">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Organization</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonItem lines="inset">
                <IonLabel position="stacked">Organization Name</IonLabel>
                <IonInput
                  disabled={loading}
                  readonly={!editing}
                  value={name}
                  onIonChange={(e: any) => setName(e.detail.value)}
                />
              </IonItem>
            </IonCardContent>
            <CardActions>
              <ButtonEdit
                size="small"
                editing={editing}
                loading={loading}
                onClickEdit={() => setEditing(true)}
                onClickCancel={revertChanges}
                onClickSave={onClickSave}
              />
            </CardActions>
          </IonCard>
        </IonCol>
        <IonCol size="12" sizeMd="6">
          <CardOrganizationDomains />
        </IonCol>
      </IonRow>
    </Page>
  );
};
