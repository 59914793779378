import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { Organization } from 'interfaces/Organization';
import { PageLoading } from 'pages/shared/PageLoading';
import { PageNoUser } from 'pages/shared/PageNoUser';
import { PageNotFound } from 'pages/shared/PageNotFound';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import {
  isAdmin,
  isAnalyst,
  isBuyer,
  isBuyerOrganization,
  isSuperAdmin,
} from 'utils';

const RouteRoleAdmin = ({ component: Component, ...rest }: any) => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, accounts } = useMsal();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.app.user
  );
  const userLoading: boolean = useSelector(
    (state: any) => state.app.userLoading
  );
  const sessionExpired: boolean = useSelector(
    (state: any) => state.app.sessionExpired
  );
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          isAuthenticated &&
          authUser &&
          organization &&
          (isAdmin(authUser) || isSuperAdmin(authUser))
        ) {
          if (isBuyerOrganization(organization)) {
            // authenticated Buyer admin
            return <Component {...props} />;
          } else {
            // authenticated Supplier admin
            return <PageNotFound />;
          }
        } else if (
          isAuthenticated &&
          authUser &&
          organization &&
          (isAnalyst(authUser) || isBuyer(authUser))
        ) {
          return <Redirect to="/" />;
        } else if (
          (isAuthenticated && !userLoading && !authUser) ||
          sessionExpired === true
        ) {
          // http request for authUser has finished, but there is still no authUser
          return <PageNoUser />;
        } else if (
          !isAuthenticated &&
          inProgress !== InteractionStatus.Startup &&
          inProgress !== InteractionStatus.HandleRedirect &&
          accounts.length === 0
        ) {
          return <Redirect to="/login" />;
        }
        return <PageLoading />;
      }}
    />
  );
};

export default RouteRoleAdmin;
