import { IonButton, useIonRouter } from '@ionic/react';
import { useApi } from 'hooks/useApi';
import FormManageUser from 'components/buyer/FormManageUser';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { Organization } from 'interfaces/Organization';
import { Page } from 'components/shared/Page';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from 'redux/appReducer';

export const PageUserAdd: React.FC = () => {
  const api = useApi();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.app.user
  );
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const dispatch = useDispatch();
  const router = useIonRouter();

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [roleId, setRoleId] = useState<number>();
  const [locationIds, setLocationIds] = useState<number[]>([]);

  const breadcrumbs: Breadcrumb[] = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Settings', path: '/settings', direction: 'back' },
    { label: 'Users', path: '/settings/users', direction: 'back' },
    { label: 'Add User' },
  ];

  const onClickCreate = () => {
    if (!organization) {
      return;
    }
    setLoading(true);
    const userData = 
      {
        app_role_id: roleId,
        organization_id: organization.organization_id,
        first_name: firstName,
        last_name: lastName,
        email_address: email,
        location_ids: locationIds
      };
    
    api
      .post('users', userData)
      .then(({ data }) => {
        const user = data;
        setLoading(false);
        dispatch(
          setToast({
            header: 'User created',
            message: `Successfully created user ${user.first_name} ${user.last_name}`,
          })
        );
        router.push('/settings/users', 'back');
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onUserChange = (userInfo: any) => {
    setFirstName(userInfo.first_name);
    setLastName(userInfo.last_name);
    setEmail(userInfo.email_address);
    setRoleId(userInfo.app_role_id);
  };

  const onLocationsChange = (locationIds: number[]) => {
    setLocationIds(locationIds);
  };

  const Footer = () => {
    return (
      <div slot="end">
        <IonButton
          color="dark"
          fill="clear"
          routerLink="/settings/users"
          routerDirection="back"
        >
          Cancel
        </IonButton>
        <IonButton color="primary" onClick={onClickCreate}>
          Create User
        </IonButton>
      </div>
    );
  };

  return (
    <Page title="Add User" footer={<Footer />}>
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
      <FormManageUser
        editing={true}
        onUserChange={onUserChange}
        onLocationsChange={onLocationsChange}
      />
    </Page>
  );
};
