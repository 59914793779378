import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import 'components/shared/ModalContactUs.css';
import { closeOutline } from 'ionicons/icons';
import { formatPhone } from 'utils';

const ModalContactUs: React.FC<{
  isOpen: boolean;
  onDismiss: () => void;
}> = ({ isOpen, onDismiss }) => {
  return (
    <IonModal
      isOpen={isOpen}
      onIonModalWillDismiss={onDismiss}
      className="modalContactUs"
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Contact Us</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss} fill="clear">
              <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItemDivider>
            <IonLabel>Trulla Technical Support</IonLabel>
          </IonItemDivider>
          <IonItem>
            <IonLabel>Email</IonLabel>
            <p slot="end" className="ion-text-right">
              <a href={`mailto:trulla-support@spendmend.com`}>
                trulla-support@spendmend.com
              </a>
            </p>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>Phone</IonLabel>
            <p slot="end" className="ion-text-right">
              <a href={`tel:3852583688`}>{formatPhone('3852583688')}</a>
            </p>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>Support Hours</IonLabel>
            <p slot="end" className="ion-text-right">
              7:00 am to 5:00 pm MT
            </p>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>Support Days</IonLabel>
            <p slot="end" className="ion-text-right">
              Monday - Friday
            </p>
          </IonItem>
        </IonList>
        <IonList>
          <IonItem lines="none">
            <p className="font-style-italic font-size-large ion-text-center">
              For more information please see the "Help" section
            </p>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar color="transparent">
          <IonButtons slot="end">
            <IonButton onClick={onDismiss} color="primary">
              Ok
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default ModalContactUs;
