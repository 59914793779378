import RouteAuth from 'routes/RouteAuth';
import RouteGuest from 'routes/RouteGuest';
import RouteOrgBuyer from 'routes/RouteOrgBuyer';
import RouteOrgSupplier from 'routes/RouteOrgSupplier';
import RouteRoleAdmin from 'routes/RouteRoleAdmin';
import RouteRoleBuyer from 'routes/RouteRoleBuyer';
import RouteRoleSuper from 'routes/RouteRoleSuper';

import { PageAnalytics } from 'pages/buyer/PageAnalytics';
import { PageCart } from 'pages/buyer/PageCart';
import { PageHome } from 'pages/buyer/PageHome';
import { PageItems } from 'pages/buyer/PageItems';
import { PageLocation } from 'pages/buyer/PageLocation';
import { PageLocationAdd } from 'pages/buyer/PageLocationAdd';
import { PageLocations } from 'pages/buyer/PageLocations';
import { PageOrder } from 'pages/buyer/PageOrder';
import { PageOrders } from 'pages/buyer/PageOrders';
import { PageSettingSupplier } from 'pages/buyer/PageSettingSupplier';
import { PageSettingSuppliers } from 'pages/buyer/PageSettingSuppliers';
import { PageSettings } from 'pages/buyer/PageSettings';
import { PageSupplier } from 'pages/buyer/PageSupplier';
import { PageSuppliers } from 'pages/buyer/PageSuppliers';
import { PageUser } from 'pages/buyer/PageUser';
import { PageUserAdd } from 'pages/buyer/PageUserAdd';
import { PageUsers } from 'pages/buyer/PageUsers';
import { PageLogin } from 'pages/shared/PageLogin';
import { PageNoUser } from 'pages/shared/PageNoUser';
import { PageNotFound } from 'pages/shared/PageNotFound';
import { PageRegister } from 'pages/shared/PageRegister/PageRegister';
import { PageUserProfile } from 'pages/shared/PageUserProfile';
import { PageOnboardRequests } from 'pages/super/PageOnboardRequests';
import { PageSupplierAccounts } from 'pages/supplier/PageSupplierAccounts';
import { PageSupplierCatalogs } from 'pages/supplier/PageSupplierCatalogs';
import { PageSupplierDeals } from 'pages/supplier/PageSupplierDeals';
import { PageSupplierHome } from 'pages/supplier/PageSupplierHome';
import { PageSupplierSales } from 'pages/supplier/PageSupplierSales';
import { PageSupplierSettings } from 'pages/supplier/PageSupplierSettings';

import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { IonRouterOutlet } from '@ionic/react';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { Organization } from 'interfaces/Organization';
import { PageOnboardRequest } from 'pages/super/PageOnboardRequest';
import { PageSupplierUser } from 'pages/supplier/PageSupplierUser';
import { PageSupplierUsers } from 'pages/supplier/PageSupplierUsers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { isBuyerOrganization, isSupplierOrganization } from 'utils';
import { PageLoading } from 'pages/shared/PageLoading';
import { PageLanding } from 'pages/shared/PageLanding';

const Routes: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();

  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.app.user
  );
  const userLoading: boolean = useSelector(
    (state: any) => state.app.userLoading
  );
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );
  const location = useLocation();

  useEffect(() => {
    if (window.InlineManual !== undefined) {
      window.InlineManual('update');
    }
  }, [location.pathname]);

  return (
    <IonRouterOutlet animated={true}>
      <Route path="/home">
        <Redirect to="/" />
      </Route>
      {/* Home route */}
      <Route
        path="/"
        exact={true}
        render={(props) => {
          if (
            isAuthenticated &&
            authUser &&
            isSupplierOrganization(organization)
          ) {
            return <PageSupplierHome />;
          } else if (
            isAuthenticated &&
            authUser &&
            isBuyerOrganization(organization)
          ) {
            return <PageHome />;
          } else if (isAuthenticated && !userLoading && !authUser) {
            return <PageNoUser />;
          } else if (
            !isAuthenticated &&
            inProgress !== InteractionStatus.Startup &&
            inProgress !== InteractionStatus.HandleRedirect &&
            accounts.length === 0
          ) {
            // return <PageLogin />;
            return <PageLanding />;
          }
          return <PageLoading />;
        }}
      />
      <Redirect path="/login" to="/" />
      {/* <RouteGuest path="/login" component={PageLogin} exact={true} /> */}
      <RouteGuest path="/register" component={PageRegister} exact={true} />

      <RouteOrgBuyer path="/analytics" component={PageAnalytics} exact={true} />
      <RouteAuth path="/profile" component={PageUserProfile} exact={true} />
      <RouteOrgBuyer path="/orders" component={PageOrders} exact={true} />
      <RouteRoleBuyer path="/orders/cart" component={PageCart} exact={true} />
      <RouteOrgBuyer
        path="/orders/:id([0-9]+)"
        component={PageOrder}
        exact={true}
      />
      <RouteOrgBuyer path="/items" component={PageItems} exact={true} />
      <RouteOrgBuyer path="/suppliers" component={PageSuppliers} exact={true} />
      <RouteOrgBuyer
        path="/suppliers/:id([0-9]+)"
        component={PageSupplier}
        exact={true}
      />

      <RouteRoleAdmin path="/settings" component={PageSettings} exact={true} />
      <RouteRoleAdmin
        path="/settings/locations"
        component={PageLocations}
        exact={true}
      />
      <RouteRoleAdmin
        path="/settings/locations/add"
        component={PageLocationAdd}
        exact={true}
      />
      <RouteRoleAdmin
        path="/settings/locations/:id([0-9]+)"
        component={PageLocation}
        exact={true}
      />
      <RouteRoleAdmin
        path="/settings/suppliers"
        component={PageSettingSuppliers}
        exact={true}
      />
      <RouteRoleAdmin
        path="/settings/suppliers/:id([0-9]+)"
        component={PageSettingSupplier}
        exact={true}
      />
      <RouteRoleAdmin
        path="/settings/users"
        component={PageUsers}
        exact={true}
      />
      <RouteRoleAdmin
        path="/settings/users/add"
        component={PageUserAdd}
        exact={true}
      />
      <RouteRoleAdmin
        path="/settings/users/:id([0-9]+)"
        component={PageUser}
        exact={true}
      />

      <RouteRoleSuper
        path="/admin/requests"
        component={PageOnboardRequests}
        exact={true}
      />
      <RouteRoleSuper
        path="/admin/requests/:id([0-9]+)"
        component={PageOnboardRequest}
        exact={true}
      />

      <RouteOrgSupplier
        path="/supplier/sales"
        component={PageSupplierSales}
        exact={true}
      />
      <RouteOrgSupplier
        path="/supplier/accounts"
        component={PageSupplierAccounts}
        exact={true}
      />
      <RouteOrgSupplier
        path="/supplier/catalogs"
        component={PageSupplierCatalogs}
        exact={true}
      />
      {/* <RouteOrgSupplier
        path="/supplier/returns"
        component={PageSupplierReturns}
        exact={true}
      /> */}
      <RouteOrgSupplier
        path="/supplier/deals"
        component={PageSupplierDeals}
        exact={true}
      />
      <RouteOrgSupplier
        path="/supplier/settings"
        component={PageSupplierSettings}
        exact={true}
      />
      <RouteOrgSupplier
        path="/supplier/settings/users"
        component={PageSupplierUsers}
        exact={true}
      />
      <RouteOrgSupplier
        path="/supplier/settings/users/:id([0-9]+)"
        component={PageSupplierUser}
        exact={true}
      />

      {/* Catch all route */}
      <Route
        render={(props) => {
          if (!isAuthenticated && accounts.length === 0) {
            return <Redirect to="/login" />;
          }
          return <PageNotFound />;
        }}
      ></Route>
    </IonRouterOutlet>
  );
};

export default Routes;
