import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonRow,
  IonSelect,
  useIonAlert,
  useIonRouter,
  useIonViewWillEnter,
} from '@ionic/react';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import CardActions from 'components/shared/CardActions';
import { Page } from 'components/shared/Page';
import SelectState from 'components/shared/SelectState';
import Subtitle from 'components/shared/Subtitle';
import { useOnboardApi } from 'hooks/useOnboardApi';
import { OnboardRequest } from 'interfaces/OnboardRequest';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { setToast } from 'redux/appReducer';

export const PageOnboardRequest: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const onboardApi = useOnboardApi();
  const dispatch = useDispatch();
  const router = useIonRouter();

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(true);
  const [onboardRequest, setOnboardRequest] = useState<OnboardRequest>();
  const [presentAlert] = useIonAlert();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [clientId, setClientId] = useState('');
  const [comments, setComments] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [locationName, setLocationName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState(''); // not sure if address 2 will be used...
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');

  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Admin' },
    {
      label: 'Registration Requests',
      path: '/admin/requests',
      direction: 'back',
    },
    { label: 'Request Details' },
  ];

  useEffect(() => {
    getOnboardRequest();
  }, [id]);

  useEffect(() => {
    if (onboardRequest) {
      populateForm();
    }
  }, [onboardRequest]);

  const populateForm = () => {
    if (!onboardRequest) return;
    setEmail(onboardRequest.userName);
    setClientId(onboardRequest.clientId);
    setComments(onboardRequest.comments);

    setFirstName(onboardRequest.userData.UserFirstName);
    setLastName(onboardRequest.userData.UserLastName);
    setOrganizationName(onboardRequest.userData.OrganizationName);
    setLocationName(onboardRequest.userData.LocationName);
    setAddress1(onboardRequest.userData.Street);
    // setAddress2(onboardRequest.userData.); // might need to ask for this
    setCity(onboardRequest.userData.City);
    setState(onboardRequest.userData.State);
    setZip(onboardRequest.userData.Zip);
    // setPhone1(onboardRequest.phone1);
    // setPhone2(onboardRequest.phone2);
  };

  const revertChanges = () => {
    populateForm();
  };

  const getOnboardRequest = () => {
    setLoading(true);
    onboardApi
      .get(`onboardrequests/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setOnboardRequest(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onClickReject = () => {
    presentAlert({
      header: 'Reject Registration Request',
      message: `Reject request for ${onboardRequest?.userData.UserFirstName} ${onboardRequest?.userData.UserLastName}?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Reject',
          role: 'destructive',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'destructive') {
          rejectRequest();
        }
      },
    });
  };

  const rejectRequest = () => {
    setLoading(true);
    const formData = {
      OrganizationName: organizationName,
      UserFirstName: firstName,
      UserLastName: lastName,
      UserEmail: email,
      ClientId: clientId,
      LocationName: locationName,
      City: city,
      Street: address1,
      State: state,
      Zip: zip,
      Comments: comments,
      phone1: phone1,
      phone2: phone2,
    };
    onboardApi
      .put(`onboardrequests/${id}/reject`, formData)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setToast({
              message: 'Registration request rejected',
            })
          );
          router.push('/admin/requests', 'back');
        } else {
          dispatch(
            setToast({
              header: `Error ${response.status}`,
              message:
                'There was a problem rejecting this registration request.',
              color: 'danger',
            })
          );
        }
        setLoading(false);
      });
  };

  const onClickApprove = () => {
    presentAlert({
      header: 'Approve Registration Request',
      message: `Approve request for ${onboardRequest?.userData.UserFirstName} ${onboardRequest?.userData.UserLastName}?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Approve',
          role: 'confirm',
        },
      ],
      onWillDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          approveRequest();
        }
      },
    });
  };

  const approveRequest = () => {
    setLoading(true);
    const formData = {
      OrganizationName: organizationName,
      UserFirstName: firstName,
      UserLastName: lastName,
      UserEmail: email,
      ClientId: clientId,
      LocationName: locationName,
      City: city,
      Street: address1,
      State: state,
      Zip: zip,
      Comments: comments,
      phone1: phone1,
      phone2: phone2,
    };
    onboardApi
      .put(`onboardrequests/${id}/approve`, formData)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setToast({
              message: 'Registration request approved',
            })
          );
          router.push('/admin/requests', 'back');
        } else {
          dispatch(
            setToast({
              header: `Error`,
              message:
                'There was a problem approving this registration request.',
              color: 'danger',
            })
          );
        }
        setLoading(false);
      });
  };

  const Footer = () => {
    return (
      <>
        <div slot="end">
          <IonButton
            color="danger"
            className="ion-margin-end"
            disabled={loading}
            onClick={onClickReject}
          >
            Reject
            <IonIcon slot="end" icon={closeCircleOutline} />
          </IonButton>
          <IonButton
            color="primary"
            disabled={loading}
            onClick={onClickApprove}
          >
            Approve
            <IonIcon slot="end" icon={checkmarkCircleOutline} />
          </IonButton>
        </div>
      </>
    );
  };
  return (
    <Page title="Registration Requests" footer={<Footer />}>
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        title="Registration Requests"
      ></Breadcrumbs>

      <IonRow>
        <IonCol size="12" sizeLg="6" sizeXl="5">
          <IonCard className="ion-padding-bottom">
            <IonCardHeader>
              <IonCardTitle>User Info</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-no-padding">
              <IonList>
                <IonItem>
                  <IonLabel position="floating">First Name</IonLabel>
                  <IonInput
                    type="text"
                    value={firstName}
                    readonly={!editing}
                    disabled={loading}
                    onIonChange={(e) => setFirstName(e.detail.value as string)}
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Last Name</IonLabel>
                  <IonInput
                    type="text"
                    value={lastName}
                    readonly={!editing}
                    disabled={loading}
                    onIonChange={(e) => setLastName(e.detail.value as string)}
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput
                    type="email"
                    value={email}
                    readonly={!editing}
                    disabled={loading}
                    onIonChange={(e) => setEmail(e.detail.value as string)}
                  />
                  <IonNote slot="helper">
                    • TODO: CHECK IF THIS IS UNIQUE
                    <br />• TODO: CHECK TO SEE IF THIS DOMAIN BELONGS TO AN
                    ORGANIZATION
                  </IonNote>
                </IonItem>
                {/* <IonItem>
                  <IonLabel position="stacked">Select Role</IonLabel>
                  <IonSelect
                    value={roleId}
                    onIonChange={(e) => setRoleId(e.detail.value as number)}
                    interface="popover"
                    interfaceOptions={{
                      alignment: 'end',
                      showBackdrop: true,
                      dismissOnSelect: true,
                    }}
                    disabled={!editing || loading}
                  >
                    {appRoles.map((appRole: AppRole) => {
                      return (
                        <IonSelectOption
                          key={appRole.app_role_id}
                          value={appRole.app_role_id}
                        >
                          {appRole.description}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem> */}
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonCol>
        <IonCol size="12" sizeLg="6" sizeXl="7">
          <IonCard className="ion-padding-bottom">
            <IonCardHeader>
              <IonCardTitle>Organization Info</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="ion-no-padding">
              <IonList>
                <IonItem>
                  <IonLabel position="floating">Organization Name</IonLabel>
                  <IonInput
                    type="text"
                    value={organizationName}
                    readonly={!editing}
                    disabled={loading}
                    onIonChange={(e) =>
                      setOrganizationName(e.detail.value as string)
                    }
                  />
                  <IonNote slot="helper">
                    TODO: LOOK FOR POSSIBLE MATCHES
                  </IonNote>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">
                    Location (Facility) Name
                  </IonLabel>
                  <IonInput
                    type="text"
                    value={locationName}
                    readonly={!editing}
                    disabled={loading}
                    onIonChange={(e) =>
                      setLocationName(e.detail.value as string)
                    }
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Street Address</IonLabel>
                  <IonInput
                    type="text"
                    value={address1}
                    readonly={!editing}
                    disabled={loading}
                    onIonChange={(e) => setAddress1(e.detail.value as string)}
                  />
                </IonItem>
                {/* <IonItem>
                  <IonLabel position="floating">Suite, Unit, etc.</IonLabel>
                  <IonInput
                    type="text"
                    value={address2}
                    readonly={!editing}
                    disabled={loading}
                    onIonChange={(e) => setAddress2(e.detail.value as string)}
                  />
                </IonItem> */}
                <IonItem>
                  <IonLabel position="floating">City</IonLabel>
                  <IonInput
                    type="text"
                    value={city}
                    readonly={!editing}
                    disabled={loading}
                    onIonChange={(e) => setCity(e.detail.value as string)}
                  />
                </IonItem>
                {/* <IonItem>
                  <IonLabel position="floating">State</IonLabel>
                  <IonInput
                    type="text"
                    value={state}
                    readonly={!editing}
                    disabled={loading}
                    onIonChange={(e) => setState(e.detail.value as string)}
                  />
                </IonItem> */}
                <SelectState
                  value={state}
                  onIonChange={(e: any) => setState(e.detail.value as string)}
                />
                <IonItem>
                  <IonLabel position="floating">Zip</IonLabel>
                  <IonInput
                    type="text"
                    value={zip}
                    readonly={!editing}
                    disabled={loading}
                    onIonChange={(e) => setZip(e.detail.value as string)}
                  />
                </IonItem>
              </IonList>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
