import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonIcon,
  IonRow,
  IonSkeletonText,
  IonSpinner,
} from '@ionic/react';
import BadgeOrderStatus from 'components/buyer/BadgeOrderStatus';
import ChipAccount from 'components/buyer/ChipAccount';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import DataTable, {
  DataTableHeader,
  DataTableRow,
} from 'components/shared/DataTable';
import Divider from 'components/shared/Divider';
import ItemDescriptionCol from 'components/shared/ItemDescriptionCol';
import { Page } from 'components/shared/Page';
import { useApi } from 'hooks/useApi';
import { Account } from 'interfaces/Account';
import { EdiAck } from 'interfaces/EdiAck';
import { EdiInvoice } from 'interfaces/EdiInvoice';
import { EdiShipNotice } from 'interfaces/EdiShipNotice';
import { Location } from 'interfaces/Location';
import { PurchaseOrder } from 'interfaces/PurchaseOrder';
import { PurchaseOrderItem } from 'interfaces/PurchaseOrderItem';
import { Supplier } from 'interfaces/Supplier';
import { printOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { formatDate, toUsd } from 'utils';

export const PageOrder: React.FC = () => {
  const api = useApi();
  const { id } = useParams<{ id: string }>();
  const allSuppliers: Supplier[] | null = useSelector(
    (state: any) => state.app.allSuppliers
  );
  const [loading, setLoading] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>();
  const [purchaseOrderItems, setPurchaseOrderItems] = useState<
    PurchaseOrderItem[]
  >([]);
  const [totalCost, setTotalCost] = useState<number>(0);

  const [location, setLocation] = useState<Location | null>(null);
  const [locationLoading, setLocationLoading] = useState(false);

  const [account, setAccount] = useState<Account | null>(null);
  const [accountLoading, setAccountLoading] = useState(false);

  const [ackItems, setAckItems] = useState<EdiAck[]>([]);
  const [ackItemsLoading, setAckItemsLoading] = useState(false);

  const [shipNoticeItems, setShipNoticeItems] = useState<EdiShipNotice[]>([]);
  const [shipNoticeItemsLoading, setShipNoticeItemsLoading] = useState(false);

  const [invoiceItems, setInvoiceItems] = useState<EdiInvoice[]>([]);
  const [invoiceItemsLoading, setInvoiceItemsLoading] = useState(false);

  const [tableHeaders, setTableHeaders] = useState<DataTableHeader[]>([]);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Orders', path: '/orders', direction: 'back' },
    { label: purchaseOrder ? purchaseOrder.purchase_order_number : id },
  ];

  useEffect(() => {
    getPurchaseOrder();
    getPurchaseOrderItems();
    getAckItems();
    getShipNoticeItems();
    getInvoiceItems();
  }, [id]);

  useEffect(() => {
    if (purchaseOrder) {
      getAccount();
      getLocation();
    } else {
      setAccount(null);
      setLocation(null);
    }

    const headers: DataTableHeader[] = [
      { text: 'Item Description', key: 'item' },
      { text: 'Requested Quantity', key: 'poQuantity', align: 'center' },
      {
        text: 'Acknowledged Quantity',
        key: 'acknowledgedQuantity',
        align: 'center',
      },
      { text: 'Invoiced Quantity', key: 'invoicedQuantity', align: 'center' },
    ];
    let costHeader: DataTableHeader = {
      text: '',
      key: 'totalPrice',
      align: 'right',
    };
    if (purchaseOrder && purchaseOrder.status === 'Invoiced') {
      costHeader.text = 'Invoiced Cost';
    } else {
      costHeader.text = 'Estimated Cost';
    }
    headers.push(costHeader);
    setTableHeaders(headers);
  }, [purchaseOrder]);

  useEffect(() => {
    if (!purchaseOrderItems || purchaseOrderItems.length === 0) {
      return;
    }
    let cost = 0;
    if (purchaseOrder?.status === 'Invoiced' && invoiceItems.length > 0) {
      invoiceItems.forEach((item: EdiInvoice) => {
        cost += parseFloat(`${item.item_unit_price}`) * item.item_quantity;
      });
      console.log(cost);
    } else {
      purchaseOrderItems.forEach((item: PurchaseOrderItem) => {
        cost += parseFloat(item.item_unit_price) * item.quantity;
      });
    }
    setTotalCost(cost);
  }, [purchaseOrderItems, invoiceItems]);

  const getPurchaseOrder = () => {
    setLoading(true);
    api
      .get(`purchaseorders/${id}`)
      .then((response) => {
        const data: PurchaseOrder = response.data;
        setPurchaseOrder(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getAccount = () => {
    if (!purchaseOrder) {
      return;
    }
    setAccountLoading(true);
    api
      .get(`accounts/${purchaseOrder.account_id}`)
      .then((response) => {
        if (response.status === 200) {
          const data: Account = response.data;
          setAccount(data);
          setAccountLoading(false);
        }
        setAccountLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAccountLoading(false);
      });
  };

  const getPurchaseOrderItems = () => {
    setLoading(true);
    api
      .get(`purchaseorders/${id}/purchaseorderitems`)
      .then(({ data }) => {
        setPurchaseOrderItems(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const getAckItems = () => {
    setAckItemsLoading(true);
    api
      .get(`purchaseorders/${id}/ack`)
      .then((response) => {
        if (response.status === 200) {
          setAckItems(response.data);
        }
        setAckItemsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAckItemsLoading(false);
      });
  };

  const getShipNoticeItems = () => {
    setShipNoticeItemsLoading(true);
    api
      .get(`purchaseorders/${id}/ship_notice`)
      .then((response) => {
        if (response.status === 200) {
          setShipNoticeItems(response.data);
        }
        setShipNoticeItemsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setShipNoticeItemsLoading(false);
      });
  };

  const getInvoiceItems = () => {
    setInvoiceItemsLoading(true);
    api
      .get(`purchaseorders/${id}/invoice`)
      .then((response) => {
        if (response.status === 200) {
          setInvoiceItems(response.data);
        }
        setInvoiceItemsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setInvoiceItemsLoading(false);
      });
  };

  const getLocation = () => {
    if (!purchaseOrder) {
      return;
    }
    setLocationLoading(true);
    api.get(`locations/${purchaseOrder.location_id}`).then((response) => {
      const data: Location = response.data;
      setLocation(data);
      setLocationLoading(false);
    });
  };

  const getItemPrice = (item: PurchaseOrderItem) => {
    // TODO: calculate estimated cost, or Invoiced cost
    return toUsd(parseFloat(item.item_unit_price) * item.quantity);
  };

  const AckQuantity: React.FC<{ item: PurchaseOrderItem }> = ({ item }) => {
    if (ackItemsLoading) {
      return <IonSpinner name="crescent" color="primary" />;
    } else if (ackItems.length === 0) {
      return <p>{'--'}</p>;
    }
    const ackItem = ackItems.find(
      (ackItem) => ackItem.purchase_order_item_ndc === item.item_ndc
    );
    return <p>{ackItem ? ackItem.item_quantity : '--'}</p>;
  };

  const InvoiceQuantity: React.FC<{ item: PurchaseOrderItem }> = ({ item }) => {
    if (invoiceItemsLoading) {
      return <IonSpinner name="crescent" color="primary" />;
    } else if (invoiceItems.length === 0) {
      return <p>{'--'}</p>;
    }
    const invoiceItem = invoiceItems.find(
      (invoiceItem) => invoiceItem.item_ndc === item.item_ndc
    );
    return <p>{invoiceItem ? invoiceItem.item_quantity : '--'}</p>;
  };

  const Footer = () => {
    return (
      <div slot="end">
        <IonButton>
          Print PO
          <IonIcon icon={printOutline} slot="end" />
        </IonButton>
      </div>
    );
  };

  return (
    <Page title="Order Detail" footer={/*<Footer />*/ undefined}>
      <Breadcrumbs breadcrumbs={breadcrumbs} title="Order Detail" />

      <IonRow>
        <IonCol size="12">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                {(loading && (
                  <IonSkeletonText animated={true} style={{ width: '300px' }} />
                )) ||
                  `PO #: ${purchaseOrder?.purchase_order_number}`}
              </IonCardTitle>
              {purchaseOrder ? (
                <BadgeOrderStatus
                  status={purchaseOrder.status}
                  className="ion-margin-start"
                />
              ) : null}
            </IonCardHeader>
            <IonCardContent className="ion-no-padding">
              {/* <Divider className="ion-no-margin" /> */}
              <IonRow className="ion-padding">
                <IonCol
                  size="12"
                  sizeMd="6"
                  className="ion-text-left ion-no-padding"
                >
                  {!locationLoading && location ? (
                    <>
                      <h2 className="font-size-xl">{location.name}</h2>
                      <p>
                        {location.address1}
                        {location.address2 && (
                          <span>,&nbsp;{location.address2}</span>
                        )}
                      </p>
                      <p>
                        {location.city} {location.state}, {location.postal_code}
                      </p>
                    </>
                  ) : (
                    <>
                      <IonSkeletonText
                        animated={true}
                        style={{ width: '200px', height: '20px' }}
                      />
                      <IonSkeletonText
                        animated={true}
                        style={{ width: '125px', height: '16px' }}
                      />
                      <IonSkeletonText
                        animated={true}
                        style={{ width: '160px', height: '16px' }}
                      />
                    </>
                  )}
                </IonCol>
                <IonCol
                  size="12"
                  sizeMd="6"
                  className="ion-text-right ion-no-padding"
                >
                  <h3 className="font-size-large">To</h3>
                  <h3 className="font-size-large">
                    {
                      allSuppliers?.find(
                        (supplier) =>
                          supplier.supplier_id === purchaseOrder?.supplier_id
                      )?.name
                    }
                  </h3>
                  <p>PO Date: {formatDate(purchaseOrder?.created || '')}</p>
                </IonCol>
              </IonRow>
              <Divider className="ion-no-margin" />
              <DataTable
                headers={tableHeaders}
                loading={loading}
                rows={purchaseOrderItems.map((item): DataTableRow => {
                  const row: DataTableRow = {
                    item: item,
                    key: `${item.supplier_item_number}-${item.account_number}`,
                    columns: [
                      {
                        header: 'item',
                        content: <ItemDescriptionCol item={item} />,
                      },
                      {
                        header: 'poQuantity',
                        content: <p>{item.quantity}</p>,
                      },
                      {
                        header: 'acknowledgedQuantity',
                        content: <AckQuantity item={item} />,
                      },
                      {
                        header: 'invoicedQuantity',
                        content: <InvoiceQuantity item={item} />,
                      },
                      {
                        header: 'totalPrice',
                        content: (
                          <p className="font-weight-500 text-color-black">
                            {getItemPrice(item)}
                          </p>
                        ),
                      },
                    ],
                  };
                  return row;
                })}
              >
                <div className="fullWidth ion-text-left">
                  {account ? (
                    <span>
                      <p
                        className="ion-no-margin font-size-default margin-right-sm margin-left-xs"
                        style={{ marginLeft: 'var(--app-spacing-xs)' }}
                      >
                        Account:
                      </p>
                      <ChipAccount
                        account={account}
                        style={{ marginLeft: 0, marginBottom: 0 }}
                      />
                    </span>
                  ) : null}
                </div>
              </DataTable>
              <IonRow>
                <IonCol className="ion-text-right ion-padding">
                  <h2 className="font-size-large">
                    {purchaseOrder?.status === 'Invoiced'
                      ? 'Invoiced'
                      : 'Estimated'}{' '}
                    Cost:{' '}
                    <strong className="text-color-black">
                      {toUsd(totalCost)}
                    </strong>
                  </h2>
                </IonCol>
              </IonRow>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
