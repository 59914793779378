import { useIonAlert } from '@ionic/react';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { Location } from 'interfaces/Location';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isAnalyst } from 'utils';

export const useAlertAddSupplier = () => {
  const [presentAlert] = useIonAlert();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.app.user
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (authUser) {
      if (isAnalyst(authUser)) {
        setMessage(
          'Contact a Buyer or an Admin at your organization to add a Supplier to this location.'
        );
      } else {
        setMessage('Add at least one Supplier to get started');
      }
    }
  }, [authUser]);

  const showAlert = (onWillDismiss: (e: any) => void) => {
    presentAlert({
      header: 'No Suppliers Configured',
      subHeader: `Selected location: ${selectedLocation?.name}`,
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: !!authUser && isAnalyst(authUser) ? 'Ok' : 'Add Supplier',
          role: 'confirm',
        },
      ],
      onWillDismiss: onWillDismiss,
    });
  };

  return { showAlert };
};
