import { IonInput, IonItem, IonLabel, IonNote } from '@ionic/react';
import { useEffect, useState } from 'react';
import SelectState from './SelectState';

export interface ValidationRule {
  pattern: RegExp;
  message: string;
}

export const FormInput: React.FC<{
  label: string;
  name: string;
  placeholder?: string;
  inputType: 'text' | 'state';
  type: 'text' | 'email' | 'password' | 'tel';
  required: boolean;
  value: any;
  setValue: (value: any) => void;
  rules?: ValidationRule[];
  disabled?: boolean;
}> = ({
  label,
  name,
  placeholder,
  inputType,
  type,
  required,
  value,
  setValue,
  rules,
  disabled,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [touched, setTouched] = useState(false);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (required) {
      if (errors.length === 0 && touched) {
        setValid(true);
      } else {
        setValid(false);
      }
    } else {
      if (errors.length === 0) {
        setValid(true);
      } else {
        setValid(false);
      }
    }
  }, [required, value, errors, touched]);

  useEffect(() => {
    if (touched) {
      doValidation();
    }
  }, [value]);

  const doValidation = () => {
    const newErrors: string[] = [];
    if (required && !value) {
      newErrors.push(`${label} is required`);
    }
    if (rules) {
      rules.forEach((rule) => {
        if (!rule.pattern.test(value)) {
          if (required || (!required && value)) {
            newErrors.push(rule.message);
          }
        }
      });
    }
    setErrors(newErrors);
  };

  const handleBlur = () => {
    setTouched(true);
    doValidation();
  };

  const handleChange = (value: any) => {
    setTouched(true);
    setValue(value);
  };
  return (
    <>
      {inputType === 'state' && (
        <SelectState
          value={value}
          onIonChange={(e: any) => setValue(e.detail.value)}
          onIonBlur={() => handleBlur()}
          errors={errors}
          disabled={disabled}
        />
      )}
      {inputType === 'text' && (
        <IonItem
          className={`${valid ? 'ion-valid' : 'ion-invalid'}`}
          detail={false}
        >
          <IonLabel position="floating">{label}</IonLabel>
          <IonInput
            type={type}
            name={name}
            required={required}
            value={value}
            onIonBlur={handleBlur}
            onIonChange={(e: any) => handleChange(e.detail.value)}
            placeholder={placeholder || undefined}
            disabled={disabled}
          />
          <IonNote slot="error">
            <ul style={{ margin: 0, padding: '0 0 0 var(--app-spacing)' }}>
              {errors.map((error) => (
                <li key={error}>
                  <span>{error}</span>
                </li>
              ))}
            </ul>
          </IonNote>
        </IonItem>
      )}
    </>
  );
};
