import { IonIcon, useIonViewWillEnter } from '@ionic/react';
import { useApi } from 'hooks/useApi';
import DataTable, {
  DataTableHeader,
  DataTableItemAction,
  DataTableRow,
} from 'components/shared/DataTable';
import { pencilOutline, trashOutline } from 'ionicons/icons';
import { useState } from 'react';
import { formatDate, toUsd } from 'utils';
import axios from 'axios';

interface SupplierDeal {
  id: number;
  vendorItemNumber: string | number;
  ndc: string | null;
  description: string;
  availableTo: '340B' | 'GPO' | 'WAC' | 'All';
  dating: string | null;
  minQuantity: number | null;
  price: number;
  expiresAt: string;
}

const TableSupplierDeals: React.FC = () => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [supplierDeals, setSupplierDeals] = useState<SupplierDeal[]>([]);

  const headers: DataTableHeader[] = [
    { text: 'Vendor Item Number', key: 'vendorItemNumber' },
    { text: 'NDC', key: 'ndc' },
    { text: 'Item Description', key: 'description' },
    {
      text: 'Available to (Account Type)',
      key: 'availableTo',
      align: 'center',
      width: '150px',
    },
    { text: 'Dating', key: 'dating' },
    { text: 'Min. Order Qty', key: 'minQuantity', align: 'center' },
    { text: 'Price', key: 'price', align: 'right' },
    { text: 'Expiration Date', key: 'expiresAt', width: '200px' },
  ];

  useIonViewWillEnter(() => {
    getSupplierDeals();
  }, []);

  const mockSupplierDeals: SupplierDeal[] = [
    {
      id: 1,
      vendorItemNumber: 'VIN-1452186',
      ndc: '61314-318-01',
      description: 'vitae repellat atque facere deserunt',
      availableTo: 'GPO',
      dating: 'Minimum 6 months',
      minQuantity: 14,
      price: 747.79,
      expiresAt: '2001-08-23T00:37:23.301Z',
    },
    {
      id: 2,
      vendorItemNumber: 'VIN-9627777',
      ndc: '61314-318-02',
      description: 'porro suscipit repellendus odio omnis',
      availableTo: 'All',
      dating: null,
      minQuantity: 4,
      price: 127.63,
      expiresAt: '1983-07-07T09:39:42.898Z',
    },
    {
      id: 3,
      vendorItemNumber: 'VIN-5632902',
      ndc: '61314-318-03',
      description: 'maiores minima praesentium cupiditate fuga',
      availableTo: '340B',
      dating: 'Minimum 3 months',
      minQuantity: 39,
      price: 613.95,
      expiresAt: '1970-07-04T03:36:00.354Z',
    },
    {
      id: 4,
      vendorItemNumber: 'VIN-2004250',
      ndc: '61314-318-04',
      description: 'facilis doloribus incidunt fugit sunt',
      availableTo: '340B',
      dating: 'Minimum 3 months',
      minQuantity: 67,
      price: 801,
      expiresAt: '2010-05-19T02:14:09.361Z',
    },
    {
      id: 5,
      vendorItemNumber: 'VIN-9790188',
      ndc: '61314-318-05',
      description: 'quibusdam maiores perferendis aut vitae',
      availableTo: '340B',
      dating: 'Minimum 3 months',
      minQuantity: 71,
      price: 784.87,
      expiresAt: '1984-12-21T01:57:20.487Z',
    },
    {
      id: 6,
      vendorItemNumber: 'VIN-3939077',
      ndc: '61314-318-06',
      description: 'blanditiis voluptatem sequi odio a',
      availableTo: '340B',
      dating: 'Minimum 3 months',
      minQuantity: 40,
      price: 450.01,
      expiresAt: '1991-06-26T08:05:33.455Z',
    },
    {
      id: 7,
      vendorItemNumber: 'VIN-7572492',
      ndc: '61314-318-07',
      description: 'suscipit facere quidem quia rerum',
      availableTo: 'GPO',
      dating: 'Minimum 6 months',
      minQuantity: 93,
      price: 470.74,
      expiresAt: '1970-05-02T15:06:40.795Z',
    },
    {
      id: 8,
      vendorItemNumber: 'VIN-5608713',
      ndc: '61314-318-08',
      description: 'mollitia minus debitis sapiente rerum',
      availableTo: 'GPO',
      dating: 'Minimum 6 months',
      minQuantity: 93,
      price: 311.17,
      expiresAt: '1972-09-25T04:32:18.879Z',
    },
    {
      id: 9,
      vendorItemNumber: 'VIN-3962184',
      ndc: '61314-318-09',
      description: 'est inventore unde iste assumenda',
      availableTo: 'GPO',
      dating: 'Minimum 6 months',
      minQuantity: 20,
      price: 363.67,
      expiresAt: '2018-05-04T23:52:03.891Z',
    },
    {
      id: 10,
      vendorItemNumber: 'VIN-6495287',
      ndc: '61314-318-010',
      description: 'suscipit expedita culpa dolorum unde',
      availableTo: 'All',
      dating: null,
      minQuantity: 18,
      price: 208.24,
      expiresAt: '1989-03-14T21:16:41.795Z',
    },
  ];

  const getSupplierDeals = () => {
    setLoading(true);
    setTimeout(() => {
      setSupplierDeals(mockSupplierDeals);
      setLoading(false);
    }, 150);

    // axios
    //   .get('supplierDeals', { baseURL: process.env.REACT_APP_MOCK_URL })
    //   .then(({ data }) => {
    //     setSupplierDeals(data);
    //     setLoading(false);
    //   });
  };

  const actions = (item: any): DataTableItemAction[] => {
    const actions = [
      {
        label: 'Manage Deal',
        icon: <IonIcon icon={pencilOutline} />,
        callback: (item: any) => {
          console.log('manage supplier deal');
        },
      },
      {
        label: 'Delete Deal',
        icon: <IonIcon icon={trashOutline} color="danger" />,
        color: 'danger',
        callback: (catalog: any) => {
          console.log('Delete catalog item');
        },
      },
    ];
    return actions;
  };

  return (
    <DataTable
      title="Featured Deals"
      headers={headers}
      actions={actions}
      rows={supplierDeals.map((supplierDeal: any): DataTableRow => {
        const row: DataTableRow = {
          item: supplierDeal,
          key: supplierDeal.id,
          columns: [
            {
              header: 'vendorItemNumber',
              content: <p>{supplierDeal.vendorItemNumber}</p>,
            },
            {
              header: 'ndc',
              content: <p>{supplierDeal.ndc}</p>,
            },
            {
              header: 'description',
              content: <p>{supplierDeal.description}</p>,
            },
            {
              header: 'availableTo',
              content: <p>{supplierDeal.availableTo}</p>,
            },
            {
              header: 'dating',
              content: <p>{supplierDeal.dating}</p>,
            },
            {
              header: 'minQuantity',
              content: <p>{supplierDeal.minQuantity}</p>,
            },
            {
              header: 'price',
              content: (
                <p className="font-weight-500 text-color-black font-size-normal">
                  {toUsd(supplierDeal.price)}
                </p>
              ),
            },
            {
              header: 'expiresAt',
              content: <p>{formatDate(supplierDeal.expiresAt)}</p>,
            },
          ],
        };
        return row;
      })}
      loading={loading}
    ></DataTable>
  );
};

export default TableSupplierDeals;
