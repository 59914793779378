import { IonSelect, IonSelectOption } from '@ionic/react';
import 'components/buyer/SelectAccount.css';
import { Account } from 'interfaces/Account';
import { Supplier } from 'interfaces/Supplier';
import { SupplierConfig, supplierConfig } from 'supplierConfig';

const SelectAccount: React.FC<{
  accounts: Account[];
  supplier: Supplier;
  value?: Account | null;
  onIonChange?: any;
  disabled?: boolean;
}> = ({ accounts, supplier, value, onIonChange, disabled }) => {
  const customConfig: SupplierConfig | null =
    supplierConfig.find((config) => config.number === supplier.number) || null;

  return (
    <IonSelect
      value={value}
      disabled={disabled}
      onIonChange={onIonChange}
      interface="popover"
      interfaceOptions={{
        dismissOnSelect: true,
        size: 'cover',
        alignment: 'end',
      }}
      placeholder="Select Account"
      className="select-account"
    >
      {accounts.length > 0 ? (
        accounts.map((account) => {
          return (
            <IonSelectOption
              key={account.account_id}
              value={account}
              className="font-weight-500"
            >
              {customConfig && customConfig.account?.transform ? (
                <>
                  {account.description}&nbsp;-&nbsp;
                  {customConfig.account?.transform(account.number)}
                </>
              ) : (
                <>
                  {account.description}&nbsp;-&nbsp;{account.number}
                </>
              )}
            </IonSelectOption>
          );
        })
      ) : (
        <IonSelectOption value={null}>No accounts found</IonSelectOption>
      )}
    </IonSelect>
  );
};

export default SelectAccount;
