import { faFileExcel, faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonButton, IonCard, IonCol, IonRow } from '@ionic/react';
import {
  orderBy,
  SortDescriptor,
  State as QueryState,
} from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { useApi } from 'hooks/useApi';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import { Page } from 'components/shared/Page';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Supplier } from 'interfaces/Supplier';
import axios from 'axios';

interface Sale {
  id: number;
  accountNumber: number;
  accountName: string;
  itemNumber: number | string;
  description: string | null;
  purchaseOrderNumber: number | string;
  orderedAt: string;
  shippedAt: string | null;
  quantity: number;
  unitPrice: number | string;
  grossSales: number | string;
  transactionFee: string | number;
  netSales: number | string;
}

const initialSort: Array<SortDescriptor> = [
  { field: 'orderedAt', dir: 'desc' },
];

export const PageSupplierSales: React.FC = () => {
  const api = useApi();
  const supplier: Supplier | null = useSelector(
    (state: any) => state.app.supplier
  );

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Sales' },
  ];

  const [sort, setSort] = useState(initialSort);
  const [dataState, setDataState] = useState<QueryState>({
    skip: 0,
    take: 20,
    sort: [{ field: 'orderDate', dir: 'desc' }],
    group: [{ field: 'customerID' }],
  });

  const [loading, setLoading] = useState(false);
  const [sales, setSales] = useState<Sale[]>([]);

  useEffect(() => {
    getSales();
  }, []);

  const mockSales: Sale[] = [
    {
      id: 1,
      accountNumber: 70783753,
      accountName: "Rogahn, O'Conner and Kiehn",
      itemNumber: 435,
      description: 'illo rem dolores labore deleniti',
      purchaseOrderNumber: 66904242055,
      orderedAt: '2022-11-01',
      shippedAt: '2022-11-03',
      quantity: 62,
      unitPrice: '871.40',
      grossSales: '$54,026.80',
      transactionFee: 'TBD%',
      netSales: 'gross - fee',
    },
    {
      id: 2,
      accountNumber: 22290822,
      accountName: 'Nikolaus - Rath',
      itemNumber: 772,
      description: 'officiis eos quas perferendis minima',
      purchaseOrderNumber: 48003184094,
      orderedAt: '2022-11-01',
      shippedAt: '2022-11-03',
      quantity: 88,
      unitPrice: '338.47',
      grossSales: '$29,785.36',
      transactionFee: 'TBD%',
      netSales: 'gross - fee',
    },
    {
      id: 3,
      accountNumber: 62380154,
      accountName: 'McLaughlin - Jakubowski',
      itemNumber: 24,
      description: 'maxime eaque reprehenderit exercitationem atque',
      purchaseOrderNumber: 82148024183,
      orderedAt: '2022-11-01',
      shippedAt: '2022-11-03',
      quantity: 97,
      unitPrice: '635.92',
      grossSales: '$61,684.24',
      transactionFee: 'TBD%',
      netSales: 'gross - fee',
    },
    {
      id: 4,
      accountNumber: 53840850,
      accountName: 'Bosco Group',
      itemNumber: 786,
      description: 'id architecto praesentium magnam alias',
      purchaseOrderNumber: 14007857230,
      orderedAt: '2022-11-01',
      shippedAt: '2022-11-03',
      quantity: 69,
      unitPrice: '794.41',
      grossSales: '$54,814.29',
      transactionFee: 'TBD%',
      netSales: 'gross - fee',
    },
    {
      id: 5,
      accountNumber: 69390200,
      accountName: 'Jast, King and Rosenbaum',
      itemNumber: 824,
      description: 'qui distinctio expedita iure et',
      purchaseOrderNumber: 93191281105,
      orderedAt: '2022-11-01',
      shippedAt: '2022-11-03',
      quantity: 86,
      unitPrice: '938.49',
      grossSales: '$80,710.14',
      transactionFee: 'TBD%',
      netSales: 'gross - fee',
    },
    {
      id: 6,
      accountNumber: 80010608,
      accountName: 'Kuvalis - Hoppe',
      itemNumber: 716,
      description: 'fugit at nam eum provident',
      purchaseOrderNumber: 71020015764,
      orderedAt: '2022-11-01',
      shippedAt: '2022-11-03',
      quantity: 74,
      unitPrice: '132.13',
      grossSales: '$9,777.62',
      transactionFee: 'TBD%',
      netSales: 'gross - fee',
    },
    {
      id: 7,
      accountNumber: 79169352,
      accountName: 'Champlin - Fadel',
      itemNumber: 554,
      description: 'iste nemo minus tempore tempora',
      purchaseOrderNumber: 87243427985,
      orderedAt: '2022-11-01',
      shippedAt: '2022-11-03',
      quantity: 89,
      unitPrice: '843.98',
      grossSales: '$75,114.22',
      transactionFee: 'TBD%',
      netSales: 'gross - fee',
    },
    {
      id: 8,
      accountNumber: 42354647,
      accountName: 'Kessler, Runte and Gerlach',
      itemNumber: 261,
      description: 'quisquam ullam corrupti maxime itaque',
      purchaseOrderNumber: 88135119089,
      orderedAt: '2022-11-01',
      shippedAt: '2022-11-03',
      quantity: 74,
      unitPrice: '101.50',
      grossSales: '$7,511.00',
      transactionFee: 'TBD%',
      netSales: 'gross - fee',
    },
    {
      id: 9,
      accountNumber: 64487150,
      accountName: 'Franey, Sporer and Carroll',
      itemNumber: 391,
      description: 'impedit voluptates voluptates dolorum ad',
      purchaseOrderNumber: 72106559232,
      orderedAt: '2022-11-01',
      shippedAt: '2022-11-03',
      quantity: 34,
      unitPrice: '921.70',
      grossSales: '$31,337.80',
      transactionFee: 'TBD%',
      netSales: 'gross - fee',
    },
    {
      id: 10,
      accountNumber: 20120899,
      accountName: 'Johns - Bernier',
      itemNumber: 115,
      description: 'repellendus officia corrupti earum consequatur',
      purchaseOrderNumber: 51170498500,
      orderedAt: '2022-11-01',
      shippedAt: '2022-11-03',
      quantity: 75,
      unitPrice: '593.17',
      grossSales: '$44,487.75',
      transactionFee: 'TBD%',
      netSales: 'gross - fee',
    },
  ];

  const getSales = () => {
    setLoading(true);
    setTimeout(() => {
      setSales(mockSales);
      setLoading(false);
    }, 150);

    // axios
    //   .get('supplierSales', { baseURL: process.env.REACT_APP_MOCK_URL })
    //   .then(({ data }) => {
    //     setLoading(false);
    //     setSales(data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setLoading(false);
    //   });
  };

  let _pdfExport: any;

  const exportExcel = () => {
    _export.save();
  };

  let _export: any;
  const exportPdf = () => {
    _pdfExport.save();
  };

  return (
    <Page title="Supplier Sales">
      <Breadcrumbs breadcrumbs={breadcrumbs}>
        <IonButton onClick={exportExcel} size="small">
          Export to Excel
          <span slot="end">
            <FontAwesomeIcon icon={faFileExcel} />
          </span>
        </IonButton>
        <IonButton onClick={exportPdf} size="small">
          Export to PDF
          <span slot="end">
            <FontAwesomeIcon icon={faFilePdf} />
          </span>
        </IonButton>
      </Breadcrumbs>
      <IonRow>
        <IonCol>
          <IonCard>
            <ExcelExport
              data={sales}
              ref={(exporter) => {
                _export = exporter;
              }}
              fileName={`${
                supplier?.name
              }_Trulla-Direct_sales_${new Date().toLocaleDateString()}`}
            >
              <Grid
                data={orderBy(sales, sort)}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                  setSort(e.sort);
                }}
              >
                <Column field="accountNumber" title="Account Number" />
                <Column field="accountName" title="Account Name" />
                <Column field="itemNumber" title="Item Number" />
                <Column field="description" title="Description" />
                <Column field="purchaseOrderNumber" title="PO Number" />
                <Column field="orderedAt" title="Order Date" />
                <Column field="shippedAt" title="Shipped Date" />
                <Column field="quantity" title="Qty" />
                <Column field="unitPrice" title="Unit Price" />
                <Column field="grossSales" title="Gross Sales" />
                <Column field="transactionFee" title="Transaction Fee" />
                <Column field="netSales" title="Net Sales" />
              </Grid>
            </ExcelExport>
            <GridPDFExport
              ref={(element) => {
                _pdfExport = element;
              }}
              margin="1cm"
              fileName={`${
                supplier?.name
              }_Trulla-Direct_sales_${new Date().toLocaleDateString()}`}
            >
              <Grid>
                <Column field="accountNumber" title="Account Number" />
                <Column field="accountName" title="Account Name" />
                <Column field="itemNumber" title="Item Number" />
                <Column field="description" title="Description" width="200px" />
                <Column field="purchaseOrderNumber" title="PO Number" />
                <Column field="orderedAt" title="Order Date" />
                <Column field="shippedAt" title="Shipped Date" />
                <Column field="quantity" title="Qty" />
                <Column field="unitPrice" title="Unit Price" />
                <Column field="grossSales" title="Gross Sales" />
                <Column field="transactionFee" title="Transaction Fee" />
                <Column field="netSales" title="Net Sales" />
              </Grid>
            </GridPDFExport>
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
