import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import { Page } from 'components/shared/Page';

export const PageAnalytics: React.FC = () => {
  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Analytics' },
  ];

  return (
    <Page title="Analytics">
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </Page>
  );
};
