import { IonButton, IonCard, IonCol, IonIcon, IonRow } from '@ionic/react';
import TableUsers from 'components/buyer/TableUsers';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import { Page } from 'components/shared/Page';
import { addOutline } from 'ionicons/icons';

export const PageUsers: React.FC = () => {
  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Settings', path: '/settings', direction: 'back' },
    { label: 'Users' },
  ];

  return (
    <Page title="User Settings">
      <Breadcrumbs breadcrumbs={breadcrumbs} title="User Settings">
        <IonButton
          size="small"
          routerLink="/settings/users/add"
          routerDirection="forward"
        >
          Add User
          <IonIcon slot="end" icon={addOutline} />
        </IonButton>
      </Breadcrumbs>
      <IonRow>
        <IonCol>
          <IonCard>
            <TableUsers />
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
