import { IonCol, IonRow } from '@ionic/react';
import { AppUser } from 'interfaces/AppUser';
import LocationsCard from 'components/buyer/CardLocations';
import CardUser from 'components/buyer/CardUser';

const FormManageUser: React.FC<{
  user?: AppUser;
  editing?: boolean;
  isSupplier?: boolean;
  onUserChange: (userData: any) => void;
  onLocationsChange?: (locationIds: number[]) => void;
}> = ({ user, editing, isSupplier, onUserChange, onLocationsChange }) => {
  return (
    <>
      <IonRow>
        <IonCol size="12" sizeLg="6" sizeXl="5">
          <CardUser user={user} editing={editing} onChange={onUserChange} />
        </IonCol>
        <IonCol size="12" sizeLg="6" sizeXl="7">
          {onLocationsChange !== undefined && !isSupplier ? (
            <LocationsCard
              userLocationIds={user?.location_ids}
              editing={editing}
              onChange={onLocationsChange}
            />
          ) : null}
        </IonCol>
      </IonRow>
    </>
  );
};

export default FormManageUser;
