import { IonButton, useIonModal } from '@ionic/react';
import ModalChangeLocation from 'components/buyer/ModalChangeLocation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHospital } from '@fortawesome/pro-light-svg-icons';

const ButtonChangeLocation: React.FC<{ style?: React.CSSProperties }> = ({
  style,
}) => {
  const [presentLocations, dismissLocations] = useIonModal(
    ModalChangeLocation,
    {
      onDismiss: (data: string, role: string) => dismissLocations(data, role),
    }
  );

  const showLocations = () => {
    presentLocations({
      onWillDismiss: (e: any) => {},
    });
  };
  return (
    <IonButton
      size="small"
      fill="clear"
      onClick={showLocations}
      data-tooltip-id="global-tooltip"
      data-tooltip-content="Change location"
      data-tooltip-place="bottom"
      style={style}
    >
      <span slot="icon-only" className="font-size-large">
        <FontAwesomeIcon icon={faHospital} />
      </span>
    </IonButton>
  );
};
export default ButtonChangeLocation;
