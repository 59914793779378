import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonRow,
  IonSearchbar,
  IonSkeletonText,
} from '@ionic/react';
import ButtonAddToCart from 'components/buyer/ButtonAddToCart';
import { CardStatOrderedItems } from 'components/buyer/CardStatOrderedItems';
import { CardStatSpend } from 'components/buyer/CardStatSpend';
import ModalManageAccounts from 'components/buyer/ModalManageAccounts';
import PopoverSupplierMeta from 'components/buyer/PopoverSupplierMeta';
import SelectAccount from 'components/buyer/SelectAccount';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import DataTable, {
  DataTableHeader,
  DataTablePagination,
  DataTableRow,
} from 'components/shared/DataTable';
import ItemDescriptionCol from 'components/shared/ItemDescriptionCol';
import LogoSupplier from 'components/shared/LogoSupplier';
import { Page } from 'components/shared/Page';
import { SelectTimeFrame, TimeFrame } from 'components/shared/SelectTimeFrame';
import { useApi } from 'hooks/useApi';
import { Account } from 'interfaces/Account';
import { AppUserSelf } from 'interfaces/AppUserSelf';
import { CatalogItem } from 'interfaces/CatalogItem';
import { Location } from 'interfaces/Location';
import { PurchaseOrderItem } from 'interfaces/PurchaseOrderItem';
import { ShoppingCartItem } from 'interfaces/ShoppingCartItem';
import { Supplier } from 'interfaces/Supplier';
import 'pages/buyer/PageSupplier.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { isAnalyst, toUsd } from 'utils';

export const PageSupplier: React.FC = () => {
  const api = useApi();
  const { id } = useParams<{ id: string }>();
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.app.user
  );

  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const [search, setSearch] = useState<string>('');

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [supplier, setSupplier] = useState<Supplier>();
  const [itemsLoading, setItemsLoading] = useState(false);
  const [items, setItems] = useState<CatalogItem[]>([]);
  const [showAccounts, setShowAccounts] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Account>();

  const [timeFrameLabel, setTimeFrameLabel] = useState<string>();
  const [filterMinDate, setFilterMinDate] = useState<string | null>(null);
  const [pagination, setPagination] = useState<DataTablePagination>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const [selectedItem, setSelectedItem] = useState<
    CatalogItem | ShoppingCartItem | PurchaseOrderItem | null
  >(null);

  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Suppliers', path: '/suppliers', direction: 'back' },
    { label: supplier ? supplier.name : id },
  ];

  const headers: Array<DataTableHeader> = [
    { text: 'Item', key: 'item' },
    { text: 'Pack Price', key: 'packPrice', align: 'right' },
    { text: 'Quantity', key: 'actions', align: 'left', width: '325px' },
  ];

  useEffect(() => {
    getSupplier();
  }, [id]);

  useEffect(() => {
    getAccounts();
  }, [id, selectedLocation]);

  useEffect(() => {
    getItems();
  }, [selectedAccount, search, pagination]);

  useEffect(() => {
    if (accounts.length === 0) {
      setItems([]);
    }
  }, [accounts]);

  const getSupplier = () => {
    setLoading(true);
    api
      .get(`suppliers/${id}`)
      .then(({ data }) => {
        setSupplier(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getAccounts = () => {
    if (!selectedLocation) return;
    setAccountsLoading(true);
    api
      .get(
        `locations/${selectedLocation.location_id}/accounts?supplier_id=${id}`
      )
      .then((response) => {
        const data: Account[] = response.data;
        setAccounts(data);
        setAccountsLoading(false);
        if (data.length > 0) {
          setSelectedAccount(data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
        setAccountsLoading(false);
      });
  };

  const getItems = () => {
    if (!selectedAccount) return;
    setItemsLoading(true);
    api
      .get(`accounts/${selectedAccount.account_id}/catalog`, {
        limit: pagination?.limit,
        offset: pagination?.offset,
        search: search || undefined,
      })
      .then((response) => {
        if (response.status === 200) {
          setItems(response.data || []);
          setTotalCount(response.data.length);
        }
        setItemsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setItemsLoading(false);
      });
  };

  return (
    <Page title="Supplier Detail" className="pageSupplier">
      <Breadcrumbs breadcrumbs={breadcrumbs} title="Supplier Detail">
        {authUser && !isAnalyst(authUser) ? (
          <IonButton size="small" onClick={() => setShowAccounts(true)}>
            Manage Accounts
          </IonButton>
        ) : null}
      </Breadcrumbs>

      <IonRow>
        <IonCol size="12" sizeMd="6" sizeLg="3">
          <IonCard className="supplierCard d-flex ion-align-items-center ion-justify-content-center">
            <IonCardContent>
              <IonRow>
                <IonCol size="12" className="ion-text-center">
                  {loading && (
                    <IonSkeletonText animated={true} className="supplierLogo" />
                  )}
                  {!loading && supplier && <LogoSupplier src={supplier.logo} />}
                </IonCol>
                <IonCol className="ion-text-center">
                  <PopoverSupplierMeta supplier={supplier} />
                </IonCol>
              </IonRow>
            </IonCardContent>
          </IonCard>
        </IonCol>

        <IonCol size="12" sizeMd="6" sizeLg="9" className="ion-no-padding">
          <IonRow>
            <IonCol size="12" className="ion-no-padding">
              <IonRow>
                <IonCol size="12" sizeLg="8" className="ion-padding-horizontal">
                  <SelectTimeFrame
                    onUpdate={(timeFrame: TimeFrame) => {
                      setFilterMinDate(timeFrame.value);
                      setTimeFrameLabel(timeFrame.label);
                    }}
                  />
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="4">
              <CardStatSpend
                minDate={filterMinDate}
                label={timeFrameLabel}
                supplier={supplier}
              />
            </IonCol>
            {/* TODO: Realized Savings (from buying featured deals) */}
            {/* <IonCol size="12" sizeMd="6" sizeLg="4">
              <CardStat
                color="success"
                title="Realized Savings"
                stat={toUsd(0, 0)}
                statTitle={filterTimeFrame}
                icon={cashOutline}
                loading={loading}
              />
            </IonCol> */}
            <IonCol size="12" sizeMd="6" sizeLg="4">
              <CardStatOrderedItems
                minDate={filterMinDate}
                label={timeFrameLabel}
                supplier={supplier}
              />
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12">
          <IonCard>
            <DataTable
              headers={headers}
              loading={itemsLoading}
              title={`${supplier?.name} Items`}
              search={false}
              onPaginationChange={(pagination: DataTablePagination) =>
                setPagination(pagination)
              }
              itemsPerPage={50}
              totalCount={totalCount}
              rows={items.map((item): DataTableRow => {
                const row: DataTableRow = {
                  item: item,
                  key: `${item.supplier_item_number}-${item.account_number}`,
                  columns: [
                    {
                      header: 'item',
                      content: (
                        <>
                          {supplier && selectedAccount && (
                            <ItemDescriptionCol
                              item={item}
                              account={selectedAccount}
                            />
                          )}
                        </>
                      ),
                    },
                    {
                      header: 'packPrice',
                      content: (
                        <>
                          <p className="font-weight-500 text-color-black">
                            {item.item_unit_price
                              ? toUsd(parseFloat(item.item_unit_price || '0'))
                              : 'N/A'}
                          </p>
                        </>
                      ),
                    },
                    {
                      header: 'actions',
                      content: (
                        <>
                          {selectedAccount &&
                          authUser &&
                          !isAnalyst(authUser) ? (
                            <ButtonAddToCart
                              item={item}
                              account={selectedAccount}
                            />
                          ) : null}
                        </>
                      ),
                    },
                  ],
                };
                return row;
              })}
            >
              <IonRow className="ion-justify-content-end ion-align-items-end">
                <IonCol size="12" sizeXl="6">
                  <p className="margin-left-xs ion-text-left">
                    Select Account:
                  </p>
                  {supplier ? (
                    <SelectAccount
                      accounts={accounts}
                      supplier={supplier}
                      value={selectedAccount}
                      disabled={accountsLoading}
                      onIonChange={(e: any) =>
                        setSelectedAccount(e.detail.value)
                      }
                    />
                  ) : null}
                </IonCol>
                <IonCol size="12" sizeXl="6">
                  <IonSearchbar
                    className="ion-text-left"
                    value={search}
                    onIonChange={(e: any) =>
                      setSearch(e.detail.value as string)
                    }
                    debounce={500}
                  />
                </IonCol>
              </IonRow>
            </DataTable>
          </IonCard>
        </IonCol>
      </IonRow>
      {supplier && selectedLocation ? (
        <ModalManageAccounts
          isOpen={showAccounts}
          onDismiss={() => {
            setShowAccounts(false);
            getAccounts();
          }}
          supplier={supplier}
          location={selectedLocation}
        />
      ) : null}
    </Page>
  );
};
