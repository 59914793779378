import { useIonViewWillEnter } from '@ionic/react';
import CardStat from 'components/shared/CardStat';
import { useApi } from 'hooks/useApi';
import { Location } from 'interfaces/Location';
import { Supplier } from 'interfaces/Supplier';
import { clipboardOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const CardStatOrders: React.FC<{
  label?: string;
  minDate: string | null;
  supplier?: Supplier;
  routerLink?: string;
  routerDirection?: 'root' | 'forward' | 'back' | 'none';
}> = ({ label, minDate, supplier, routerLink, routerDirection }) => {
  const api = useApi();
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const [loading, setLoading] = useState(false);
  const [orderCount, setOrderCount] = useState<number | null>(null);

  useEffect(() => {
    getOrderCount();
  }, []);

  useIonViewWillEnter(() => {
    getOrderCount();
  }, []);

  useEffect(() => {
    getOrderCount();
  }, [minDate, selectedLocation]);

  const getOrderCount = () => {
    if (!selectedLocation) {
      return;
    }
    setLoading(true);
    api
      .get(
        `locations/${selectedLocation.location_id}/stats/purchase_order_count`,
        {
          supplier_id: supplier ? supplier.supplier_id : undefined,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          if (data.count !== undefined && typeof data.count === 'number') {
            setOrderCount(response.data.count);
          } else {
            setOrderCount(null);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <CardStat
      color="primary"
      title={supplier ? `${supplier.name} Orders` : 'Orders'}
      loading={loading}
      stat={orderCount !== null ? orderCount.toLocaleString('en-US') : 'N/A'}
      statTitle={label}
      icon={clipboardOutline}
      routerLink={routerLink}
      routerDirection={routerDirection}
    />
  );
};
