import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonRow,
} from '@ionic/react';
import TableUsers from 'components/buyer/TableUsers';
import Breadcrumbs, { Breadcrumb } from 'components/shared/Breadcrumbs';
import { Page } from 'components/shared/Page';
import React from 'react';

export const PageSupplierUsers: React.FC = () => {
  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Settings', path: '/supplier/settings' },
    { label: 'Users' },
  ];

  return (
    <Page title="User Settings">
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        title="User Settings"
      ></Breadcrumbs>
      <IonRow>
        <IonCol>
          <IonCard>
            <TableUsers isSupplier={true} />
          </IonCard>
        </IonCol>
      </IonRow>
    </Page>
  );
};
