import App from 'App';
import { createRoot } from 'react-dom/client';
import 'react-tooltip/dist/react-tooltip.css';
import store from 'redux/store';
import reportWebVitals from 'reportWebVitals';
// import * as serviceWorkerRegistration from 'serviceWorkerRegistration';

import { Provider as ReduxProvider } from 'react-redux';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from 'authConfig';

const container = document.getElementById('root');
const root = createRoot(container!);

declare global {
  interface Window {
    InlineManual: any;
  }
}

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <MsalProvider instance={msalInstance}>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </MsalProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
